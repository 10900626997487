const ACCOUNT_FIELDS = {
  ID: '_id',
  NAME: 'name',
  EMAIL: 'email',
  ROLE: 'role',
  EMAIL_VERIFIED: 'emailVerified',
  HEARING_AID: 'hearingAid',
  NORMAL_HEARING_MOD: 'normalHearingMod',
  PREFERENCES: 'preferences',
  PASSWORD: 'password',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
};

const USER_ROLE_ENUM = {
  ADMIN: 'admin',
  USER: 'user',
};

const USER_PREFERENCES_ENUM = {
  HEARING_MOD: {
    NAME: 'hearingMod',
    ENUM: {
      NORMAL: { LABEL: 'Normal Hearing', VALUE: 'nm' },
      HIGH_FREQ_LOSS: { LABEL: 'High range frequency loss', VALUE: 'hi' },
      MID_FREQ_LOSS: { LABEL: 'Medium range frequency loss', VALUE: 'me' },
      LOW_FREQ_LOSS: { LABEL: 'Low range frequency loss', VALUE: 'lo' },
    },
  },
};

export { ACCOUNT_FIELDS, USER_ROLE_ENUM, USER_PREFERENCES_ENUM };
