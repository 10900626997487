import ACTIONS from '../actionsType';
import {
  handleCreateWorkspaceTask,
  handleDeleteWorkspaceTask,
  handleGetWorkspaceById,
  handleCreateWorkspace,
  handleUpdateWorkspaceTask,
  handleGetWorkspaces,
} from '@client/apiServices';

/**
 * Create workspace task
 * @param {string} workspaceId
 * @param {string} chunkId
 * @param {string} status
 * @return {(function(*): void)|*}
 */
const createWorkspaceTask =
  ({ workspaceId, chunkId, status }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.CREATE_WORKSPACE_TASK,
    });
    handleCreateWorkspaceTask({ workspaceId, chunkId, status })
      .then((res) => {
        dispatch({
          type: ACTIONS.CREATE_WORKSPACE_TASK_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.CREATE_WORKSPACE_TASK_ERROR,
          payload: err,
        });
      });
  };
/**
 * Get all workspaces (of current user)
 * @return {function(*): void}
 */
const getWorkspaces = () => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_WORKSPACES,
  });
  handleGetWorkspaces()
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_WORKSPACES_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_WORKSPACES_ERROR,
        payload: err,
      });
    });
};

/**
 * Create a workspace
 * @param {string} title
 * @return {function(*): void}
 */
const createWorkspace = () => (dispatch) => {
  dispatch({
    type: ACTIONS.CREATE_WORKSPACE,
  });
  handleCreateWorkspace()
    .then((res) => {
      dispatch({
        type: ACTIONS.CREATE_WORKSPACE_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.CREATE_WORKSPACE_ERROR,
        payload: err,
      });
    });
};

/**
 * Get one workspace
 * @param {string} workspaceId
 * @return {(function(*): void)|*}
 */
const getWorkspaceById = (workspaceId) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_WORKSPACE,
  });
  handleGetWorkspaceById(workspaceId)
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_WORKSPACE_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_WORKSPACE_ERROR,
        payload: err,
      });
    });
};

/**
 * Update workspace task
 * @param {string} workspaceId
 * @param {string} taskId
 * @param {string} status
 * @return {(function(*): void)|*}
 */
const updateWorkspaceTask =
  ({ workspaceId, taskId, status }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.UPDATE_WORKSPACE_TASK,
    });
    handleUpdateWorkspaceTask({ workspaceId, taskId, status })
      .then((res) => {
        dispatch({
          type: ACTIONS.UPDATE_WORKSPACE_TASK_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ACTIONS.UPDATE_WORKSPACE_TASK_ERROR,
          payload: err,
        });
      });
  };

/**
 * Delete workspace task
 * @param {string} workspaceId
 * @param {string} taskId
 * @return {(function(*): void)|*}
 */
const deleteWorkspaceTask =
  ({ workspaceId, taskId }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.DELETE_WORKSPACE_TASK,
    });
    handleDeleteWorkspaceTask({ workspaceId, taskId })
      .then(() => {
        dispatch({
          type: ACTIONS.DELETE_WORKSPACE_TASK_FULFILLED,
          payload: { workspaceId, taskId },
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.DELETE_WORKSPACE_TASK_ERROR,
          payload: err,
        });
      });
  };

export {
  getWorkspaces,
  getWorkspaceById,
  createWorkspace,
  createWorkspaceTask,
  updateWorkspaceTask,
  deleteWorkspaceTask,
};
