import React, { useEffect } from 'react';
import UserTable from '@client/components/admin/userTable';
import { getUsers } from '@client/stores/actions/admin';
import { useDispatch } from 'react-redux';

const AdminPage = () => {
  const dispatch = useDispatch();

  /**
   * Get a list of users in system
   */
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <>
      <h1>User</h1>
      <UserTable />
    </>
  );
};

export default AdminPage;
