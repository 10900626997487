import React, { useCallback, useEffect, useState } from 'react';
import useAudio from '../custom-hooks/useAudio';
import { HeadingIntroTemplate } from '../templates/InnerTemplates';
import { Col, Row, Spin } from 'antd';
import { RecordPlayButton } from '../components/Button.style';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import CustomIcon from '../components/CustomIcon';
import useRecorderPlayer from '../custom-hooks/useRecorderPlayer';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { CHUNK_FIELDS, TRACK_FIELDS } from '@client/constants/api';
import { RecordingText } from '@client/components/Text.style';
import useLongPress from '@client/custom-hooks/useLongPress';

const PhonemeTest = ({ pageState, testState, setCurrentTaskState, spinning }) => {
  const TIME_LIMIT = 2500; //time limit of recording, in millisecond
  // Track
  const tracksUrl = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.TRACKS_URL], null)
  );

  const isDownloadingTrack = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK],
      false
    )
  );
  const testAudio = useAudio(tracksUrl?.[0]?.[TRACK_FIELDS.FILE]);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const recorder = useRecorderPlayer(TIME_LIMIT);
  const [clicked, setClicked] = useState(false);
  const longPressProps = useLongPress({
    onClick: (ev) => {
      setClicked(true);
    },
    onLongPress: (ev) => {
      setClicked(false);
      recorder.StartRecording();
    },
    onLongPressStop: (ev) => {
      recorder.StopRecording();
    },
  });
  useEffect(() => {
    //check answer when recordState changes
    if (recorder?.recordState?.audioBlob) {
      setCurrentTaskState({ answer: recorder?.recordState?.audioBlob, complete: true });
    }
  }, [recorder?.recordState]);

  return (
    <HeadingIntroTemplate
      HeadingText={pageState?.chunks[testState.testNo - 1][CHUNK_FIELDS.TITLE]}
      IntroText={
        <React.Fragment>
          <p>When you press play, you will hear a sound.</p>
          <p>Press and hold the record button and repeat the sound that you heard.</p>
        </React.Fragment>
      }
      Content={
        <Spin size={'large'} spinning={isDownloadingTrack || spinning}>
          <div style={{ marginTop: '3rem' }}>
            <Row>
              <Col span={24}>
                <RecordPlayButton
                  onClick={useCallback(() => {
                    testAudio?.setIsPlaying(!testAudio?.isPlaying);
                    setAudioPlayed(true);
                  }, [testAudio?.isPlaying, setAudioPlayed])}
                  Icon={testAudio?.isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                  buttonLabel='Play'
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '3rem' }}>
              <Col span={24}>
                <RecordPlayButton
                  props={longPressProps}
                  Icon={
                    recorder?.recordState?.isRecording ? (
                      <CustomIcon.StopIcon />
                    ) : (
                      <CustomIcon.MicrophoneIcon />
                    )
                  }
                  disabled={recorder?.isBlocked || !audioPlayed || testAudio?.isPlaying}
                  buttonLabel='Record'
                />
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>
                {clicked && !recorder.recordState.isRecording && <span>hold to record</span>}
                {recorder.recordState.isRecording && <RecordingText />}
              </Col>
            </Row>
          </div>
        </Spin>
      }
    />
  );
};
export default PhonemeTest;
