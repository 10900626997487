import { ROUTES } from '@client/constants/constants';
import Register from '@client/pages/Register';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import EmailSentPage from '@client/pages/EmailSentPage';
import VerifyEmail from '@client/pages/VerifyEmail';
import ReVerifyEmail from '@client/pages/ReVerifyEmail';
import ForgotPasswordPage from '@client/pages/ForgotPassword';
import ResetPasswordPage from '@client/pages/ResetPasswordPage';
import ProtectedRoute from '@client/routes/AuthRoutes';
import Profile from '@client/pages/profile';
import Dashboard from '@client/pages/Dashboard';
import Test from '@client/pages/Test';
import TermsOfService from '@client/pages/TermsOfService';
import { SmallLogoPageTemp } from '@client/templates/PageTemplate';

const SmallLogoRoutesComp = () => {
  return (
    <Switch>
      {/*Public*/}
      <Route path={ROUTES.SIGNUP} exact component={Register} />
      <Route
        path={ROUTES.REGISTER_SUCCESS}
        exact
        render={() => (
          <EmailSentPage
            messagePrompt={'A verification link has been sent to your email address. '}
          />
        )}
      />
      <Route path={ROUTES.VERIFY_EMAIL} exact component={VerifyEmail} />
      <Route path={ROUTES.RE_VERIFY_EMAIL} exact component={ReVerifyEmail} />
      <Route path={ROUTES.FORGOT_PASSWORD} exact component={ForgotPasswordPage} />
      <Route
        path={ROUTES.FORGOT_PASSWORD_EMAIL_SENT}
        exact
        render={() => (
          <EmailSentPage
            messagePrompt={'A reset password link has been sent to your email address. '}
          />
        )}
      />
      <Route path={ROUTES.RESET_PASSWORD} exact component={ResetPasswordPage} />
      <Route path={ROUTES.TERMS_OF_SERVICE} exact component={TermsOfService} />
      {/*Login required*/}
      <ProtectedRoute path={ROUTES.PROFILE} exact component={Profile} />
      <ProtectedRoute path={ROUTES.DASHBOARD} exact component={Dashboard} />
      <ProtectedRoute path={ROUTES.TEST} exact component={Test} />
    </Switch>
  );
};

const SmallLogoLayoutRoutes = () => {
  return SmallLogoPageTemp({ content: <SmallLogoRoutesComp /> });
};

export default SmallLogoLayoutRoutes;
