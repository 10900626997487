/**
 * Constant values definition
 */

export const TRACK_TAGS = {
  DIGIT: 'digit',
  PHONEME: 'word',
  SENTENCE: 'sentence',
};

export const TASKS_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
};

export const ROUTES = {
  ADMIN: '/admin',
  ADMIN_USER_WORKSPACES: '/admin/workspaces',
  LOGIN: '/login',
  SIGNUP: '/register',
  RECOVERY: '/recovery',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_EMAIL_SENT: '/forgot-password-email-sent',
  RESET_PASSWORD: '/reset-password',
  LANDING: '/',
  REGISTER_SUCCESS: '/registration-success',
  VERIFY_EMAIL: '/verify-email',
  RE_VERIFY_EMAIL: '/re-verify-email',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  NEW_TEST: '/new-test',
  TERMS_OF_SERVICE: '/terms-of-service',
  TEST: '/test',
  DIGIT_TEST: '/digit-test',
  PHONEME_TEST: '/phoneme-test',
  SENTENCE_TEST: '/sentence-test',
};

export const URL_SEARCH_PARAMS = {
  USER_ID: 'userId',
  WORKSPACE_ID: 'workspaceId',
};

export const TEST_TYPE = {
  ADJUST_VOLUME: 'ADJUST_VOLUME',
  ADJUST_MICROPHONE: 'ADJUST_MICROPHONE',
  DIGIT: 'DIGIT',
  PHONEME: 'PHONEME',
  SENTENCE: 'SENTENCE',
  UNSET: 'UNSET',
};

export const DIGIT_LENGTH = 3;

export const RESPONSE_TYPE = {
  TEXT: 'text',
  AUDIO: 'audio',
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YY HH:mm:ss';
