import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { useHistory } from 'react-router-dom';
import { DATE_FORMAT, ROUTES, URL_SEARCH_PARAMS } from '@client/constants/constants';
import { TableWrapper } from '@client/components/admin/userTable/styles';
import moment from 'moment';

/**
 * Format user data
 * @param {Array} users
 * @return {*[]}
 */
const useUserdata = (users) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    const data = users?.map((each) => ({
      key: each?.[ACCOUNT_FIELDS.ID],
      ...each,
      [ACCOUNT_FIELDS.CREATED_AT]: moment(each?.[ACCOUNT_FIELDS.CREATED_AT]).format(DATE_FORMAT),
      [ACCOUNT_FIELDS.UPDATED_AT]: moment(each?.[ACCOUNT_FIELDS.UPDATED_AT]).format(DATE_FORMAT),
    }));
    setResult(data);
  }, [users]);
  return result;
};

const UserTable = () => {
  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: ACCOUNT_FIELDS.NAME,
      key: ACCOUNT_FIELDS.NAME,
    },
    {
      title: 'Email',
      dataIndex: ACCOUNT_FIELDS.EMAIL,
      key: ACCOUNT_FIELDS.NAME,
    },
    {
      title: 'Created at',
      dataIndex: ACCOUNT_FIELDS.CREATED_AT,
      key: ACCOUNT_FIELDS.CREATED_AT,
    },
    {
      title: 'Updated at',
      dataIndex: ACCOUNT_FIELDS.UPDATED_AT,
      key: ACCOUNT_FIELDS.UPDATED_AT,
    },
  ];

  const history = useHistory();
  const users = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ADMIN.NAME, REDUCER_STATES.ADMIN.FIELDS.USERS], [])
  );
  const dataSource = useUserdata(users);

  /**
   * Handle row operations
   * @param {Object} record
   * @return {{onClick: onClick}}
   */
  const onRow = (record) => ({
    onClick: () => {
      history.push({
        pathname: ROUTES.ADMIN_USER_WORKSPACES,
        search: `?${URL_SEARCH_PARAMS.USER_ID}=${record?.key}`,
      });
    },
  });

  return (
    <TableWrapper>
      <Table columns={COLUMNS} dataSource={dataSource} onRow={onRow} showSizeChanger={false} />
    </TableWrapper>
  );
};

export default UserTable;
