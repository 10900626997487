import Svg from '../Svg';
import React from 'react';

const QuotationMark = () => (
  <Svg width='0.8em' viewBox='0 0 23.352 16.207'>
    <g id='noun_Quote_2409407' transform='translate(-2.5 -17.068)'>
      <g id='Group_6' data-name='Group 6' transform='translate(2.5 17.068)'>
        <g id='Group_5' data-name='Group 5'>
          <path
            id='Path_5'
            data-name='Path 5'
            d='M60.7,22.877a8.5,8.5,0,0,1,3.2-3.048,1.5,1.5,0,0,0,.762-1.278A1.469,1.469,0,0,0,62.6,17.2c-2.851,1.254-7.6,4.375-7.6,10.84a5.224,5.224,0,1,0,5.7-5.162Z'
            transform='translate(-42.095 -17.068)'
            fill='currentColor'
          />
          <path
            id='Path_6'
            data-name='Path 6'
            d='M8.178,22.877a8.5,8.5,0,0,1,3.2-3.048,1.5,1.5,0,0,0,.762-1.278A1.469,1.469,0,0,0,10.071,17.2C7.244,18.452,2.5,21.574,2.5,28.039a5.224,5.224,0,1,0,5.678-5.162Z'
            transform='translate(-2.5 -17.068)'
            fill='currentColor'
          />
        </g>
      </g>
    </g>
  </Svg>
);
export default QuotationMark;
