import { SmallLogoPageTemp } from '../templates/PageTemplate';
import React from 'react';
import { UnderlinedLink } from '../components/Link.style';
import { ROUTES } from '../constants/constants';

const NotFoundComp = () => {
  return (
    <React.Fragment>
      <h1>404 - Not Found!</h1>
      <UnderlinedLink Label='back to home' To={ROUTES.LANDING} />
    </React.Fragment>
  );
};

const NotFound = () => {
  return SmallLogoPageTemp({
    content: <NotFoundComp />,
  });
};

export default NotFound;
