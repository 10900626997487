import Layout, { Content, Footer } from 'antd/es/layout/layout';
import React from 'react';

export const FooterLayout = ({ PageContent, FooterContent }) => {
  return (
    <Layout style={{ height: '100%', background: 'inherit' }}>
      <Content>{PageContent}</Content>
      <Footer style={{ background: 'inherit', padding: '0px' }}>{FooterContent}</Footer>
    </Layout>
  );
};
