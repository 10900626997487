import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '@client/constants/constants';
import {
  CHUNK_FIELDS,
  RESPONSE_FIELDS,
  RESPONSE_TYPE_ENUM,
  TASK_FIELDS,
  WORKSPACE_FIELDS,
} from '@client/constants/api';
import { Button, Table, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import {
  handleDownloadPlayback,
  handleDownloadTrack,
  handleGetChunkById,
} from '@client/apiServices';
import _ from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { getAdminResponses } from '@client/stores/actions/admin';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from '@client/services/libs';

/**
 * Group responses by workspace id
 * @param {Array} responses
 * @return {unknown}
 */
const useResponses = (responses) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const result = {};
    responses?.forEach((each) => {
      const workspaceId = each?.[RESPONSE_FIELDS.WORKSPACE_ID];
      if (workspaceId && !Object.prototype.hasOwnProperty.call(result, workspaceId)) {
        result[workspaceId] = [];
      }
      result[workspaceId].push(each);
    });
    setData(result);
  }, [responses]);

  return data;
};

/**
 * Format workspaces data
 * @param {Array} workspaces
 * @return {unknown}
 */
const useData = (workspaces) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const newData = workspaces?.map((each) => ({
      ...each,
      key: each?.[WORKSPACE_FIELDS.ID],
      [WORKSPACE_FIELDS.CREATED_AT]: moment(each?.[WORKSPACE_FIELDS.CREATED_AT]).format(
        DATE_FORMAT
      ),
      [WORKSPACE_FIELDS.UPDATED_AT]: moment(each?.[WORKSPACE_FIELDS.UPDATED_AT]).format(
        DATE_FORMAT
      ),
    }));
    setData(newData);
  }, [workspaces]);

  return data;
};

const WorkspaceTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userId } = parseQueryString(location?.search);

  const workspaces = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ADMIN.NAME, REDUCER_STATES.ADMIN.FIELDS.WORKSPACES], [])
  );
  const responses = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ADMIN.NAME, REDUCER_STATES.ADMIN.FIELDS.RESPONSES], [])
  );

  const [isDownloading, setIsDownloading] = useState(false);

  const responsesData = useResponses(responses);
  const workspacesData = useData(workspaces);
  const userAnsweredWorkspaces = workspacesData?.filter((each) =>
    Object.keys(responsesData)?.includes(each?.[WORKSPACE_FIELDS.ID])
  );

  /**
   * Handle click download
   * @param {Object} workspace
   * @return {Promise<void>}
   */
  const handleClickDownload = async (workspace) => {
    setIsDownloading(true);
    const zip = JSZip();
    const workspaceName = workspace?.[WORKSPACE_FIELDS.TITLE];
    const workspaceId = workspace?.[WORKSPACE_FIELDS.ID];
    const rootChunk = await handleGetChunkById(workspace?.[WORKSPACE_FIELDS.CHUNK_ID]);
    const responses = responsesData?.[workspaceId];
    for (let i = 0; i < responses?.length; i++) {
      const taskID = responses?.[i]?.[RESPONSE_FIELDS.TASK_ID];
      const task = workspace?.[WORKSPACE_FIELDS.TASKS]?.find(
        (task) => task?.[TASK_FIELDS.ID] === taskID
      );
      const chunkID = task?.[TASK_FIELDS.CHUNK_ID];
      const chunk = rootChunk?.[CHUNK_FIELDS.CHILDREN]?.find(
        (child) => child[CHUNK_FIELDS.ID] === chunkID
      );
      const answers = responses?.[i]?.[RESPONSE_FIELDS.ANSWERS];
      const type = responses?.[i]?.[RESPONSE_FIELDS.TYPE];
      const subFolder = zip.folder(chunk[CHUNK_FIELDS.TITLE]);
      for (let j = 0; j < answers?.length; j++) {
        const subFolderForOneQuestion = subFolder.folder((j + 1).toString());
        const answer = answers?.[j];
        const currentTrackId = answer?.[RESPONSE_FIELDS.ANSWERS_TRACK_ID];
        const audio = await handleDownloadTrack(currentTrackId);
        subFolderForOneQuestion.file('question.mp3', audio);
        if (type === RESPONSE_TYPE_ENUM.AUDIO) {
          const audio = await handleDownloadPlayback(answer?.[RESPONSE_FIELDS.ANSWERS_PLAYBACK_ID]);
          subFolderForOneQuestion.file('answer.mp3', audio);
        }
        if (type === RESPONSE_TYPE_ENUM.TEXT) {
          subFolderForOneQuestion.file('answer.txt', answer?.[RESPONSE_FIELDS.ANSWERS_TEXT]);
        }
      }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${workspaceName?.split(' ')?.join('_')}.zip`);
      setIsDownloading(false);
    });
  };

  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: WORKSPACE_FIELDS.TITLE,
      key: WORKSPACE_FIELDS.TITLE,
    },
    {
      title: 'Created at',
      dataIndex: WORKSPACE_FIELDS.CREATED_AT,
      key: WORKSPACE_FIELDS.CREATED_AT,
    },
    {
      title: 'Updated at',
      dataIndex: WORKSPACE_FIELDS.UPDATED_AT,
      key: WORKSPACE_FIELDS.UPDATED_AT,
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (text, record) => (
        <>
          <Tooltip key={'download'} title={'download'}>
            <Button
              icon={<CloudDownloadOutlined />}
              onClick={() => handleClickDownload(record)}
              loading={isDownloading}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  /**
   * Get current user's responses
   */
  useEffect(() => {
    dispatch(getAdminResponses({ userId }));
  }, [userId]);

  return <Table columns={COLUMNS} dataSource={userAnsweredWorkspaces} showSizeChanger={false} />;
};

export default WorkspaceTable;
