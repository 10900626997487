const REDUCER_STATES = {
  ACCOUNT: {
    NAME: 'account',
    FIELDS: {
      ERROR: 'error',
      USER: 'user',
      IS_LOGGED_IN: 'isLoggedIn',
      IS_LOGGING_IN: 'isLoggingIn',
      IS_REGISTERING: 'isRegistering',
      IS_UPDATING_PROFILE: 'isUpdatingProfile',
      IS_RESETTING_PASSWORD: 'isResettingPassword',
      IS_RESENDING_RESET_PASSWORD: 'isResendingResetPassword',
      IS_GETTING_SESSION_STATUS: 'isGettingSessionStatus',
      IS_SENDING_CONFIRMATION_EMAIL: 'isSendingConfirmationEmail',
    },
  },
  ADMIN: {
    NAME: 'admin',
    FIELDS: {
      ERROR: 'error',
      USERS: 'users',
      RESPONSES: 'responses',
      WORKSPACES: 'workspaces',
      IS_GETTING_RESPONSE: 'isGettingResponse',
      IS_GETTING_USER: 'isGettingUser',
      IS_CREATING_USER: 'isCreatingUser',
      IS_GETTING_WORKSPACE: 'isGettingWorkspace',
    },
  },
  CHUNK: {
    NAME: 'chunk',
    FIELDS: {
      ERROR: 'error',
      CHUNKS: 'chunks',
      CURRENT_CHUNK: 'currentChunk',
      IS_GETTING_CHUNK: 'isGettingChunk',
    },
  },
  PLAYBACK: {
    NAME: 'playback',
    FIELDS: {
      ERROR: 'error',
      PLAYBACKS: 'playbacks',
      CURRENT_PLAYBACK: 'currentPlayback',
      IS_CREATING_PLAYBACK: 'isCreatingPlayback',
      IS_GETTING_PLAYBACK: 'isGettingPlayback',
      IS_DELETING_PLAYBACK: 'isDeletingPlayback',
      IS_UPDATING_PLAYBACK: 'isUpdatingPlayback',
      IS_DOWNLOADING_PLAYBACK: 'isDownloadingPlayback',
    },
  },
  RESPONSE: {
    NAME: 'response',
    FIELDS: {
      ERROR: 'error',
      RESPONSES: 'responses',
      CURRENT_RESPONSE: 'currentResponse',
      IS_GETTING_RESPONSE: 'isGettingResponse',
      IS_CREATING_RESPONSE: 'isCreatingResponse',
      IS_DELETING_RESPONSE: 'isDeletingResponse',
      IS_UPDATING_RESPONSE: 'isUpdatingResponse',
    },
  },
  TRACK: {
    NAME: 'track',
    FIELDS: {
      ERROR: 'error',
      TRACKS: 'tracks',
      TRACKS_URL: 'tracksUrl',
      CURRENT_TRACK: 'currentTrack',
      IS_CREATING_TRACK: 'isCreatingTrack',
      IS_GETTING_TRACK: 'isGettingTrack',
      IS_DELETING_TRACK: 'isDeletingTrack',
      IS_UPDATING_TRACK: 'isUpdatingTrack',
      IS_DOWNLOADING_TRACK: 'isDownloadingTrack',
    },
  },
  WORKSPACE: {
    NAME: 'workspace',
    FIELDS: {
      ERROR: 'error',
      WORKSPACES: 'workspaces',
      CURRENT_WORKSPACE: 'currentWorkspace',
      CURRENT_TASK: 'currentTasks',
      IS_GETTING_WORKSPACE: 'isGettingWorkspace',
      IS_CREATING_WORKSPACE: 'isCreatingWorkspace',
      IS_CREATING_WORKSPACE_TASK: 'isCreatingWorkspaceTask',
      IS_UPDATING_WORKSPACE_TASK: 'isUpdatingWorkspaceTask',
      IS_DELETING_WORKSPACE_TASK: 'isDeletingWorkspaceTask',
    },
  },
};

export { REDUCER_STATES };
