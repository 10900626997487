import React from 'react';
import { Spin } from 'antd';
import { GradientBtn } from '../components/Button.style';
import * as PropTypes from 'prop-types';

/**
 * Gradient button with loading spin
 * @param {boolean} isLoading
 * @param {string} tip - loading tip
 * @param {string} btnLabel - button label
 * @param {Object} styles - styles
 * @param {string|null} htmlType - htmlType
 * @param {Function} onClick - onClick
 * @return {JSX.Element}
 * @constructor
 */
const GradientBtnWithSpin = ({
  isLoading,
  tip = '',
  btnLabel = 'Ok',
  styles,
  htmlType = null,
  onClick,
}) => {
  return (
    <div style={styles} onClick={onClick}>
      <Spin size={'large'} spinning={isLoading} tip={tip}>
        <GradientBtn buttonLabel={btnLabel} htmlType={htmlType} />
      </Spin>
    </div>
  );
};

GradientBtnWithSpin.prototype = {
  isLoading: PropTypes.bool.isRequired,
  tip: PropTypes.string,
  btnLabel: PropTypes.string,
  styles: PropTypes.object,
  htmlType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
export default GradientBtnWithSpin;
