import { notification } from 'antd';

notification.config({
  placement: 'bottomRight',
  duration: 3,
});

export const success = (message, title) => {
  notification.success({
    message: title || 'Success',
    description: message,
  });
};

export const info = (message, title) => {
  notification.info({
    message: title || 'Information',
    description: message,
  });
};

export const warning = (message, title) => {
  notification.warning({
    message: title || 'Warning',
    description: message,
  });
};

/**
 * Error notification with on close function support
 * @param {string} message
 * @param {string|null} title
 * @param {Function} onCloseFunc
 * @param {number} duration
 */
export const error = ({ message, title = null, onCloseFunc = null, duration = 3 }) => {
  notification.error({
    message: title || 'Error',
    description: message,
    duration,
    onClose: () => {
      if (onCloseFunc) {
        onCloseFunc();
      } else {
        return;
      }
    },
  });
};

export default {
  success,
  info,
  warning,
  error,
};
