import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { MAIN_GREY } from '../assets/styles/global';

export const PageHeadingText = styled.div`
  font-size: 1.4rem;
`;

export const PageIntroText = styled.div`
  color: ${MAIN_GREY};
  font-size: 0.8rem;
`;

export const DescripText = styled.span`
  color: ${MAIN_GREY};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  padding: 1px 6px;
`;

export const HintText = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
`;
export const TripletDigitDiv = styled.div`
  width: calc(4rem + 6px);
  font-size: 2rem;
  text-align: center;
`;

const SplitText = (props) => {
  return (
    <span aria-label={props.copy} {...props}>
      {props.copy.split('').map(function (char, index) {
        return (
          <span className={'char'} aria-hidden='true' key={index} style={{ '--char-index': index }}>
            {char}
          </span>
        );
      })}
    </span>
  );
};

const JumpAnimation = keyframes`
  20% {
    transform: translateY(2%) scaleY(0.9);
  }
  40% {
    transform: translateY(-40%) scaleY(1.1);
  }
  50% {
    transform: translateY(5%) scaleY(0.8);
  }
  70% {
    transform: translateY(-5%) scaleY(1);
  }
  80%,
  100% {
    transform: translateY(0) scaleY(1);
  }`;

export const JumpingText = styled(SplitText)`
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'flex-start'};
  & > .char {
    animation-name: ${JumpAnimation};
    animation-duration: 1s;
    animation-delay: calc(var(--char-index) * 0.075s);
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.165, 0.44, 0.64, 1);
  }
`;

export const RecordingText = (props) => <JumpingText copy='Recording...' {...props} />;
