const RESPONSE_FIELDS = {
  ID: '_id',
  WORKSPACE_ID: 'workspaceId',
  TASK_ID: 'taskId',
  TYPE: 'type',
  ANSWERS: 'answers',
  ANSWERS_TRACK_ID: 'trackId',
  ANSWERS_PLAYBACK_ID: 'playbackId',
  ANSWERS_TEXT: 'text',
};

const RESPONSE_TYPE_ENUM = {
  AUDIO: 'audio',
  TEXT: 'text',
};

export { RESPONSE_FIELDS, RESPONSE_TYPE_ENUM };
