import {
  handleCreatePlayback,
  handleDeletePlayback,
  handleDownloadPlayback,
  handleGetPlayback,
  handleGetPlaybacks,
  handleUpdatePlayback,
} from '@client/apiServices/playback';
import ACTIONS from '../actionsType';
import { createResponse } from '@client/stores/actions/response';
import { PLAYBACK_FIELDS, RESPONSE_FIELDS } from '@client/constants/api';
import { RESPONSE_TYPE } from '@client/constants/constants';

/**
 * Create playback
 * @param {Array} file
 * @param {Array} workspaceId
 * @param {Array} taskId
 * @return {(function(*): void)|*}
 */
const createPlayback =
  ({ file, workspaceId, taskId }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.CREATE_PLAYBACK,
    });
    handleCreatePlayback({ file, workspaceId, taskId })
      .then((res) => {
        dispatch({
          type: ACTIONS.CREATE_PLAYBACK_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.CREATE_PLAYBACK_ERROR,
          payload: err,
        });
      });
  };

/**
 * Create a playback in the server then create a response with the playbackId as answer
 * @param {Array} file
 * @param {Array} workspaceId
 * @param {Array} trackId
 * @param {Array} taskId
 * @return {(function(*): void)|*}
 */
const createPlaybackThenResponse =
  ({ file, workspaceId, taskId, trackId }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.CREATE_PLAYBACK,
    });
    handleCreatePlayback({ file, workspaceId, taskId })
      .then((res) => {
        dispatch({
          type: ACTIONS.CREATE_PLAYBACK_FULFILLED,
          payload: res,
        });
        const answers = [
          {
            [RESPONSE_FIELDS.ANSWERS_TRACK_ID]: trackId?.[0],
            [RESPONSE_FIELDS.ANSWERS_PLAYBACK_ID]: res?.[0]?.[PLAYBACK_FIELDS.ID],
          },
        ];
        dispatch(
          createResponse({
            workspaceId: workspaceId[0],
            taskId: taskId[0],
            type: RESPONSE_TYPE.AUDIO,
            answers: answers,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ACTIONS.CREATE_PLAYBACK_ERROR,
          payload: err,
        });
      });
  };
/**
 * Get playbacks
 * @return {(function(*): void)|*}
 */
const getPlaybacks = () => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_PLAYBACKS,
  });
  handleGetPlaybacks()
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_PLAYBACKS_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_PLAYBACKS_ERROR,
        payload: err,
      });
    });
};

/**
 * Get one playback
 * @param {string} playbackId
 * @return {(function(*): void)|*}
 */
const getPlayback = (playbackId) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_PLAYBACK,
  });
  handleGetPlayback(playbackId)
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_PLAYBACK_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_PLAYBACK_ERROR,
        payload: err,
      });
    });
};

/**
 * Update one playback
 * @param {string} playbackId
 * @param {string} workspaceId
 * @param {string} taskId
 * @return {(function(*): void)|*}
 */
const updatePlayback =
  ({ playbackId, workspaceId, taskId }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.UPDATE_PLAYBACK,
    });
    handleUpdatePlayback({ playbackId, workspaceId, taskId })
      .then((res) => {
        dispatch({
          type: ACTIONS.UPDATE_PLAYBACK_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.UPDATE_PLAYBACK_ERROR,
          payload: err,
        });
      });
  };

/**
 * Download one playback
 * @param {string} playbackId
 * @return {(function(*): void)|*}
 */
const downloadPlayback = (playbackId) => (dispatch) => {
  dispatch({
    type: ACTIONS.DOWNLOAD_PLAYBACK,
  });
  handleDownloadPlayback(playbackId)
    .then(() => {
      dispatch({
        type: ACTIONS.DOWNLOAD_PLAYBACK_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.DOWNLOAD_PLAYBACK_ERROR,
        payload: err,
      });
    });
};

/**
 * Delete one playback
 * @param {string} playbackId
 * @return {(function(*): void)|*}
 */
const deletePlayback = (playbackId) => (dispatch) => {
  dispatch({
    type: ACTIONS.DELETE_PLAYBACK,
  });
  handleDeletePlayback(playbackId)
    .then(() => {
      dispatch({
        type: ACTIONS.DELETE_PLAYBACK_FULFILLED,
        payload: playbackId,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.DELETE_PLAYBACK_ERROR,
        payload: err,
      });
    });
};

export {
  createPlayback,
  createPlaybackThenResponse,
  getPlaybacks,
  getPlayback,
  updatePlayback,
  downloadPlayback,
  deletePlayback,
};
