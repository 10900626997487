import React from 'react';
import { IconButton } from '@client/components/ButtonWithIcon/styles';
import * as PropTypes from 'prop-types';

/**
 * Button with icon in it
 * @param {boolean} disabled
 * @param {function} onClick
 * @param {JSX.Element} icon
 * @param {string} buttonLabel
 * @return {JSX.Element}
 * @constructor
 */
const ButtonWithIcon = ({ disabled = false, onClick, icon, buttonLabel }) => {
  return (
    <IconButton onClick={onClick} style={{ textAlign: 'left' }} disabled={disabled}>
      {icon}
      <span style={{ marginLeft: '10px' }}>{buttonLabel}</span>
    </IconButton>
  );
};

ButtonWithIcon.prototype = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  buttonLabel: PropTypes.string,
};

export default ButtonWithIcon;
