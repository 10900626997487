import Svg from '../Svg';
import React from 'react';

const ABC = () => (
  <Svg width='0.8em' viewBox='0 0 25 33'>
    <g id='Group_3' data-name='Group 3' transform='translate(-72 -120)'>
      <text
        id='A'
        transform='translate(73 134)'
        fill='currentColor'
        fontSize='18'
        fontFamily='Helvetica'
      >
        <tspan x='0' y='0'>
          A
        </tspan>
      </text>
      <text
        id='B'
        transform='translate(85 142)'
        fill='currentColor'
        fontSize='17'
        fontFamily='Helvetica'
      >
        <tspan x='0' y='0'>
          B
        </tspan>
      </text>
      <text
        id='C'
        transform='translate(72 149)'
        fill='currentColor'
        fontSize='17'
        fontFamily='Helvetica'
      >
        <tspan x='0' y='0'>
          C
        </tspan>
      </text>
    </g>
  </Svg>
);
export default ABC;
