import { REDUCER_STATES } from '@client/constants/reducerStates';
import ACTIONS from '../actionsType';

const initialState = {
  [REDUCER_STATES.ADMIN.FIELDS.IS_CREATING_USER]: false,
  [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_RESPONSE]: false,
  [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_WORKSPACE]: false,
  [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_USER]: false,
  [REDUCER_STATES.ADMIN.FIELDS.USERS]: [],
  [REDUCER_STATES.ADMIN.FIELDS.RESPONSES]: [],
  [REDUCER_STATES.ADMIN.FIELDS.WORKSPACES]: null,
  [REDUCER_STATES.ADMIN.FIELDS.ERROR]: null,
};

export const admin = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_USERS: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: null,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_USER]: true,
      };
    }
    case ACTIONS.GET_USERS_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.USERS]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_USER]: false,
      };
    }
    case ACTIONS.GET_USERS_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_USER]: false,
      };
    }

    case ACTIONS.CREATE_USER: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: null,
        [REDUCER_STATES.ADMIN.FIELDS.IS_CREATING_USER]: true,
      };
    }
    case ACTIONS.CREATE_USER_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.USERS]: [
          ...state[REDUCER_STATES.ADMIN.FIELDS.USERS],
          action.payload,
        ],
        [REDUCER_STATES.ADMIN.FIELDS.IS_CREATING_USER]: false,
      };
    }
    case ACTIONS.CREATE_USER_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_CREATING_USER]: false,
      };
    }

    case ACTIONS.GET_RESPONSES_ADMIN: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: null,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_RESPONSE]: true,
      };
    }
    case ACTIONS.GET_RESPONSES_ADMIN_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.RESPONSES]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_RESPONSE]: false,
      };
    }
    case ACTIONS.GET_RESPONSES_ADMIN_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_RESPONSE]: false,
      };
    }

    case ACTIONS.GET_WORKSPACES_ADMIN: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: null,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_WORKSPACE]: true,
      };
    }
    case ACTIONS.GET_WORKSPACES_ADMIN_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.WORKSPACES]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_WORKSPACE]: false,
      };
    }
    case ACTIONS.GET_WORKSPACES_ADMIN_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ADMIN.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.ADMIN.FIELDS.IS_GETTING_WORKSPACE]: false,
      };
    }

    default: {
      return state;
    }
  }
};
