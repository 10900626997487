import React, { useEffect } from 'react';
import { parseQueryString } from '@client/services/libs';
import { useLocation } from 'react-router-dom';
import WorkspaceTable from '@client/components/admin/workspaceTable';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import { getAdminWorkspaces, getUsers } from '@client/stores/actions/admin';

const AdminWorkspacesPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const users = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ADMIN.NAME, REDUCER_STATES.ADMIN.FIELDS.USERS], [])
  );

  const { userId } = parseQueryString(location?.search);

  /**
   * Get a list of users in system
   */
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  /**
   * Get a list of workspaces in system
   */
  useEffect(() => {
    dispatch(getAdminWorkspaces());
  }, []);

  return (
    <>
      <h1>
        {_.find(users, { [ACCOUNT_FIELDS.ID]: userId })?.[ACCOUNT_FIELDS.NAME]}&apos;s workspaces
      </h1>
      <WorkspaceTable />
    </>
  );
};

export default AdminWorkspacesPage;
