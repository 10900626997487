import React from 'react';
import { Layout } from '../assets/styles/global';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../constants/constants';
import NotFound from '../pages/NotFound';
import SmallLogoLayoutRoutes from '@client/routes/layouts/smallLogoLayoutRoutes';
import BigLogoLayoutRoutes from '@client/routes/layouts/bigLogoLayoutRoutes';
import AdminLayoutRoutes from '@client/routes/layouts/adminLayout';

const Routes = () => {
  return (
    <Layout>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={[
              ROUTES.SIGNUP,
              ROUTES.REGISTER_SUCCESS,
              ROUTES.VERIFY_EMAIL,
              ROUTES.RE_VERIFY_EMAIL,
              ROUTES.FORGOT_PASSWORD,
              ROUTES.FORGOT_PASSWORD_EMAIL_SENT,
              ROUTES.RESET_PASSWORD,
              ROUTES.PROFILE,
              ROUTES.DASHBOARD,
              ROUTES.TEST,
              ROUTES.TERMS_OF_SERVICE,
            ]}
            component={SmallLogoLayoutRoutes}
          />
          <Route exact path={[ROUTES.LANDING, ROUTES.LOGIN]} component={BigLogoLayoutRoutes} />
          <Route
            exact
            path={[ROUTES.ADMIN, ROUTES.ADMIN_USER_WORKSPACES]}
            component={AdminLayoutRoutes}
          />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Layout>
  );
};

export default Routes;
