import styled from 'styled-components';
import React from 'react';
import { PRIMARY_COLOR, SECOND_COLOR, BUTTON_GRADIENT } from './../assets/styles/global.js';

const Button = ({ className, onClick, buttonLabel, disabled }) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      <span>{buttonLabel}</span>
    </button>
  );
};

const ButtonWithIcon = ({ className, onClick, buttonLabel, Icon, disabled }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      style={{ textAlign: 'left' }}
      disabled={disabled}
    >
      {Icon}
      <span style={{ marginLeft: '10px' }}>{buttonLabel}</span>
    </button>
  );
};

const DivButton = React.forwardRef(({ className, onClick, content, disabled, props }, ref) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled} ref={ref} {...props}>
      <div>{content}</div>
    </button>
  );
});

const DivIconTextButton = ({ className, onClick, buttonLabel, Icon, disabled, props }) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled} {...props}>
      <div>{Icon}</div>
      <span>{buttonLabel}</span>
    </button>
  );
};

const IconOnlyButton = ({ className, onClick, icon, disabled }) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {icon}
    </button>
  );
};
export const RecordPlayButton = styled(DivIconTextButton)`
  background: transparent;
  border: none;

  cursor: pointer;
  & > span {
    font-size: 1.8rem;
    text-transform: uppercase;
    padding-left: 1rem;
  }
  & > div {
    display: inline-block;
    text-align: center;
    background-color: ${PRIMARY_COLOR};
    color: ${SECOND_COLOR};
    height: 4rem;
    width: 4rem;
    border: none;
    font-size: 2.5rem;
    border-radius: 5px;
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;
export const SquareButton = styled(DivButton)`
  background-color: ${PRIMARY_COLOR};
  border-radius: 5px;
  color: ${SECOND_COLOR};
  height: 4rem;
  width: 4rem;
  border: none;
  font-size: 2.5rem;
  margin: 3px;
  cursor: pointer;
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
  & > div {
    display: flex;
    justify-content: center;
  }
`;

export const EmptySquareButton = styled(DivButton)`
  color: transparent;
  background-color: transparent;
  height: 4rem;
  width: 4rem;
  border: none;
  font-size: 2.5rem;
  margin: 3px;
  pointer-events: none;
`;

export const RoundIconButton = styled(DivButton)`
  background: transparent;
  border: none;
  & > div {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    box-shadow: -15px ${SECOND_COLOR};
    border: solid 2px;
    color: ${SECOND_COLOR};
    font-size: 1.2rem;
    margin: 0.5rem;
    height: 1.6rem;
    width: 1.6rem;
    &:hover {
      filter: brightness(120%);
    }
  }

  cursor: pointer;
`;
export const PrimaryIconButton = styled(IconOnlyButton)`
  padding: 0;
  background: transparent;
  border: none;
  color: ${PRIMARY_COLOR};
  font-size: ${(props) => props.fontSize || '1rem'};
  transition: 0.5s;
  position: relative;

  cursor: pointer;
  &:hover {
    filter: brightness(120%);
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;
export const OutlinedBtn = styled(Button)`
  width: 100%;
  min-width: 100px;
  height: 3rem;
  background: transparent;
  border-radius: 3rem;
  border: solid 3px;
  color: ${PRIMARY_COLOR};
  margin-top: ${(props) => props.marginTop || '0px'};
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;
export const GradientBtn = styled(Button)`
  width: 100%;
  min-width: 100px;
  height: 3rem;
  background-image: ${BUTTON_GRADIENT};
  background-size: 300% 100%;
  border-radius: 3rem;
  border: none;
  color: ${SECOND_COLOR};
  margin-top: ${(props) => props.marginTop || '0px'};
  font-size: 1rem;
  transition: 0.5s;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 15%);
  cursor: pointer;

  &:hover {
    background-position: 100% 0;
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;

export const TextBtn = styled(Button)`
  width: 100%;
  background: transparent;
  border: none;
  color: ${PRIMARY_COLOR};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  transition: 0.5s;
  cursor: pointer;
  height: ${(props) => (props.height ? props.height : 'auto')};
  text-transform: ${(props) => (props.textTransform ? props.textTransform : 'capitalize')};
  & > span {
    padding: ${(props) => (props.padding ? props.padding : '10px 25px 10px 25px')};
  }
  &:hover {
    filter: brightness(120%);
  }
`;

export const TextBtnAsListItem = styled(Button)`
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  color: ${PRIMARY_COLOR};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  transition: 0.5s;
  cursor: pointer;
  height: ${(props) => (props.height ? props.height : 'auto')};
  text-transform: ${(props) => (props.textTransform ? props.textTransform : 'capitalize')};
  & > span {
    padding: ${(props) => (props.padding ? props.padding : '0px')};
  }
  &:hover {
    filter: brightness(120%);
  }
`;

export const TextIconBtn = styled(ButtonWithIcon)`
  width: 100%;
  background: transparent;
  border: none;
  color: ${PRIMARY_COLOR};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.6rem')};
  transition: 0.5s;
  cursor: pointer;
  position: relative;
  padding: ${(props) => (props.padding ? props.padding : '1rem 0rem')};
  display: flex;
  align-items: center;
  text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : 'none')};

  &:hover {
    filter: brightness(120%);
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;

/**
 * Button without any border
 * @param {Object} props
 * @return {JSX.Element}
 */
export const JustTextButton = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  color: ${PRIMARY_COLOR};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;
