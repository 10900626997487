import React, { useEffect, useRef } from 'react';
import { Form, Input, Col, Row, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { register, reVerifyEmail } from '../stores/actions/account.action';
import {
  EMAIL_FORMAT_RULE,
  PASSWORD_FORMAT_RULE,
  USER_NAME_FORMAT_RULE,
} from '@client/constants/formValidation';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import GradientBtnWithSpin from '@client/components/GradientBtnWithSpin';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { ROUTES } from '@client/constants/constants';
import { makeUrl } from '@client/services/libs';
import { Link, useHistory } from 'react-router-dom';

const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER], null)
  );
  const isRegistering = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_REGISTERING], false)
  );
  const isSendingConfirmationEmail = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_SENDING_CONFIRMATION_EMAIL],
      false
    )
  );
  const error = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.ERROR], null)
  );

  const previousIsSendingConfirmationEmail = useRef(isSendingConfirmationEmail);

  /**
   * Register
   * @param {Object} values
   */
  const handleSubmit = (values) => {
    dispatch(
      register({
        name: values[ACCOUNT_FIELDS.NAME],
        email: values[ACCOUNT_FIELDS.EMAIL],
        password: values[ACCOUNT_FIELDS.PASSWORD],
        hearingAid: values[ACCOUNT_FIELDS.HEARING_AID],
        normalHearingMod: values[ACCOUNT_FIELDS.NORMAL_HEARING_MOD],
      })
    );
  };

  /**
   * Redirect to email sent page after registering
   */
  useEffect(() => {
    if (previousIsSendingConfirmationEmail.current && !isSendingConfirmationEmail && !error) {
      history.push(ROUTES.REGISTER_SUCCESS);
    }
    previousIsSendingConfirmationEmail.current = isSendingConfirmationEmail;
  }, [isSendingConfirmationEmail]);

  /**
   * Send email verify
   */
  useEffect(() => {
    if (user && !error) {
      const userId = _.get(user, ACCOUNT_FIELDS.ID, null);
      dispatch(reVerifyEmail({ userId, destUrl: makeUrl(ROUTES.VERIFY_EMAIL) }));
    }
  }, [user, error]);

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <p style={{ fontSize: '1.4rem' }}>Create an account</p>
        </Col>
      </Row>
      <Form layout='vertical' onFinish={handleSubmit}>
        <Form.Item label='Name' name={ACCOUNT_FIELDS.NAME} rules={USER_NAME_FORMAT_RULE}>
          <Input />
        </Form.Item>

        <Form.Item label='Email' name={ACCOUNT_FIELDS.EMAIL} rules={EMAIL_FORMAT_RULE}>
          <Input />
        </Form.Item>

        <Form.Item
          name={ACCOUNT_FIELDS.PASSWORD}
          label='Password'
          rules={PASSWORD_FORMAT_RULE}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name='confirm'
          label='Confirm Password'
          dependencies={['password']}
          hasFeedback
          rules={[
            ...PASSWORD_FORMAT_RULE,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(ACCOUNT_FIELDS.PASSWORD) === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name={ACCOUNT_FIELDS.HEARING_AID}
          label='I wear a hearing aid'
          rules={[{ required: true, message: 'Please select an option!' }]}
        >
          <Radio.Group options={radioOptions} />
        </Form.Item>
        <Form.Item
          name={ACCOUNT_FIELDS.NORMAL_HEARING_MOD}
          label='I wear normal hearing modification'
          rules={[{ required: true, message: 'Please select an option!' }]}
          style={{ display: 'none' }}
          initialValue={false}
        >
          <Radio.Group options={radioOptions} />
        </Form.Item>

        <Form.Item>
          <GradientBtnWithSpin
            htmlType='submit'
            styles={{ marginTop: '2rem' }}
            btnLabel={'Create Account'}
            isLoading={isRegistering || isSendingConfirmationEmail}
          />
        </Form.Item>
      </Form>
      <Row justify='space-around'>
        <Col span={24}>
          <p style={{ fontSize: '9px', textAlign: 'center' }}>
            By signing up you accept the{' '}
            <Link to={ROUTES.TERMS_OF_SERVICE}>
              <span style={{ textDecoration: 'underline', fontWeight: '500' }}>
                Terms of Service and Privacy Policy
              </span>
            </Link>
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Register;
