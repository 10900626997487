import useAudio from '../custom-hooks/useAudio';
import { Col, Row, Spin } from 'antd';
import { RecordPlayButton } from '../components/Button.style';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { HeadingIntroTemplate } from '../templates/InnerTemplates';
import { StyledTextArea } from '../components/TextArea';
import { HintText } from '@client/components/Text.style';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { CHUNK_FIELDS, TRACK_FIELDS } from '@client/constants/api';
const SentenceTest = ({ pageState, testState, setCurrentTaskState, spinning }) => {
  // TrackFile
  const tracksUrl = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.TRACKS_URL], null)
  );
  const isDownloadingTrack = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK],
      false
    )
  );
  const [answer, setAnswer] = useState('');
  const previousAnswer = useRef(answer);
  const testAudio = useAudio(tracksUrl?.[0]?.[TRACK_FIELDS.FILE]);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    //check answer when answer changes
    if (answer !== previousAnswer.current) {
      if (!audioPlayed && !showWarning) setShowWarning(true);
      setCurrentTaskState({ answer: answer, complete: !!(answer && answer.length > 0) });
    }
    previousAnswer.current = answer;
  }, [answer, showWarning, setShowWarning]);
  return (
    <HeadingIntroTemplate
      HeadingText={pageState?.chunks[testState?.testNo - 1][CHUNK_FIELDS.TITLE]}
      IntroText={
        <React.Fragment>
          <p>When you press play, you will hear a sentence.</p>

          <p>Type the sentence that you heard in the text box and press submit.</p>
        </React.Fragment>
      }
      Content={
        <Spin size={'large'} spinning={isDownloadingTrack || spinning}>
          <div style={{ marginTop: '3rem' }}>
            <Row>
              <Col span={24}>
                <RecordPlayButton
                  onClick={useCallback(() => {
                    testAudio.setIsPlaying(!testAudio.isPlaying);
                    setAudioPlayed(true);
                    setShowWarning(false);
                  }, [testAudio.isPlaying, setAudioPlayed, setShowWarning])}
                  Icon={testAudio.isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                  buttonLabel='Play'
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
              <Col span={24}>
                <StyledTextArea
                  value={answer}
                  onChange={useCallback(
                    (e) => {
                      e.preventDefault();
                      setAnswer(e.target.value);
                    },
                    [answer]
                  )}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {showWarning && <HintText>Press &quot;Play&quot;and listen to the audio.</HintText>}
              </Col>
            </Row>
          </div>
        </Spin>
      }
    />
  );
};
export default SentenceTest;
