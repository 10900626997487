import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { reVerifyEmail } from '../stores/actions/account.action';
import * as _ from 'lodash';
import { REDUCER_STATES } from '../constants/reducerStates';
import GradientBtnWithSpin from '../components/GradientBtnWithSpin';
import queryString from 'query-string';
import { makeUrl } from '@client/services/libs';

const ReVerifyEmailPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryStrings = queryString.parse(location.search);
  const isSendingConfirmationEmail = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_SENDING_CONFIRMATION_EMAIL],
      false
    )
  );
  const error = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.ERROR], null)
  );
  const previousIsSendingConfirmationEmail = useRef(isSendingConfirmationEmail);

  /**
   * Handle send confirmation email
   */
  const handleResendEmail = () => {
    const userId = queryStrings?.id;
    if (userId) {
      dispatch(reVerifyEmail({ userId, destUrl: makeUrl(ROUTES.VERIFY_EMAIL) }));
    }
  };

  useEffect(() => {
    if (previousIsSendingConfirmationEmail.current && !isSendingConfirmationEmail && !error) {
      history.push(ROUTES.REGISTER_SUCCESS);
    }
    previousIsSendingConfirmationEmail.current = isSendingConfirmationEmail;
  }, [isSendingConfirmationEmail]);

  return (
    <>
      <h1>Email not been confirmed yet</h1>
      <p>Your email address is not validated yet</p>
      <p>Please click the button below to re-send a new confirmation email</p>
      <GradientBtnWithSpin
        btnLabel={'Re-send email'}
        isLoading={isSendingConfirmationEmail}
        styles={{ marginTop: '2rem' }}
        onClick={handleResendEmail}
      />
    </>
  );
};

export default ReVerifyEmailPage;
