//Global Style Components
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';

export const SECOND_COLOR = '#FFE6E6';
export const PRIMARY_COLOR = '#f15290';
export const MAIN_GREY = '#676767';
export const LIGHTER_GREY = '#707070';
export const GRADIENT_COLOR_1 = '#FF4D4D';
export const GRADIENT_COLOR_2 = '#eb53ad';
export const SUCCESS_GREEN = '#52c41a';
export const MAIN_GRADIENT = `linear-gradient(130.7deg, ${GRADIENT_COLOR_1}, ${GRADIENT_COLOR_2})`;
export const BUTTON_GRADIENT = `linear-gradient(to right, ${GRADIENT_COLOR_1}, ${GRADIENT_COLOR_2}, ${GRADIENT_COLOR_1})`;

export const Layout = styled(AntLayout)`
  background: ${MAIN_GRADIENT};
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
export const AppContent = styled(AntLayout.Content)`
  height: 100%;
  min-height: 100vh;
`;
