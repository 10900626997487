import { REDUCER_STATES } from '@client/constants/reducerStates';
import ACTIONS from '../actionsType';

const initialState = {
  [REDUCER_STATES.CHUNK.FIELDS.IS_GETTING_CHUNK]: false,
  [REDUCER_STATES.CHUNK.FIELDS.CURRENT_CHUNK]: null,
  [REDUCER_STATES.CHUNK.FIELDS.ERROR]: null,
};

export const chunk = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_CHUNK: {
      return {
        ...state,
        [REDUCER_STATES.CHUNK.FIELDS.ERROR]: null,
        [REDUCER_STATES.CHUNK.FIELDS.IS_GETTING_CHUNK]: true,
      };
    }
    case ACTIONS.GET_CHUNK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.CHUNK.FIELDS.CURRENT_CHUNK]: action.payload,
        [REDUCER_STATES.CHUNK.FIELDS.IS_GETTING_CHUNK]: false,
      };
    }
    case ACTIONS.GET_CHUNK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.CHUNK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.CHUNK.FIELDS.IS_GETTING_CHUNK]: false,
      };
    }
    case ACTIONS.RESET_CHUNK_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
