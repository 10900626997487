import API from '../axios/index';
import ENDPOINTS from '../constants/endpoints';
import { extractResponse } from '../axios/extractResponse';
import { errorHandler } from '../axios/errorHandler';

/**
 * Register new user
 * @param {Object} body
 * @return {Promise<*>}
 */
const handleRegister = async (body) => {
  try {
    const res = await API.post(ENDPOINTS.SIGN_UP, body);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Log in
 * @param {string} email
 * @param {string} password
 * @return {Promise<*>}
 */
const handleLogin = async ({ email, password }) => {
  try {
    const res = await API.post(ENDPOINTS.LOG_IN, { email, password });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get session status for current user
 * @param {boolean} isDisplayError
 * @return {Promise<*>}
 */
const getCurrentUser = async (isDisplayError = true) => {
  try {
    const res = await API.get(ENDPOINTS.CURRENT_USER);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err, isDisplayError);
  }
};

/**
 * Send email confirmation for 1st time
 * @param {string} token - optional
 * @param {string} userId
 * @return {Promise<*>}
 */
const sendEmailConfirmation = async ({ token, userId }) => {
  try {
    const res = await API.get(ENDPOINTS.VERIFY_EMAIL + `?token=${token}&userId=${userId}`);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Resend confirmation email
 * @param {string} userId - user's uuid
 * @param {string} destUrl - link to confirmation email
 * @return {Promise<*>}
 */
const reSentEmailConfirmation = async ({ userId, destUrl }) => {
  try {
    const res = await API.post(ENDPOINTS.RESEND, { userId, destUrl });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Reset password
 * @param {String} token
 * @param {String} userId
 * @param {String} newPassword
 * @return {Promise<*>}
 */
const handleResetPassword = async ({ token, userId, newPassword }) => {
  try {
    const res = await API.post(ENDPOINTS.RESET_PASSWORD, { token, userId, newPassword });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * TODO: add destUrl after solve env issue
 * Resend reset password email
 * @param {String} destUrl
 * @param {String} email
 * @return {Promise<*>}
 */
const handleResendResetPassword = async ({ email, destUrl }) => {
  try {
    const res = await API.post(ENDPOINTS.RESEND_RESET_PASSWORD, { email, destUrl });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Update profile
 * @param {string} name
 * @param {string} email
 * @param {string} password
 * @param {boolean} hearingAid
 * @param {boolean} normalHearingMod
 * @param {Object} preferences
 * @return {Promise<*>}
 */
const handleUpdateProfile = async ({
  name,
  email,
  password,
  hearingAid,
  normalHearingMod,
  preferences,
}) => {
  try {
    const res = await API.put(ENDPOINTS.PROFILE, {
      name,
      email,
      password,
      hearingAid,
      normalHearingMod,
      preferences,
    });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Logout
 * @return {Promise<*>}
 */
const handleLogout = async () => {
  try {
    const res = await API.post(ENDPOINTS.LOG_OUT);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

export {
  getCurrentUser,
  handleRegister,
  sendEmailConfirmation,
  reSentEmailConfirmation,
  handleLogin,
  handleLogout,
  handleResetPassword,
  handleResendResetPassword,
  handleUpdateProfile,
};
