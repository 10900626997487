import React, { useEffect } from 'react';
import { GradientBtn, TextBtn } from '../components/Button.style';
import { Col, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../constants/constants';
import { get } from 'lodash';
import { REDUCER_STATES } from '../constants/reducerStates';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import { USER_ROLE_ENUM } from '@client/constants/api/account';
import { getSessionStatus } from '@client/stores/actions/account.action';

const Landing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector((state) =>
    get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN], false)
  );
  const loginUser = useSelector((state) =>
    get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER], null)
  );

  useEffect(() => {
    dispatch(getSessionStatus(false));
  }, []);

  if (isLoggedIn) {
    return (
      <React.Fragment>
        <Row style={{ margin: '.5rem 0 1rem 0' }}>
          <Col>
            <p style={{ fontSize: '1.2rem' }}>
              Let AI do all the work for calibrating your implant
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col span={24}>
            <GradientBtn
              onClick={() => {
                if (loginUser?.[ACCOUNT_FIELDS.ROLE] === USER_ROLE_ENUM.ADMIN) {
                  history.push(ROUTES.ADMIN);
                } else {
                  history.push(ROUTES.DASHBOARD);
                }
              }}
              buttonLabel='Get started'
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row style={{ margin: '.5rem 0 1rem 0' }}>
          <Col>
            <p style={{ fontSize: '1.2rem' }}>
              Let AI do all the work for calibrating your implant
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: '1rem' }}>
          <Col span={24}>
            <GradientBtn
              onClick={() => {
                history.push(ROUTES.SIGNUP);
              }}
              buttonLabel='Sign Up'
            />
          </Col>
          <Col span={24}>
            <TextBtn
              buttonLabel='Sign In'
              height='3rem'
              onClick={() => {
                history.push(ROUTES.LOGIN);
              }}
            />
          </Col>
        </Row>

        <Row justify='space-around'>
          <Col>
            <p style={{ fontSize: '9px' }}>
              <Link to={ROUTES.TERMS_OF_SERVICE}>Terms of Service and Privacy Policy</Link>
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default Landing;
