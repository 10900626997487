import API from '../axios/index';
import ENDPOINTS from '../constants/endpoints';
import { extractResponse } from '../axios/extractResponse';
import { errorHandler } from '../axios/errorHandler';

/**
 * Get default chunk
 * @return {Promise<*>}
 */

/**
 * Get one chunk by ID
 * @param {string} chunkId
 * @return {Promise<*>}
 */
const handleGetChunkById = async (chunkId) => {
  try {
    const res = await API.get(ENDPOINTS.CHUNKS({ chunkId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

export { handleGetChunkById };
