/**
 * Message values definition. It is used to show user messages e.g. Warning, info etc..
 */
export default {
  CHANGE_SAVED: 'Change saved',
  DATA_NOT_AVAILABLE: 'Data is not available',
  DATA_NOT_SUPPORTED: 'Data structure incorrect',
  DATA_SAVED: 'Data saved',
  EXISTING_ENTITY: 'Entity exists',
  FILE_UPLOADED: 'File uploaded',
  INVALID_OPERATION: 'Invalid operation',
  PASSWORD_UPDATED: 'Password has been updated',
  PASSWORD_RESET_SUCCESS: 'Your password has been reset, please re-login.',
  UNAUTHENTICATED: 'Action required user login',
  UNAUTHORIZED: 'Permission denied',
  UNKNOWN_ERROR: 'An unknown error occurred, please try later',
  USERNAME_PASSWORD_MISMATCH: 'Username or password is incorrect',
  SCHEDULES_PLANNED: 'New schedules have been created',
  SERVER_INERNAL_ERROR: 'Server internal error, please again try later',
  VERIFY_EMAIL: 'A verification link has been sent to your email address.',
  VERIFY_EMAIL_SUCCESS:
    'Your email address has been verified and your registration is currently under review. The account will ' +
    'be activated when it is approved.',
  VERIFY_EMAIL_FAILURE: 'This verification link is invalid.',
  MICROPHONE_REQUEST_ERROR: 'Error occurs when requesting for a microphone.',
};
