const EMAIL_FORMAT_RULE = [
  {
    required: true,
    message: 'Email is required.',
  },
  {
    type: 'email',
    message: 'Please enter a valid email.',
  },
  {
    max: '50',
    message: 'A maximum length of 50 character is required.',
  },
];

const PASSWORD_FORMAT_RULE = [
  {
    required: true,
    message: 'Password is required.',
  },
  {
    min: 8,
    message: 'a minimum password length of 8 characters is required.',
  },
  {
    max: 25,
    message: 'a minimum password length of 25 characters is required.',
  },
];

const USER_NAME_FORMAT_RULE = [
  {
    required: true,
    message: 'Name is required.',
  },
  {
    min: 6,
    message: 'A minimum length of 6 character is required.',
  },
  {
    max: 50,
    message: 'A maximum length of 50 character is required.',
  },
];

export { EMAIL_FORMAT_RULE, PASSWORD_FORMAT_RULE, USER_NAME_FORMAT_RULE };
