import ACTIONS from '../actionsType';
import {
  handleCreateUser,
  handleGetAdminResponses,
  handleGetAdminWorkspaces,
  handleGetUsers,
} from '@client/apiServices';

/**
 * Get users
 * @return {(function(*): void)|*}
 */
const getUsers = () => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_USERS,
  });
  handleGetUsers()
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_USERS_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_USERS_ERROR,
        payload: err,
      });
    });
};

/**
 * Get workspaces for admin user
 * @return {(function(*): void)|*}
 */
const getAdminWorkspaces = () => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_WORKSPACES_ADMIN,
  });
  handleGetAdminWorkspaces()
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_WORKSPACES_ADMIN_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_WORKSPACES_ADMIN_ERROR,
        payload: err,
      });
    });
};

/**
 * Get responses for admin user
 * @param {[]|Array} userId
 * @param {[]|Array} workspaceId
 * @return {(function(*): void)|*}
 */
const getAdminResponses =
  ({ userId, workspaceId }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.GET_RESPONSES_ADMIN,
    });
    handleGetAdminResponses({ userId, workspaceId })
      .then((res) => {
        dispatch({
          type: ACTIONS.GET_RESPONSES_ADMIN_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.GET_RESPONSES_ADMIN_ERROR,
          payload: err,
        });
      });
  };

/**
 * Create new user
 * @param {string} name
 * @param {string} email
 * @param {boolean} emailVerified
 * @param {string} password
 * @param {boolean} hearingAid
 * @param {boolean} normalHearingMod
 * @return {(function(*): void)|*}
 */
const createUser =
  ({ name, email, emailVerified, password, hearingAid, normalHearingMod }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.CREATE_USER,
    });
    handleCreateUser({
      name,
      email,
      emailVerified,
      password,
      hearingAid,
      normalHearingMod,
    })
      .then((res) => {
        dispatch({
          type: ACTIONS.CREATE_USER_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.CREATE_USER_ERROR,
          payload: err,
        });
      });
  };

export { getUsers, createUser, getAdminWorkspaces, getAdminResponses };
