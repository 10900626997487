import API from '../axios/index';
import ENDPOINTS from '../constants/endpoints';
import { extractResponse } from '../axios/extractResponse';
import { errorHandler } from '../axios/errorHandler';
import { PLAYBACK_FIELDS } from '@client/constants/api';

/**
 * Get all playbacks
 * @return {Promise<*>}
 */
const handleGetPlaybacks = async () => {
  try {
    const res = await API.get(ENDPOINTS.PLAYBACKS);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get one playback
 * @param {string} playbackId
 * @return {Promise<*>}
 */
const handleGetPlayback = async (playbackId) => {
  try {
    const res = await API.get(ENDPOINTS.PLAYBACK({ playbackId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Create playback
 * Request is a FormData which values are arrays
 * @param {Array} file
 * @param {Array} workspaceId
 * @param {Array} taskId
 * @return {Promise<*>}
 */
const handleCreatePlayback = async ({ file, workspaceId, taskId }) => {
  try {
    const bodyFormData = new FormData();
    file.forEach((item) => {
      bodyFormData.append(PLAYBACK_FIELDS.FILE + '[]', item);
    });
    taskId.forEach((item) => {
      bodyFormData.append(PLAYBACK_FIELDS.TASK_ID + '[]', item);
    });
    workspaceId.forEach((item) => {
      bodyFormData.append(PLAYBACK_FIELDS.WORKSPACE_ID + '[]', item);
    });
    const res = await API.post(ENDPOINTS.PLAYBACKS, bodyFormData);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Update one playback
 * @param {string} playbackId
 * @param {string} workspaceId
 * @param {string} taskId
 * @return {Promise<*>}
 */
const handleUpdatePlayback = async ({ playbackId, workspaceId, taskId }) => {
  try {
    const body = {
      workspaceId,
      taskId,
    };
    const res = await API.put(ENDPOINTS.PLAYBACK({ playbackId }), body);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};
/**
 * Handle delete one playback
 * @param {string} playbackId
 * @return {Promise<*>}
 */
const handleDeletePlayback = async (playbackId) => {
  try {
    const res = await API.delete(ENDPOINTS.PLAYBACK({ playbackId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Download one playback
 * @param {string} playbackId
 * @return {Promise<*>}
 */
const handleDownloadPlayback = async (playbackId) => {
  try {
    const res = await API.get(ENDPOINTS.PLAYBACK_DOWNLOAD({ playbackId }), {
      responseType: 'arraybuffer',
    });
    return new Blob([res?.data], {
      type: res.headers['content-type'],
    });
  } catch (err) {
    errorHandler(err);
  }
};

export {
  handleCreatePlayback,
  handleGetPlaybacks,
  handleGetPlayback,
  handleUpdatePlayback,
  handleDeletePlayback,
  handleDownloadPlayback,
};
