import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DIGIT_LENGTH, RESPONSE_TYPE, TASKS_STATUS, TEST_TYPE } from '@client/constants/constants';
import {
  CHUNK_FIELDS,
  PLAYBACK_FIELDS,
  RESPONSE_FIELDS,
  TASK_FIELDS,
  TRACK_FIELDS,
  WORKSPACE_FIELDS,
} from '@client/constants/api';
import { createPlaybackThenResponse } from '@client/stores/actions/playback';
import { createResponse } from '@client/stores/actions/response';
import { GradientBtn, OutlinedBtn } from '@client/components/Button.style';
import { AdjustMicrophone, AdjustVolume } from '@client/components/AdjustPageContent';
import DigitTest from '@client/components/DigitTripletTest';
import PhonemeTest from '@client/components/PhonemeTest';
import SentenceTest from '@client/components/SentenceTest';
import { StyledGradientProgressBar } from '@client/components/svg/Progress';
import { FooterLayout } from '@client/templates/Layout';
import { downloadTracks, getRandomTrack } from '@client/stores/actions/track';
import ACTIONS from '@client/stores/actionsType';
import { useDispatch, useSelector } from 'react-redux';
import { createWorkspaceTask } from '@client/stores/actions/workspace';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';

const TestContent = ({
  testPages,
  pageState,
  setPageState,
  workspaceId,
  workspace,
  workspaceError,
  isGettingTrack,
  wasGettingTrack,
}) => {
  const dispatch = useDispatch();
  const [currentTaskState, setCurrentTaskState] = useState({
    complete: true,
    answer: null,
  });

  // Redux State Workspace Tasks
  const task = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_TASK],
      false
    )
  );
  const isCreatingTask = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE_TASK],
      false
    )
  );
  const wasCreatingTask = useRef(isCreatingTask);
  const isUpdatingTask = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.IS_UPDATING_WORKSPACE_TASK],
      false
    )
  );
  const wasUpdatingTask = useRef(isUpdatingTask);
  // Redux State Response
  const isCreatingResponse = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.RESPONSE.NAME, REDUCER_STATES.RESPONSE.FIELDS.IS_CREATING_RESPONSE],
      null
    )
  );

  const spinning = !!(isCreatingResponse || isUpdatingTask);
  const [testState, setTestState] = useState({
    tracks: [], //for digit test this stores the current test tracks (3)
    currentTrack: null, //for phoneme and sentence test this store the current test track (1)
    currentTask: null,
    currentTaskIsComplete: false,
    currentTaskAnswer: null, //answers of current test type
    pageIndex: 0,
    testNo: 0, //starts from 1 ends at 5
  });
  /*
   * Get track(s) and task ready for current question chunk
   */
  const getTracksAndTaskReady = (currentChunk, taskOfChunk) => {
    /*
     For digit triplet test, we get tracks for current question before each question starts.
     We handle the download of the tracks in DigitTest Component.
     */
    if (pageState.testType === TEST_TYPE.DIGIT) {
      // If we are retaking a test, we have tracks in pageState, we just download the tracks during test
      if (pageState.tracks.length === 5) {
        setTestState((prevState) => {
          return {
            ...prevState,
            currentTask: taskOfChunk,
            currentTaskIsComplete: false,
            tracks: pageState.tracks[testState.testNo - 1],
          };
        });
        dispatch(downloadTracks(pageState.tracks[testState.testNo - 1]))
          .then((tracksWithFile) => {
            dispatch({
              type: ACTIONS.DOWNLOAD_TRACK_FULFILLED,
              payload: tracksWithFile,
            });
          })
          .catch((error) => {
            dispatch({
              type: ACTIONS.DOWNLOAD_TRACK_ERROR,
              payload: error,
            });
          });
      } else {
        setTestState((prevState) => {
          return {
            ...prevState,
            currentTask: taskOfChunk,
            currentTaskIsComplete: false,
          };
        });
        dispatch(
          getRandomTrack(
            currentChunk[CHUNK_FIELDS.TRACK_NUM],
            currentChunk[CHUNK_FIELDS.TRACK_TAGS]
          )
        );
      }
    } else {
      /*
       For phoneme/sentence test, we got all tracks in advance, thus we just download the track before each question starts
       */
      const currentTrack = pageState.tracks?.[testState.testNo - 1];
      setTestState((prevState) => {
        return {
          ...prevState,
          currentTask: taskOfChunk,
          currentTaskIsComplete: false,
          currentTrack: currentTrack,
        };
      });
      dispatch(downloadTracks([currentTrack]))
        .then((tracksWithFile) => {
          dispatch({
            type: ACTIONS.DOWNLOAD_TRACK_FULFILLED,
            payload: tracksWithFile,
          });
        })
        .catch((error) => {
          dispatch({
            type: ACTIONS.DOWNLOAD_TRACK_ERROR,
            payload: error,
          });
        });
    }
  };

  /*
   * If current page is a real test, get tracks and task ready for this test
   */
  useEffect(() => {
    if (testState.testNo > 0 && workspace) {
      //taking a real test
      const currentChunk = pageState.chunks[testState.testNo - 1];
      // If no task for current chunk, create task
      const taskOfChunk = workspace[WORKSPACE_FIELDS.TASKS]?.find(
        (task) => task[TASK_FIELDS.CHUNK_ID] === currentChunk[CHUNK_FIELDS.ID]
      );
      if (!taskOfChunk) {
        dispatch(
          createWorkspaceTask({
            workspaceId: workspaceId,
            chunkId: currentChunk?.[CHUNK_FIELDS.ID],
            status: TASKS_STATUS.PENDING,
          })
        );
      } else {
        getTracksAndTaskReady(currentChunk, taskOfChunk);
      }
    }
  }, [testState.testNo]);
  useEffect(() => {
    if (wasCreatingTask.current && !isCreatingTask && !workspaceError) {
      getTracksAndTaskReady(pageState.chunks[testState.testNo - 1], task);
    }
    wasCreatingTask.current = isCreatingTask;
  }, [wasCreatingTask, isCreatingTask, workspaceError, getTracksAndTaskReady]);

  /*
   * Get answer from test page component(digit/phoneme/sentence)
   * If answer is provided, send response to server
   * (If is PhonemeTest, create playback
   */
  const sendResponse = (testType) => {
    const trackId =
      pageState.testType === TEST_TYPE.DIGIT
        ? testState.tracks?.map((tr) => tr?.[TRACK_FIELDS.ID])
        : [testState.currentTrack[TRACK_FIELDS.ID]];
    const workspaceId = workspace[WORKSPACE_FIELDS.ID];
    const taskId = testState.currentTask[TASK_FIELDS.ID];
    const responseAnswer = [];
    if (testType === TEST_TYPE.PHONEME) {
      const audioFile = new File([currentTaskState.answer], 'myRecord.mp3', {
        type: currentTaskState.answer?.type,
        lastModified: Date.now(),
      });
      dispatch(
        createPlaybackThenResponse({
          [PLAYBACK_FIELDS.FILE]: [audioFile],
          [PLAYBACK_FIELDS.WORKSPACE_ID]: [workspaceId],
          [PLAYBACK_FIELDS.TASK_ID]: [taskId],
          [RESPONSE_FIELDS.ANSWERS_TRACK_ID]: trackId,
        })
      );
    } else {
      if (testType === TEST_TYPE.DIGIT) {
        for (let i = 0; i < DIGIT_LENGTH; i++) {
          responseAnswer.push({
            [RESPONSE_FIELDS.ANSWERS_TRACK_ID]: trackId[i],
            [RESPONSE_FIELDS.ANSWERS_TEXT]: currentTaskState.answer?.[i],
          });
        }
      } else {
        responseAnswer.push({
          [RESPONSE_FIELDS.ANSWERS_TRACK_ID]: trackId[0],
          [RESPONSE_FIELDS.ANSWERS_TEXT]: currentTaskState.answer,
        });
      }
      dispatch(
        createResponse({
          workspaceId: workspaceId,
          taskId: taskId,
          type: RESPONSE_TYPE.TEXT,
          answers: responseAnswer,
        })
      );
    }
  };
  /*
   * If we just updated a task, that means we we just completed a task.
   * Go to next page or complete test according to the current testNo & pageIndex
   */
  useEffect(() => {
    if (wasUpdatingTask.current && !isUpdatingTask && !workspaceError) {
      if (testState.pageIndex + 1 === testPages.length) {
        //last task completed
        setPageState((prevState) => {
          return {
            ...prevState,
            testType: TEST_TYPE.UNSET,
            chunks: [],
            tracks: [],
          };
        });
      } else nextPage();
    }
    wasUpdatingTask.current = isUpdatingTask;
  }, [
    wasUpdatingTask.current,
    isUpdatingTask,
    workspaceError,
    testState.pageIndex,
    testPages,
    setTestState,
  ]);
  /*
   * Increase pageIndex
   * If next page is a real test, also increase the testNo
   */
  const nextPage = () => {
    if (
      testState.pageIndex + 1 === testPages.length ||
      testPages[testState.pageIndex + 1] === pageState.testType
    ) {
      setTestState((prevState) => {
        return {
          ...prevState,
          pageIndex: testState.pageIndex + 1,
          testNo: testState.testNo + 1,
        };
      });
      setCurrentTaskState({ answer: null, complete: false });
    } else {
      setTestState((prevState) => {
        return { ...prevState, pageIndex: testState.pageIndex + 1 };
      });
    }
  };

  const NextBtn = (
    <OutlinedBtn
      buttonLabel='Next'
      disabled={!currentTaskState.complete}
      onClick={() => {
        // Show error notification if user click next button without finishing the test
        const currentTestType = testPages?.[testState.pageIndex];
        if (
          currentTestType !== TEST_TYPE.ADJUST_MICROPHONE &&
          currentTestType !== TEST_TYPE.ADJUST_VOLUME
        ) {
          sendResponse(currentTestType);
        } else {
          nextPage();
        }
      }}
    />
  );
  const CompleteBtn = (
    <GradientBtn
      disabled={!currentTaskState.complete}
      buttonLabel='Complete'
      onClick={useCallback(() => {
        sendResponse(testPages?.[testState.pageIndex]);
      }, [sendResponse, testPages, testState.pageIndex])}
    />
  );

  // const DigitTestMemo = React.memo(DigitTest);
  const DynamicContent = useCallback(() => {
    if (testPages[testState.pageIndex] === TEST_TYPE.ADJUST_VOLUME) return <AdjustVolume />;
    else if (testPages[testState.pageIndex] === TEST_TYPE.ADJUST_MICROPHONE)
      return <AdjustMicrophone />;
    else if (pageState.testType === TEST_TYPE.DIGIT) {
      return (
        <DigitTest
          pageState={pageState}
          testState={testState}
          setTestState={setTestState}
          setCurrentTaskState={setCurrentTaskState}
          spinning={spinning}
          isGettingTrack={isGettingTrack}
          wasGettingTrack={wasGettingTrack}
        />
      );
    } else if (pageState.testType === TEST_TYPE.PHONEME) {
      return (
        <PhonemeTest
          pageState={pageState}
          testState={testState}
          setCurrentTaskState={setCurrentTaskState}
          spinning={spinning}
        />
      );
    } else
      return (
        <SentenceTest
          pageState={pageState}
          testState={testState}
          setCurrentTaskState={setCurrentTaskState}
          spinning={spinning}
        />
      );
  }, [pageState, testState, setCurrentTaskState, spinning, isGettingTrack, wasGettingTrack]);

  return (
    <FooterLayout
      PageContent={
        <React.Fragment>
          <DynamicContent />
          <StyledGradientProgressBar
            percentage={Math.round(((testState.pageIndex + 1) / testPages.length) * 100)}
          />
        </React.Fragment>
      }
      FooterContent={testState.pageIndex + 1 === testPages.length ? CompleteBtn : NextBtn}
    />
  );
};
export default TestContent;
