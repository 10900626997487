import { useState, useEffect, useMemo } from 'react';

const useAudioInSequence = (audioSrcArr) => {
  const audioArr = useMemo(() => {
    return audioSrcArr?.map((src) => new Audio(src));
  }, [audioSrcArr]);
  const [state, setState] = useState({
    currentAudio: audioArr?.[0],
    isPlaying: false,
    currentIsPlaying: false,
  });
  useEffect(() => {
    setState({ currentAudio: audioArr?.[0], isPlaying: false, currentIsPlaying: false });
  }, [audioArr]);

  const playCurrentAudio = () => {
    if (!state.currentIsPlaying) {
      const playedPromise = state?.currentAudio?.play();
      if (playedPromise) {
        playedPromise.catch((e) => {
          console.log(e);
        });
      }
    }
  };

  // Play or pause the audio according to state
  useEffect(() => {
    if (state.isPlaying) {
      playCurrentAudio();
    } else {
      state.currentAudio?.pause();
    }
  }, [state.isPlaying, state.currentAudio]);

  // Add end event listener
  useEffect(() => {
    let timer;
    let mount = true;
    const listener = () => {
      const i = audioArr.indexOf(state.currentAudio);
      if (i >= 0 && i < audioArr?.length - 1) {
        timer = setTimeout(() => {
          if (mount)
            setState((prevState) => {
              return { ...prevState, currentAudio: audioArr?.[i + 1], currentIsPlaying: false };
            });
        }, 500);
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            currentAudio: audioArr?.[0],
            currentIsPlaying: false,
            isPlaying: false,
          };
        });
      }
    };
    audioArr?.forEach((audio) => {
      audio?.addEventListener('ended', listener);
    });
    return () => {
      mount = false;
      clearTimeout(timer);
      audioArr?.forEach((audio) => {
        audio?.removeEventListener('ended', listener);
      });
    };
  }, [state]);
  // Stop the audio when the component unmounts
  useEffect(() => {
    return () => {
      state.currentAudio?.pause();
    };
  }, []);

  const setIsPlaying = (value) => {
    setState((prevState) => {
      return { ...prevState, isPlaying: value };
    });
  };

  return {
    audio: state.currentAudio,
    isPlaying: state.isPlaying,
    setIsPlaying: setIsPlaying,
  };
};
export default useAudioInSequence;
