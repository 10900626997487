import { Col, Row } from 'antd';
import React from 'react';

const TermsOfService = () => {
  return (
    <Row>
      <Col span={24}>
        <p style={{ fontSize: '1.4rem' }}>To be added</p>
      </Col>
    </Row>
  );
};
export default TermsOfService;
