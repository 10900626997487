import Svg from '../Svg';
import React from 'react';

const BackSpace = () => (
  <Svg width='0.8em' viewBox='0 0 46.086 32.942'>
    <path
      d='M35.149,21.471l3.327-3.327a2.353,2.353,0,0,0-3.327-3.327l-3.327,3.327-3.327-3.327a2.353,2.353,0,0,0-3.384,3.271c.019.019.038.04.056.056l3.327,3.327L25.168,24.8a2.353,2.353,0,1,0,3.271,3.384c.021-.019.04-.04.056-.056L31.822,24.8l3.327,3.327a2.353,2.353,0,0,0,3.384-3.271c-.019-.021-.04-.04-.056-.056ZM19.652,5H43.587a4.708,4.708,0,0,1,4.706,4.706v23.53a4.708,4.708,0,0,1-4.706,4.706H19.652a4.707,4.707,0,0,1-3.327-1.379L2.9,23.134a2.35,2.35,0,0,1,0-3.327L16.325,6.379A4.707,4.707,0,0,1,19.652,5Z'
      transform='translate(-2.207 -5)'
      fill='currentColor'
    />
  </Svg>
);
export default BackSpace;
