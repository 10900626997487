import Svg from '../Svg';
import React from 'react';

const LogoWithName = ({ className }) => (
  <Svg width='229' height='362' viewBox='0 0 229 362' className={className}>
    <path
      d='M53.5,254A53.455,53.455,0,0,1,0,200.5c0-1.166.038-2.343.113-3.5H0V96.5A96.05,96.05,0,0,1,16.481,42.546,96.781,96.781,0,0,1,58.938,7.583a96.373,96.373,0,0,1,91.516,8.9,96.781,96.781,0,0,1,34.962,42.457A96.536,96.536,0,0,1,106.405,192.5a54.129,54.129,0,0,1,.595,8A53.456,53.456,0,0,1,53.5,254Zm16-172a14.5,14.5,0,1,0,0,29h16v16a14.5,14.5,0,0,0,29,0V111h16a14.5,14.5,0,1,0,0-29h-16V66a14.5,14.5,0,0,0-29,0V82Z'
      transform='translate(36 0)'
      fill='#currentColor'
    />
    <text
      id='tune.it'
      transform='translate(0 344)'
      fill='#currentColor'
      fontSize='78'
      fontFamily='Helvetica'
    >
      <tspan x='0' y='0'>
        tune.it
      </tspan>
    </text>
  </Svg>
);
export default LogoWithName;
