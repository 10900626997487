import ACTIONS from '../actionsType';
import { handleGetChunkById } from '@client/apiServices/chunk';

/**
 * Get one chunk
 * @param {string} chunkId
 * @return {(function(*): void)|*}
 */
const getChunkById = (chunkId) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_CHUNK,
  });
  handleGetChunkById(chunkId)
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_CHUNK_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ACTIONS.GET_CHUNK_ERROR,
        payload: err,
      });
    });
};

export { getChunkById };
