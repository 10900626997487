import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { getSessionStatus } from '@client/stores/actions/account.action';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { Redirect, Route } from 'react-router-dom';
import { CenterContainer } from '@client/components/Container.style';
import { SpinWithColor } from '@client/components/Loading';
import { ROUTES } from '@client/constants/constants';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import { USER_ROLE_ENUM } from '@client/constants/api/account';

const AdminRoutes = (props) => {
  const dispatch = useDispatch();
  const { component: Component, path } = props;
  const [canCheck, setCanCheck] = useState(false);

  const isGettingSession = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_GETTING_SESSION_STATUS],
      false
    )
  );
  const previousIsGettingSession = useRef(isGettingSession);
  const loginUser = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER], null)
  );

  /**
   * Get current user when path change
   */
  useEffect(() => {
    dispatch(getSessionStatus());
  }, [path]);

  useEffect(() => {
    if (previousIsGettingSession.current && !isGettingSession) {
      setCanCheck(true);
    }
    previousIsGettingSession.current = isGettingSession;
  }, [isGettingSession]);

  return (
    <Route
      render={() => {
        if (!isGettingSession && canCheck) {
          if (loginUser?.[ACCOUNT_FIELDS.ROLE] === USER_ROLE_ENUM.ADMIN) {
            return <Component {...props} />;
          } else {
            return <Redirect to={ROUTES.LOGIN} />;
          }
        } else {
          return (
            <CenterContainer>
              <SpinWithColor size={'large'} />
            </CenterContainer>
          );
        }
      }}
    />
  );
};

export default AdminRoutes;
