import { useState, useEffect, useMemo } from 'react';

/**
 * Custom Hook to play or pause a given audio
 * @param {string|undefined} audioSrc -- url of the audio file
 * @return {{isPlaying: boolean, setIsPlaying: void, audio: HTMLAudioElement}}
 */
const useAudio = (audioSrc) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audio = useMemo(() => new Audio(audioSrc), [audioSrc]);

  // Play or pause the audio according to state
  useEffect(() => {
    if (isPlaying) {
      const playedPromise = audio.play();
      if (playedPromise) {
        playedPromise.catch((e) => {
          console.log(e);
        });
      }
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  // Add end event listener
  useEffect(() => {
    const listener = () => {
      if (isPlaying) setIsPlaying(false);
    };
    audio.addEventListener('ended', listener);
    return () => {
      audio.removeEventListener('ended', listener);
    };
  });
  // Stop the audio when the component unmounts
  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, []);

  return {
    audio: audio,
    isPlaying: isPlaying,
    setIsPlaying: setIsPlaying,
  };
};
export default useAudio;
