import styled from 'styled-components';
import { Spin } from 'antd';

/**
 * The default spin is pink color which cannot be seen in pink background
 * @param {Object} props
 * @return {JSX.Element}
 */
const SpinWithColor = styled(Spin)`
  .ant-spin-dot.ant-spin-dot-spin {
    .ant-spin-dot-item {
      background-color: ${(props) => (props.color ? props.color : 'white')};
    }
  }
`;

export { SpinWithColor };
