import React from 'react';
import Icon from '@ant-design/icons';
import QuotationMark from './svg/QuotationMark';
import ABC from './svg/ABC';
import OneTwoThree from './svg/OneTwoThree';
import Microphone from './svg/Microphone';
import StopRecord from './svg/StopRecord';
import Backspace from './svg/Backspace';

const DigitIcon = (props) => <Icon component={OneTwoThree} {...props} />;
const PhonemeIcon = (props) => <Icon component={ABC} {...props} />;
const QuoteIcon = (props) => <Icon component={QuotationMark} {...props} />;
const MicrophoneIcon = (props) => <Icon component={Microphone} {...props} />;
const StopIcon = (props) => <Icon component={StopRecord} {...props} />;
const BackspaceIcon = (props) => <Icon component={Backspace} {...props} />;

const CustomIcon = {
  DigitIcon,
  PhonemeIcon,
  QuoteIcon,
  MicrophoneIcon,
  StopIcon,
  BackspaceIcon,
};
export default CustomIcon;
