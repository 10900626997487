/*
 * Page Content to select test type
 * When test type is selected, update the testState with the filtered chunks.
 * For phoneme/sentence test, get  tracks for all chunks before test starts
 * For digit test, get tracks for each chunk after test starts
 */
import {
  RESPONSE_TYPE,
  ROUTES,
  TASKS_STATUS,
  TEST_TYPE,
  TRACK_TAGS,
} from '@client/constants/constants';
import React, { useCallback, useEffect, useState } from 'react';
import {
  handleDeletePlayback,
  handleDeleteResponse,
  handleGetResponses,
  handleGetTrack,
  handleUpdateWorkspaceTask,
} from '@client/apiServices';
import {
  CHUNK_FIELDS,
  RESPONSE_FIELDS,
  TASK_FIELDS,
  WORKSPACE_FIELDS,
} from '@client/constants/api';
import Layout, { Content, Footer } from 'antd/es/layout/layout';
import Modal from 'antd/es/modal/Modal';
import { Button, Col, Row, Spin } from 'antd';
import { TextIconBtn } from '@client/components/Button.style';
import CustomIcon from '@client/components/CustomIcon';
import { CheckCircleTwoTone, EditTwoTone, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { getRandomTrack } from '@client/stores/actions/track';
import { useDispatch, useSelector } from 'react-redux';
import { PRIMARY_COLOR, SUCCESS_GREEN } from '@client/assets/styles/global';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';

const SelectTest = ({
  pageState,
  setPageState,
  workspaceId,
  workspace,
  rootChunk,
  isGettingWorkspace,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const digitChunks = rootChunk?.[CHUNK_FIELDS.CHILDREN]?.filter((chunk) =>
    chunk[CHUNK_FIELDS.TRACK_TAGS].includes(TRACK_TAGS.DIGIT)
  );
  const phonemeChunks = rootChunk?.[CHUNK_FIELDS.CHILDREN]?.filter((chunk) =>
    chunk[CHUNK_FIELDS.TRACK_TAGS].includes(TRACK_TAGS.PHONEME)
  );
  const sentenceChunks = rootChunk?.[CHUNK_FIELDS.CHILDREN]?.filter((chunk) =>
    chunk[CHUNK_FIELDS.TRACK_TAGS].includes(TRACK_TAGS.SENTENCE)
  );
  const isGettingChunk = useSelector((state) =>
    _.get(state, [REDUCER_STATES.CHUNK.NAME, REDUCER_STATES.CHUNK.FIELDS.IS_GETTING_CHUNK], null)
  );

  const [selectTestState, setSelectTestState] = useState({
    isCheckingTask: false,
    digitTasks: [],
    phonemeTasks: [],
    sentenceTasks: [],
    digitTestComplete: false,
    phonemeTestComplete: false,
    sentenceTestComplete: false,
  });
  /*
   * Check status of all tasks on select test page, to show status of three types of tests (Digit/Phoneme/Sentence)
   */
  useEffect(() => {
    if (rootChunk && pageState.testType === TEST_TYPE.UNSET && workspace) {
      setSelectTestState((prevState) => {
        return { ...prevState, isCheckingTask: true };
      });
    }
  }, [rootChunk, workspace, pageState.testType]);

  useEffect(() => {
    if (selectTestState.isCheckingTask && workspace) {
      const digitTasks = [];
      const phonemeTasks = [];
      const sentenceTasks = [];
      let digitTestComplete = true;
      let phonemeTestComplete = true;
      let sentenceTestComplete = true;
      for (const task of workspace[WORKSPACE_FIELDS.TASKS]) {
        if (
          digitChunks?.map((chunk) => chunk[CHUNK_FIELDS.ID]).includes(task[TASK_FIELDS.CHUNK_ID])
        ) {
          // is a digit task
          digitTasks.push(task);
          if (task[TASK_FIELDS.STATUS] !== TASKS_STATUS.COMPLETED) digitTestComplete = false;
        } else if (
          phonemeChunks?.map((chunk) => chunk[CHUNK_FIELDS.ID]).includes(task[TASK_FIELDS.CHUNK_ID])
        ) {
          // is phoneme task
          phonemeTasks.push(task);
          if (task[TASK_FIELDS.STATUS] !== TASKS_STATUS.COMPLETED) phonemeTestComplete = false;
        } else if (
          sentenceChunks
            ?.map((chunk) => chunk[CHUNK_FIELDS.ID])
            .includes(task[TASK_FIELDS.CHUNK_ID])
        ) {
          // is sentence task
          sentenceTasks.push(task);
          if (task[TASK_FIELDS.STATUS] !== TASKS_STATUS.COMPLETED) sentenceTestComplete = false;
        }
      }
      setSelectTestState({
        isCheckingTask: false,
        digitTasks: digitTasks,
        phonemeTasks: phonemeTasks,
        sentenceTasks: sentenceTasks,
        digitTestComplete: digitTasks.length === digitChunks.length && digitTestComplete,
        phonemeTestComplete: phonemeTasks.length === phonemeChunks.length && phonemeTestComplete,
        sentenceTestComplete:
          sentenceTasks.length === sentenceChunks.length && sentenceTestComplete,
      });
    }
  }, [selectTestState.isCheckingTask, rootChunk, workspace]);
  /**
   * Filter the chunks which is 1. of the selected test type and 2. is not completed (no corresponding completed task)
   * @param {Array} chunks
   * @param {Array} tasks
   * @return {*} Array of chunks
   */
  const filterChunks = (chunks, tasks) => {
    return chunks?.filter((chunk) => {
      const taskOfChunk = tasks?.find(
        (task) => task[TASK_FIELDS.CHUNK_ID] === chunk[CHUNK_FIELDS.ID]
      );
      return !(taskOfChunk && taskOfChunk[TASK_FIELDS.STATUS] === TASKS_STATUS.COMPLETED);
    });
  };
  /**
   * If selected test type is phoneme/sentence, calculate the total number of tracks required,
   * then get total number of random tracks from the server in one request, so that there would be no repeat tracks
   * @param {Array} chunks
   */
  const getAllTracksForChunks = (chunks) => {
    const trackTags = chunks?.[0]?.[CHUNK_FIELDS.TRACK_TAGS];
    const TotalTrackNumNeeded = chunks?.[0]?.[CHUNK_FIELDS.TRACK_NUM] * chunks?.length;
    dispatch(getRandomTrack(TotalTrackNumNeeded, trackTags));
  };
  /**
   * Function to handle the click of test type button
   * Filter incomplete chunks of selected type.
   * If is phoneme or sentence test, also get tracks ready before test start.
   * If all tests of the selected type is complete, show modal.
   * @param {String} type
   */
  const handleTypeClicked = (type) => {
    let chunks;
    let tasks;
    let typeAsText;
    let allComplete;
    if (type === TEST_TYPE.DIGIT) {
      if (selectTestState.digitTestComplete) {
        allComplete = true;
        typeAsText = 'Digit Triplet';
      }
      chunks = digitChunks;
      tasks = selectTestState.digitTasks;
    } else if (type === TEST_TYPE.PHONEME) {
      if (selectTestState.phonemeTestComplete) {
        allComplete = true;
        typeAsText = 'Phoneme';
      }
      chunks = phonemeChunks;
      tasks = selectTestState.phonemeTasks;
    } else {
      if (selectTestState.sentenceTestComplete) {
        allComplete = true;
        typeAsText = 'Sentence';
      }
      chunks = sentenceChunks;
      tasks = selectTestState.sentenceTasks;
    }
    if (allComplete) {
      setModal((prevState) => {
        return {
          ...prevState,
          testType: type,
          typeAsText: typeAsText,
        };
      });
      setModalIsVisible(true);
    } else {
      const incompleteChunks = filterChunks(chunks, tasks);
      setPageState((prevState) => {
        return {
          ...prevState,
          testType: type,
          chunks: incompleteChunks,
        };
      });
      if (type === TEST_TYPE.PHONEME || type === TEST_TYPE.SENTENCE)
        getAllTracksForChunks(incompleteChunks);
    }
  };

  const [modalIsLoading, setModalIsLoading] = useState(false);

  const modalInitialState = {
    typeAsText: 'undefined',
    testType: 'undefined',
  };
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modal, setModal] = useState(modalInitialState);
  const closeModal = () => {
    setModalIsVisible(false);
    setModal(modalInitialState);
  };
  const retakeTests = async (type) => {
    let tasks;
    let chunks;
    if (type === TEST_TYPE.DIGIT) {
      tasks = selectTestState.digitTasks;
      chunks = digitChunks;
    } else if (type === TEST_TYPE.PHONEME) {
      tasks = selectTestState.phonemeTasks;
      chunks = phonemeChunks;
    } else {
      tasks = selectTestState.sentenceTasks;
      chunks = sentenceChunks;
    }
    if (tasks) {
      const allResponses = await handleGetResponses();
      const workspaceResponses = allResponses.filter(
        (response) => response[RESPONSE_FIELDS.WORKSPACE_ID] === workspaceId
      );
      const tracks = [];
      for (let i = 0; i < tasks.length; i++) {
        const responseToDelete = workspaceResponses.find(
          (response) => response[RESPONSE_FIELDS.TASK_ID] === tasks[i][TASK_FIELDS.ID]
        );

        if (responseToDelete) {
          /*
            Memorize the tracks of the previous test, so we can retake the test with the same tracks
           */
          const tracksOfTask = [];
          const answers = responseToDelete[RESPONSE_FIELDS.ANSWERS];
          if (answers) {
            for (let i = 0; i < answers.length; i++) {
              const track = await handleGetTrack(answers[i]?.[RESPONSE_FIELDS.ANSWERS_TRACK_ID]);
              tracksOfTask.push(track);
            }
            tracks.push(tracksOfTask);
          }
          /*
            Delete previous responses and audio files if there's any
           */
          if (responseToDelete[RESPONSE_FIELDS.TYPE] === RESPONSE_TYPE.AUDIO) {
            const playBackId =
              responseToDelete[RESPONSE_FIELDS.ANSWERS]?.[0]?.[RESPONSE_FIELDS.ANSWERS_PLAYBACK_ID];
            await handleDeletePlayback(playBackId);
          }
          await handleDeleteResponse(responseToDelete[RESPONSE_FIELDS.ID]);
        }
        /*
          Update workspace task status to pending
         */
        await handleUpdateWorkspaceTask({
          workspaceId: workspaceId,
          taskId: tasks[i][TASK_FIELDS.ID],
          status: TASKS_STATUS.PENDING,
        });
      }
      return [tracks, chunks];
    }
  };
  const CompleteIcon = () => (
    <CheckCircleTwoTone twoToneColor={SUCCESS_GREEN} style={{ fontSize: '1.5rem' }} />
  );
  const InProgressIcon = () => (
    <EditTwoTone twoToneColor={PRIMARY_COLOR} style={{ fontSize: '1.5rem' }} />
  );
  const goToDashboard = () => {
    history.push(ROUTES.DASHBOARD);
  };
  return (
    <Layout style={{ height: '100%', background: 'inherit' }}>
      <Content>
        <Modal
          title={`Re-take all the ${modal.typeAsText} tests?`}
          visible={modalIsVisible}
          onCancel={closeModal}
          footer={[
            <Button key='back' onClick={closeModal}>
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={modalIsLoading}
              onClick={async () => {
                setModalIsLoading(true);
                // setModal((prevState) => {
                //   return { ...prevState, loading: true };
                // });
                const [tracks, chunks] = await retakeTests(modal.testType);
                closeModal();
                const processedTracks =
                  modal.testType === TEST_TYPE.PHONEME || modal.testType === TEST_TYPE.SENTENCE
                    ? tracks.map((tracksOfTask) => tracksOfTask[0])
                    : tracks;

                setPageState((prevState) => {
                  return {
                    ...prevState,
                    testType: modal.testType,
                    chunks: chunks,
                    tracks: processedTracks,
                  };
                });

                // if (modal.testType === TEST_TYPE.PHONEME || modal.testType === TEST_TYPE.SENTENCE)
                //   getAllTracksForChunks(chunks);
              }}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>All your responses for {modal.typeAsText} tests will be deleted. Are you sure?</p>
        </Modal>
        <Spin
          size={'large'}
          spinning={isGettingChunk || isGettingWorkspace || selectTestState.isCheckingTask}
        >
          <Row justify='space-between' align='middle'>
            <Col>
              <TextIconBtn
                {...{
                  Icon: <CustomIcon.DigitIcon />,
                  buttonLabel: 'Digit Triplet test',
                  ...(selectTestState.digitTestComplete && { textDecoration: 'line-through' }),
                  onClick: useCallback(() => {
                    handleTypeClicked(TEST_TYPE.DIGIT);
                  }, [handleTypeClicked]),
                }}
              />
            </Col>
            <Col>
              {!selectTestState.digitTestComplete && selectTestState.digitTasks?.length > 0 && (
                <InProgressIcon />
              )}
              {selectTestState.digitTestComplete && <CompleteIcon />}
            </Col>
          </Row>
          <Row justify='space-between' align='middle'>
            <Col>
              <TextIconBtn
                {...{
                  Icon: <CustomIcon.PhonemeIcon />,
                  buttonLabel: 'Phoneme test',
                  ...(selectTestState.phonemeTestComplete && { textDecoration: 'line-through' }),
                  onClick: useCallback(() => {
                    handleTypeClicked(TEST_TYPE.PHONEME);
                  }, [handleTypeClicked]),
                }}
              />
            </Col>
            <Col>
              {!selectTestState.phonemeTestComplete && selectTestState.phonemeTasks?.length > 0 && (
                <InProgressIcon />
              )}
              {selectTestState.phonemeTasks?.length > 0 && selectTestState.phonemeTestComplete && (
                <CompleteIcon />
              )}
            </Col>
          </Row>
          <Row justify='space-between' align='middle'>
            <Col>
              <TextIconBtn
                {...{
                  Icon: <CustomIcon.QuoteIcon />,
                  buttonLabel: 'Sentence test',
                  ...(selectTestState.sentenceTestComplete && { textDecoration: 'line-through' }),
                  onClick: useCallback(() => {
                    handleTypeClicked(TEST_TYPE.SENTENCE);
                  }, [handleTypeClicked]),
                }}
              />
            </Col>

            <Col>
              {!selectTestState.sentenceTestComplete &&
                selectTestState.sentenceTasks?.length > 0 && <InProgressIcon />}
              {selectTestState.sentenceTestComplete && <CompleteIcon />}
            </Col>
          </Row>
        </Spin>
      </Content>
      <Footer style={{ background: 'inherit', padding: '0px' }}>
        {selectTestState.sentenceTestComplete &&
        selectTestState.digitTestComplete &&
        selectTestState.phonemeTestComplete ? (
          <Row justify={'center'}>
            <Col>
              <TextIconBtn
                Icon={<SaveOutlined />}
                buttonLabel='Save results'
                onClick={goToDashboard}
                fontSize='1.4rem'
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <TextIconBtn
                Icon={<LeftOutlined />}
                buttonLabel='Back'
                onClick={goToDashboard}
                fontSize='1.4rem'
              />
            </Col>
          </Row>
        )}
      </Footer>
    </Layout>
  );
};

export default SelectTest;
