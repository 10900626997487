const WORKSPACE_FIELDS = {
  ID: '_id',
  TITLE: 'title',
  CHUNK_ID: 'chunkId',
  USER_ID: 'userId',
  TASKS: 'tasks',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
};

const TASK_FIELDS = {
  ID: '_id',
  CHUNK_ID: 'chunkId',
  STATUS: 'status',
};

export { WORKSPACE_FIELDS, TASK_FIELDS };
