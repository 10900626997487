import axios from 'axios';
import Qs from 'qs';
import ENDPOINTS from '../constants/endpoints';
import notification from '../services/notification';
import store from '../stores/index';
import { logout } from '../stores/actions/account.action';
import { ROUTES } from '@client/constants/constants';
const API_BASE_URL = '/api';

/**
 * Format api request params to 'a=b&a=c'
 * @param {Object} params
 */
const requestParamsFormatter = (params) => {
  Qs.stringify(params, { arrayFormat: 'repeat' });
};

const API = axios.create({ baseURL: API_BASE_URL, requestParamsFormatter });

const unauthorizedInterceptor = (instance) =>
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error?.config;
      const errorCode = error?.response?.status;
      const hasRetryRequest = originalRequest.__isRetryRequest;
      if (errorCode === 401 && originalRequest && !hasRetryRequest) {
        originalRequest.__isRetryRequest = true;
        if (originalRequest.url === ENDPOINTS.LOG_IN) {
          return Promise.reject(error);
        }
        try {
          const axiosNoInterceptor = axios.create({ baseURL: API_BASE_URL });
          await axiosNoInterceptor.get(ENDPOINTS.CURRENT_USER);
          return axios(originalRequest);
        } catch (e) {
          if (error?.response?.status === 401 && window.location.pathname !== ROUTES.LANDING) {
            notification.error({
              message:
                'The session has expired. You will be redirected to the login page in 3 seconds.',
              onCloseFunc: () => store.dispatch(logout()),
            });
          }
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );

unauthorizedInterceptor(API);
export default API;
