import styled from 'styled-components';
import { SECOND_COLOR } from '../assets/styles/global';
import React from 'react';
import { NoDeLink } from './Link.style.js';
import LogoWithName from './svg/LogoWithName';

export const SmallNameLink = ({ destination }) => {
  return <NoDeLink to={destination}>tune.it</NoDeLink>;
};

export const StyledLogoName = styled(LogoWithName)`
  fill: ${SECOND_COLOR};
  height: 45vmin;
  min-height: 200px;
  pointer-events: none;
`;
