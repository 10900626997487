import Svg from '../Svg';
import React from 'react';

const Underline = ({ className }) => (
  <Svg width='3em' height='4' viewBox='0 0 55 4' className={className}>
    <line
      id='Line_8'
      data-name='Line 8'
      x2='51'
      transform='translate(2 2)'
      fill='#currentColor'
      stroke='#707070'
      strokeLinecap='round'
      strokeWidth='4'
    />
  </Svg>
);

export default Underline;
