/**
 * Define a account reducer.
 * The reducer takes an incoming action object { type: 'name of type', data: someData}. Update the record in
 * the redux store based on the type of the action.
 */
import ACTIONS from '../actionsType';
import { REDUCER_STATES } from '../../constants/reducerStates';

const initialState = {
  [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESETTING_PASSWORD]: false,
  [REDUCER_STATES.ACCOUNT.FIELDS.IS_SENDING_CONFIRMATION_EMAIL]: false,
  [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGING_IN]: false,
  [REDUCER_STATES.ACCOUNT.FIELDS.IS_REGISTERING]: false,
  [REDUCER_STATES.ACCOUNT.FIELDS.IS_UPDATING_PROFILE]: false,
  [REDUCER_STATES.ACCOUNT.FIELDS.USER]: null,
  [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN]: false,
  [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
};
export const account = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SESSION_STATUS: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_GETTING_SESSION_STATUS]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.GET_SESSION_STATUS_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_GETTING_SESSION_STATUS]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.USER]: action.payload,
      };
    }
    case ACTIONS.GET_SESSION_STATUS_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.USER]: null,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_GETTING_SESSION_STATUS]: false,
      };
    }
    case ACTIONS.LOG_IN: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGING_IN]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.LOG_IN_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.USER]: action.payload,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGING_IN]: false,
      };
    }
    case ACTIONS.LOG_IN_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGING_IN]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.REGISTER: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_REGISTERING]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.REGISTER_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.USER]: action.payload,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_REGISTERING]: false,
      };
    }
    case ACTIONS.REGISTER_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_REGISTERING]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.LOG_OUT_FULFILLED: {
      return {
        ...initialState,
      };
    }
    case ACTIONS.REDIRECT_TO_LOGIN: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.USER]: null,
      };
    }
    case ACTIONS.RESEND_EMAIL_VERIFICATION: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_SENDING_CONFIRMATION_EMAIL]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.RESEND_EMAIL_VERIFICATION_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_SENDING_CONFIRMATION_EMAIL]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.RESEND_EMAIL_VERIFICATION_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_SENDING_CONFIRMATION_EMAIL]: false,
        error: action.payload,
      };
    }
    case ACTIONS.RESET_PASSWORD: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESETTING_PASSWORD]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.RESET_PASSWORD_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESETTING_PASSWORD]: false,
      };
    }
    case ACTIONS.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESETTING_PASSWORD]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.RESEND_RESET_PASSWORD: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESENDING_RESET_PASSWORD]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.RESEND_RESET_PASSWORD_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESENDING_RESET_PASSWORD]: false,
      };
    }
    case ACTIONS.RESEND_RESET_PASSWORD_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_RESENDING_RESET_PASSWORD]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.UPDATE_PROFILE: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_UPDATING_PROFILE]: true,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: null,
      };
    }
    case ACTIONS.UPDATE_PROFILE_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_UPDATING_PROFILE]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.USER]: action.payload,
      };
    }
    case ACTIONS.UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.ACCOUNT.FIELDS.IS_UPDATING_PROFILE]: false,
        [REDUCER_STATES.ACCOUNT.FIELDS.ERROR]: action.payload,
      };
    }
    default:
      return state;
  }
};
