import { REDUCER_STATES } from '@client/constants/reducerStates';
import ACTION from '../actionsType';
import { findIndex, get } from 'lodash';
import { RESPONSE_FIELDS } from '@client/constants/api/response';

const initialState = {
  [REDUCER_STATES.RESPONSE.FIELDS.IS_CREATING_RESPONSE]: false,
  [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: false,
  [REDUCER_STATES.RESPONSE.FIELDS.IS_UPDATING_RESPONSE]: false,
  [REDUCER_STATES.RESPONSE.FIELDS.IS_DELETING_RESPONSE]: false,
  [REDUCER_STATES.RESPONSE.FIELDS.RESPONSES]: null,
  [REDUCER_STATES.RESPONSE.FIELDS.CURRENT_RESPONSE]: null,
  [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: null,
};

export const response = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_RESPONSES: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: true,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: null,
      };
    }
    case ACTION.GET_RESPONSES_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.RESPONSES]: action.payload,
      };
    }
    case ACTION.GET_RESPONSES_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTION.GET_RESPONSE: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: true,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: null,
      };
    }
    case ACTION.GET_RESPONSE_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.CURRENT_RESPONSE]: action.payload,
      };
    }
    case ACTION.GET_RESPONSE_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_GETTING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTION.CREATE_RESPONSE: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_CREATING_RESPONSE]: true,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: null,
      };
    }
    case ACTION.CREATE_RESPONSE_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_CREATING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.CURRENT_RESPONSE]: action.payload,
      };
    }
    case ACTION.CREATE_RESPONSE_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_CREATING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTION.UPDATE_RESPONSE_ANSWER: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_UPDATING_RESPONSE]: true,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: null,
      };
    }
    case ACTION.UPDATE_RESPONSE_ANSWER_FULFILLED: {
      const currentResponses = state[REDUCER_STATES.RESPONSE.FIELDS.RESPONSES];
      let newResponses = currentResponses;
      const index = findIndex(currentResponses, {
        [RESPONSE_FIELDS.ID]: get(action.payload, RESPONSE_FIELDS.ID, null),
      });
      if (index !== -1) {
        newResponses = [
          ...currentResponses.slice(0, index),
          action.payload,
          ...currentResponses.slice(index + 1),
        ];
      }
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_UPDATING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.RESPONSES]: newResponses,
        [REDUCER_STATES.RESPONSE.FIELDS.CURRENT_RESPONSE]: action.payload,
      };
    }
    case ACTION.UPDATE_RESPONSE_ANSWER_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_UPDATING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: action.payload,
      };
    }

    case ACTION.DELETE_RESPONSE: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_DELETING_RESPONSE]: true,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: null,
      };
    }
    case ACTION.DELETE_RESPONSE_FULFILLED: {
      const currentResponses = state[REDUCER_STATES.RESPONSE.FIELDS.RESPONSES];
      let newResponses = currentResponses;
      const index = findIndex(currentResponses, {
        [RESPONSE_FIELDS.ID]: get(action.payload, RESPONSE_FIELDS.ID, null),
      });
      if (index !== -1) {
        newResponses = [...currentResponses.slice(0, index), ...currentResponses.slice(index + 1)];
      }
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_DELETING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.RESPONSES]: newResponses,
        [REDUCER_STATES.RESPONSE.FIELDS.CURRENT_RESPONSE]: null,
      };
    }
    case ACTION.DELETE_RESPONSE_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.RESPONSE.FIELDS.IS_DELETING_RESPONSE]: false,
        [REDUCER_STATES.RESPONSE.FIELDS.ERROR]: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
