import ACTIONS from '../actionsType';
import {
  handleCreateTrack,
  handleDeleteTrack,
  handleDownloadTrack,
  handleGetRandomTracks,
  handleGetTrack,
  handleGetTracks,
  handleUpdateTrack,
} from '@client/apiServices';
import { TRACK_FIELDS } from '@client/constants/api';

/**
 * Create track
 * @param {Array} file
 * @param {string} label
 * @param {Array} tags
 * @return {(function(*): void)|*}
 */
const createTrack =
  ({ file, label, tags }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.CREATE_TRACK,
    });
    handleCreateTrack({ file, label, tags })
      .then((res) => {
        dispatch({
          type: ACTIONS.CREATE_TRACK_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.CREATE_TRACK_ERROR,
          payload: err,
        });
      });
  };

/**
 * Get random <trackNum> of tracks with <trackTags> as tags
 * @param {number} trackNum
 * @param {Array.<string>} trackTags
 * @return {function(*): void}
 */
const getRandomTrack = (trackNum, trackTags) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_RANDOM_TRACKS,
  });
  handleGetRandomTracks(trackNum, trackTags)
    .then((tracks) => {
      dispatch({
        type: ACTIONS.GET_RANDOM_TRACKS_FULFILLED,
        payload: tracks,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_RANDOM_TRACKS_ERROR,
        payload: err,
      });
    });
};

/**
 * Download track files of given tracks
 * @param {Array} tracks
 * @return {function(*): void}
 */
const downloadTracks = (tracks) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.DOWNLOAD_TRACK });
    Promise.all(
      tracks.map(
        (track) =>
          new Promise((resolve, reject) => {
            const trackId = track?.[TRACK_FIELDS.ID];
            handleDownloadTrack(trackId)
              .then((data) => {
                resolve({
                  [TRACK_FIELDS.ID]: trackId,
                  [TRACK_FIELDS.FILE]: URL.createObjectURL(data),
                });
              })
              .catch((err) => reject(err));
          })
      )
    )
      .then((values) => resolve(values))
      .catch((error) => reject(error));
  });
};

/**
 * Get tracks
 * @return {(function(*): void)|*}
 */
const getTracks = () => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_TRACKS,
  });
  handleGetTracks()
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_TRACKS_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_TRACKS_ERROR,
        payload: err,
      });
    });
};

/**
 * Get one track
 * @param {string} trackId
 * @return {(function(*): void)|*}
 */
const getTrack = (trackId) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_TRACK,
  });
  handleGetTrack(trackId)
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_TRACK_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_TRACK_ERROR,
        payload: err,
      });
    });
};

/**
 * Update one track
 * @param {string} responseId
 * @param {string} label
 * @param {Array} tags
 * @return {(function(*): void)|*}
 */
const updateTrack =
  ({ trackId, label, tags }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.UPDATE_TRACK,
    });
    handleUpdateTrack({ trackId, label, tags })
      .then((res) => {
        dispatch({
          type: ACTIONS.UPDATE_TRACK_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.UPDATE_TRACK_ERROR,
          payload: err,
        });
      });
  };

/**
 * Download one track
 * @param {string} trackId
 * @return {(function(*): void)|*}
 */
const downloadTrack = (trackId) => (dispatch) => {
  dispatch({
    type: ACTIONS.DOWNLOAD_TRACK,
  });
  handleDownloadTrack(trackId)
    .then(() => {
      dispatch({
        type: ACTIONS.DOWNLOAD_TRACK_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.DOWNLOAD_TRACK_ERROR,
        payload: err,
      });
    });
};

/**
 * Delete one track
 * @param {string} trackId
 * @return {(function(*): void)|*}
 */
const deleteTrack = (trackId) => (dispatch) => {
  dispatch({
    type: ACTIONS.DELETE_TRACK,
  });
  handleDeleteTrack(trackId)
    .then(() => {
      dispatch({
        type: ACTIONS.DELETE_TRACK_FULFILLED,
        payload: trackId,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.DELETE_TRACK_ERROR,
        payload: err,
      });
    });
};

export {
  createTrack,
  getRandomTrack,
  downloadTracks,
  getTracks,
  getTrack,
  updateTrack,
  downloadTrack,
  deleteTrack,
};
