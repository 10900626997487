import Qs from 'qs';

/**
 * Extract query params from url
 * @example "?a=1&&b=2" to {a:1,b:2}
 * @param {String} queryString
 * @return {any}
 */
const parseQueryString = (queryString) => {
  return Qs.parse(queryString, { ignoreQueryPrefix: true });
};
//
// /**
//  * Get environment variable
//  * @param {string} name - variable name
//  * @return {string}
//  */
// const getEnvVar = (name) => {
//   // eslint-disable-next-line
//   return ENV[name];
// };

/**
 * Generate link
 * @param {string} route - path
 * @return {string}
 */
const makeUrl = (route) => {
  return window.location.origin + route;
};

/**
 * Generate string contains variable
 * @param {string} strings
 * @param {Array} keys
 * @return {function(*=): string}
 */
const ttl = (strings, ...keys) => {
  return function (collection) {
    const result = [strings[0]];
    collection = collection || {};
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1]);
    });
    return result.join('');
  };
};

export { parseQueryString, makeUrl, ttl };
