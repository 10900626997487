import React from 'react';
import Svg from '../Svg';

const SpeakerSvg = ({ className }) => (
  <Svg width='348' height='270.643' viewBox='0 0 348 270.643' className={className}>
    <g id='undraw_listening_1u79' transform='translate(0 0)'>
      <path
        id='Path_35'
        data-name='Path 35'
        d='M999.136,671.566l9.033-13.091-9.031,14.51v1.485q-.972,0-1.924-.055l.669-19.083-.01-.147.017-.028.064-1.8L988.4,639.127,997.957,652l.03.382.505-14.418-8.219-14.729,8.273,12.2.271-29.88v-.1l0,.1.275,23.555,7.765-9.474-7.764,11.5.012,12.9,7.191-12.506-7.188,14.4.007,7.174,10.448-17.415-10.447,19.918Z'
        transform='translate(-662.191 -409.212)'
        fill='#d0cde1'
      />
      <path
        id='Path_36'
        data-name='Path 36'
        d='M276.907,471.313l26.024-37.248L276.887,475.37l-.023,4.243q-2.777-.028-5.5-.191l2.23-54.509-.027-.421.048-.08.212-5.151-27.044-40.8,27.08,36.947.079,1.091,1.685-41.184L252.4,333.093l23.432,34.982,1.272-85.361.006-.284,0,.28.392,67.3,22.342-26.936-22.374,32.733-.18,36.861L298.04,357.06l-20.775,41.028-.1,20.5L307.305,369l-30.179,56.73Z'
        transform='translate(-211.208 -212.778)'
        fill='#575a89'
      />
      <ellipse
        id='Ellipse_17'
        data-name='Ellipse 17'
        cx='174'
        cy='6.012'
        rx='174'
        ry='6.012'
        transform='translate(0 258.618)'
        fill='#d0cde1'
      />
      <path
        id='Path_37'
        data-name='Path 37'
        d='M817.492,736.511a15.054,15.054,0,0,0-1.3-6.246,14.96,14.96,0,0,0-4.43,5.027,14.96,14.96,0,0,0-4.431-5.027,15.054,15.054,0,0,0-1.3,6.246A15.071,15.071,0,0,0,800,734.655a12.088,12.088,0,0,0,11.586,11.431l.025.017.007-.014c.09.008.139.011.139.011s-.008-.092-.029-.258l.029-.068.029.068c-.021.166-.029.258-.029.258s.049,0,.139-.011l.007.014.025-.017a12.089,12.089,0,0,0,11.586-11.431A15.071,15.071,0,0,0,817.492,736.511Z'
        transform='translate(-547.622 -485.11)'
        fill='#f15290'
      />
      <path
        id='Path_38'
        data-name='Path 38'
        d='M820.966,135.931l.019,0-2.74-16.019-15.152,4.814,2.267,14.328a3.894,3.894,0,1,0,1.564,1.923l.014,0L804.5,125.594l12.742-4.048,2.166,12.663a3.885,3.885,0,1,0,1.552,1.722Z'
        transform='translate(-547.248 -113.947)'
        fill='#f15290'
      />
      <path
        id='Path_39'
        data-name='Path 39'
        d='M1006.064,151.573l.019,0-2.74-16.019-15.152,4.814,2.267,14.328a3.893,3.893,0,1,0,1.564,1.923l.014,0L989.6,141.236l12.742-4.048,2.166,12.663a3.885,3.885,0,1,0,1.552,1.722Z'
        transform='translate(-659.807 -123.459)'
        fill='#f15290'
      />
      <path
        id='Path_40'
        data-name='Path 40'
        d='M898.183,186.619l.011,0-1.952-12.337-.964.152,1.692,10.7a3.019,3.019,0,1,0,1.213,1.491Z'
        transform='translate(-603.773 -147.012)'
        fill='#f15290'
      />
      <path
        id='Path_41'
        data-name='Path 41'
        d='M940.16,161.994a3.013,3.013,0,1,0-1.406,2.541l-1.692,10.7.964.153,1.952-12.337-.011,0a2.994,2.994,0,0,0,.193-1.051Z'
        transform='translate(-629.189 -137.703)'
        fill='#f15290'
      />
      <path
        id='Path_42'
        data-name='Path 42'
        d='M473.448,546.756l5.712,8.568,8.568-6.664-5.712-7.14Z'
        transform='translate(-287.908 -329.303)'
        fill='#ffb8b8'
      />
      <path
        id='Path_43'
        data-name='Path 43'
        d='M637.088,659.385s-8.18.38-5.875,4.7,4.1,28.724,10.686,27.311,12.549-8.892,12.476-14.04-.9-7.381-.9-7.381-1.765-3.408-.59-4.349,1.619-6.889-1.18-8.7-10.886-4.2-10.886-4.2S640,660.135,637.088,659.385Z'
        transform='translate(-444.732 -437.961)'
        fill='#2f2e41'
      />
      <path
        id='Path_44'
        data-name='Path 44'
        d='M498.429,605.9l-1.9,10.472,10,.952v-8.092Z'
        transform='translate(-301.941 -368.45)'
        fill='#ffb8b8'
      />
      <path
        id='Path_45'
        data-name='Path 45'
        d='M557.629,344.208s-5.236,17.612-6.664,28.084c0,0-28.56,38.556-23.324,55.215s20.468,49.979,20.468,49.979,25.7-10.472,30.94-16.184l-19.04-27.132s2.856-3.332-1.428-5.236-.476-4.284-.476-4.284,8.092-27.132,16.184-29.988l28.084-5.712s-1.9-40.459-8.568-47.6C593.8,341.352,560.485,341.828,557.629,344.208Z'
        transform='translate(-381.608 -248.609)'
        fill='#2f2e41'
      />
      <path
        id='Path_46'
        data-name='Path 46'
        d='M624.632,720.46s-4.76-6.664-7.14-2.38-21.9,19.04-17.136,23.8,14.28,5.712,18.564,2.856,5.712-4.76,5.712-4.76,1.9-3.332,3.332-2.856,6.664-2.38,6.664-5.712-2.38-11.424-2.38-11.424S625.584,723.316,624.632,720.46Z'
        transform='translate(-425.764 -476.827)'
        fill='#2f2e41'
      />
      <path
        id='Path_47'
        data-name='Path 47'
        d='M645.238,448.944l6.664,57.6s-3.808,16.184-4.76,28.56,0,11.9,0,11.9,21.42,8.568,25.228,2.856,1.9-103.291,1.9-103.291L662.85,436.092Z'
        transform='translate(-453.51 -306.221)'
        fill='#2f2e41'
      />
      <path
        id='Path_48'
        data-name='Path 48'
        d='M612.566,118.841c4.941-11.659,17.52-13.861,17.52-13.861s12.112-3.231,22.226,12.157c9.428,14.343,21.586,27.781,6.167,33.506l-4.073-8.553-.489,9.844a62.769,62.769,0,0,1-6.788,1.03c-17.1,1.737-33.223,4.626-33.522-1.554C613.212,143.192,607.811,130.06,612.566,118.841Z'
        transform='translate(-432.458 -104.697)'
        fill='#2f2e41'
      />
      <circle
        id='Ellipse_18'
        data-name='Ellipse 18'
        cx='13.804'
        cy='13.804'
        r='13.804'
        transform='translate(186.493 9.444)'
        fill='#ffb8b8'
      />
      <path
        id='Path_49'
        data-name='Path 49'
        d='M654.106,185.013s-.238,14.042-1.666,17.374,25.466-2.618,25.466-2.618-9.52-6.664-10.948-15.708Z'
        transform='translate(-457.856 -152.959)'
        fill='#ffb8b8'
      />
      <path
        id='Path_50'
        data-name='Path 50'
        d='M617.27,213.281l-2.6-1.912-18.344.96-3.808,41.887s-10.948,12.852,2.38,12.852,25.7-6.188,35.224-1.9,17.612-45.219,17.612-45.219L633.5,211.369S627.266,218.993,617.27,213.281Z'
        transform='translate(-418.878 -169.565)'
        fill='#575a89'
      />
      <path
        id='Path_51'
        data-name='Path 51'
        d='M620.191,302.485l26.656,5.712v6.188l-26.656.952S617.335,304.389,620.191,302.485Z'
        transform='translate(-437.506 -224.974)'
        fill='#ffb8b8'
      />
      <path
        id='Path_52'
        data-name='Path 52'
        d='M595.758,216.2l-5.236-2.38s-6.188,1.428-6.664,6.664-9.52,39.031-2.38,40.459a102.767,102.767,0,0,0,16.66,1.428s-6.188-8.568,1.428-13.328l-5.712-2.856s-5.712-12.852-1.9-19.516S595.758,216.2,595.758,216.2Z'
        transform='translate(-413.074 -171.055)'
        fill='#575a89'
      />
      <path
        id='Path_53'
        data-name='Path 53'
        d='M465.784,15.036l12.606-6.6,17.409,2.7,3.6,15.908-8.966-.345-2.5-5.844V26.6l-4.137-.159-2.4-9.3-1.5,9.905-14.707-.3Z'
        transform='translate(-282.882 -5.128)'
        fill='#2f2e41'
      />
      <ellipse
        id='Ellipse_19'
        data-name='Ellipse 19'
        cx='2.539'
        cy='4.443'
        rx='2.539'
        ry='4.443'
        transform='translate(211.641 17.459)'
        fill='#ffb8b8'
      />
      <circle
        id='Ellipse_20'
        data-name='Ellipse 20'
        cx='1.733'
        cy='1.733'
        r='1.733'
        transform='translate(212.691 21.582)'
        fill='#fff'
      />
      <ellipse
        id='Ellipse_21'
        data-name='Ellipse 21'
        cx='0.282'
        cy='0.845'
        rx='0.282'
        ry='0.845'
        transform='translate(214.885 22.209)'
        fill='#2f2e41'
      />
      <path
        id='Path_54'
        data-name='Path 54'
        d='M703.557,170.832h0a.443.443,0,0,1-.443-.443l-.443-3.546H704v3.546A.443.443,0,0,1,703.557,170.832Z'
        transform='translate(-488.435 -142.489)'
        fill='#fff'
      />
      <path
        id='Path_55'
        data-name='Path 55'
        d='M453.749,148.149l-4.495,19.477h10.488l-8.24-1.873Z'
        transform='translate(-273.195 -90.091)'
        opacity='0.1'
      />
      <path
        id='Path_56'
        data-name='Path 56'
        d='M722.886,316.975l-.1-.09,2.46-2.73a2.127,2.127,0,0,0-.156-3l-5.783-5.212a2.127,2.127,0,0,0-3,.156l-13.5,14.976a2.127,2.127,0,0,0,.156,3l5.784,5.213a2.127,2.127,0,0,0,3-.156l9.286-10.3.1.09Z'
        transform='translate(-488.185 -226.742)'
        fill='#3f3d56'
      />
      <circle
        id='Ellipse_22'
        data-name='Ellipse 22'
        cx='1.371'
        cy='1.371'
        r='1.371'
        transform='translate(228.794 84.806)'
        fill='#f15290'
      />
      <path
        id='Path_57'
        data-name='Path 57'
        d='M709.736,322.487s16.226,3.927,12.488,9.527-18.5-4.635-18.5-4.635Z'
        transform='translate(-489.076 -237.137)'
        fill='#ffb8b8'
      />
      <path
        id='Path_58'
        data-name='Path 58'
        d='M696.628,233.252h4.284s4.76,5.236,3.808,10-3.808,27.132-3.808,27.132-14.28,13.328-21.42,6.188-1.9-13.8-1.9-13.8,9.52,7.616,14.756-2.38Z'
        transform='translate(-472.057 -182.873)'
        fill='#575a89'
      />
      <path
        id='Path_59'
        data-name='Path 59'
        d='M694.233,233.279l3.279-32.391s6.877-20.448.164-20.95-7.184,19.377-7.184,19.377L678.8,234.759S685.216,241.044,694.233,233.279Z'
        transform='translate(-473.919 -150.447)'
        fill='#ffb8b8'
      />
    </g>
  </Svg>
);
export default SpeakerSvg;
