import styled from 'styled-components';
import Quiet from './svg/Quiet';
import Internet from './svg/Internet';
import Speaker from './svg/Speaker';
import Ready from './svg/Ready';

const QuietImage = styled(Quiet)`
  max-width: 100%;
  max-height: 100%;
`;
const InternetImage = styled(Internet)`
  max-width: 100%;
  max-height: 100%;
`;
const SpeakerImage = styled(Speaker)`
  max-width: 100%;
  max-height: 100%;
`;
const ReadyImage = styled(Ready)`
  max-width: 100%;
  max-height: 100%;
`;
const StyledImages = {
  QuietImage,
  InternetImage,
  SpeakerImage,
  ReadyImage,
};
export default StyledImages;
