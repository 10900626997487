import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { Form, Input } from 'antd';
import { EMAIL_FORMAT_RULE } from '@client/constants/formValidation';
import GradientBtnWithSpin from '@client/components/GradientBtnWithSpin';
import { useDispatch, useSelector } from 'react-redux';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { resendResetPassword } from '@client/stores/actions/account.action';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@client/constants/constants';
import { makeUrl } from '@client/services/libs';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isSendingResetPasswordEmail = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_RESENDING_RESET_PASSWORD],
      false
    )
  );
  const error = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.ERROR], false)
  );

  const previousIsSendingResetPasswordEmail = useRef(isSendingResetPasswordEmail);

  /**
   * Send reset password link
   * @param {Object} values - {email:xxx}
   */
  const onFinish = (values) => {
    dispatch(
      resendResetPassword({
        email: _.get(values, ACCOUNT_FIELDS.EMAIL, null),
        destUrl: makeUrl(ROUTES.RESET_PASSWORD),
      })
    );
  };

  /**
   * Redirect to email
   */
  useEffect(() => {
    if (previousIsSendingResetPasswordEmail.current && !isSendingResetPasswordEmail && !error) {
      history.push(ROUTES.FORGOT_PASSWORD_EMAIL_SENT);
    }
    previousIsSendingResetPasswordEmail.current = isSendingResetPasswordEmail;
  }, [isSendingResetPasswordEmail, error]);

  return (
    <>
      <h1>Forgot password</h1>
      <Form onFinish={onFinish} layout='vertical'>
        <Form.Item
          name={ACCOUNT_FIELDS.EMAIL}
          label={'Please confirm your email address:'}
          rules={EMAIL_FORMAT_RULE}
        >
          <Input type={'email'} />
        </Form.Item>
        <GradientBtnWithSpin
          btnLabel={'Confirm'}
          isLoading={isSendingResetPasswordEmail}
          styles={{ marginTop: '2rem' }}
          htmlType={'submit'}
        />
      </Form>
    </>
  );
};

export default ForgotPasswordPage;
