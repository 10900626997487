import { Col, Row } from 'antd';
import React from 'react';
import { PageHeadingText, PageIntroText } from '../components/Text.style';

/**
 * Page Inner Template for pages with heading and intro text.
 * @param {string| JSX.Element} HeadingText
 * @param {string| JSX.Element} IntroText
 * @param {JSX.Element} Content
 * @param {boolean} Spinning
 * @param {string} minHeight
 * @return {JSX.Element}
 * @constructor
 */
export const HeadingIntroTemplate = ({ HeadingText, IntroText, marginTop, Content, minHeight }) => {
  return (
    <div
      style={{
        minHeight: minHeight ? minHeight : 'calc(100% - 2rem)',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }}
    >
      <Row style={{ marginTop: marginTop ? marginTop : '0.8em' }}>
        <Col span={24}>
          <PageHeadingText>{HeadingText}</PageHeadingText>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PageIntroText>{IntroText}</PageIntroText>
        </Col>
      </Row>

      {Content}
    </div>
  );
};
