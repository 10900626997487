import API from '@client/axios';
import { errorHandler } from '@client/axios/errorHandler';
import { extractResponse } from '@client/axios/extractResponse';
import ENDPOINTS from '@client/constants/endpoints';

/**
 * Randomly get <trackNum> of tracks with <trackTags> as tags from server
 * @param {integer} trackNum
 * @param {Array.<string>}trackTags
 * @return {Promise<*>}
 */
const handleGetRandomTracks = async (trackNum, trackTags) => {
  try {
    let query = '?sampleSize=' + trackNum;
    trackTags.forEach((tag) => {
      query = query + '&tags=' + tag;
    });
    const res = await API.get(ENDPOINTS.TRACKS_RANDOM + query);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get tracks
 * @return {Promise<*>}
 */
const handleGetTracks = async () => {
  try {
    const res = await API.get(ENDPOINTS.TRACKS);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get one track
 * @param {string} trackId
 * @return {Promise<*>}
 */
const handleGetTrack = async (trackId) => {
  try {
    const res = await API.get(ENDPOINTS.TRACK({ trackId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Create new tracks
 * @param {Array} file
 * @param {string} label
 * @param {Array} tags
 * @return {Promise<*>}
 */
const handleCreateTrack = async ({ file, label, tags }) => {
  try {
    const res = await API.post(ENDPOINTS.TRACKS, { file, label, tags });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Update track
 * @param {string} responseId
 * @param {string} label
 * @param {Array} tags
 * @return {Promise<*>}
 */
const handleUpdateTrack = async ({ trackId, label, tags }) => {
  try {
    const res = await API.put(ENDPOINTS.TRACK({ trackId }), { label, tags });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Download one track and return as a Blob
 * @param {string} trackId
 * @return {Promise<*>}
 */
const handleDownloadTrack = async (trackId) => {
  try {
    const res = await API.get(ENDPOINTS.TRACK_DOWNLOAD({ trackId }), {
      responseType: 'arraybuffer',
    });
    return new Blob([res?.data], {
      type: res.headers['content-type'],
    });
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Delete one track
 * @param {string} trackId
 * @return {Promise<*>}
 */
const handleDeleteTrack = async (trackId) => {
  try {
    const res = await API.delete(ENDPOINTS.TRACK({ trackId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

export {
  handleGetRandomTracks,
  handleGetTracks,
  handleGetTrack,
  handleCreateTrack,
  handleUpdateTrack,
  handleDownloadTrack,
  handleDeleteTrack,
};
