import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import GradientBtnWithSpin from '@client/components/GradientBtnWithSpin';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from '@client/stores/actions/account.action';
import { ROUTES } from '@client/constants/constants';
import { PASSWORD_FORMAT_RULE } from '@client/constants/formValidation';
import { parseQueryString } from '@client/services/libs';

const REDIRECT_TIME = 3000;

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { userId, token } = parseQueryString(location?.search);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const isResettingPassword = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_RESETTING_PASSWORD],
      false
    )
  );
  const error = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.ERROR], null)
  );

  const previousIsResettingPassword = useRef(isResettingPassword);

  /**
   * Confirm reset password
   * @param {Object} values - {password: xxx}
   */
  const onFinish = (values) => {
    dispatch(
      resetPassword({ userId, token, newPassword: _.get(values, ACCOUNT_FIELDS.PASSWORD, null) })
    );
  };

  useEffect(() => {
    if (previousIsResettingPassword.current && !isResettingPassword && !error) {
      setHasSubmitted(true);
      setTimeout(() => {
        history.push(ROUTES.LOGIN);
      }, REDIRECT_TIME);
    }
    previousIsResettingPassword.current = isResettingPassword;
  }, [isResettingPassword, error]);

  return (
    <>
      <h1>Reset password</h1>
      <Form onFinish={onFinish} layout='vertical'>
        <Form.Item name={ACCOUNT_FIELDS.PASSWORD} label={'Password'} rules={PASSWORD_FORMAT_RULE}>
          <Input type={'password'} />
        </Form.Item>
        <Form.Item
          name={'confirmPassword'}
          label={'Confirm password'}
          rules={[
            ...PASSWORD_FORMAT_RULE,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(ACCOUNT_FIELDS.PASSWORD) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
          dependencies={[ACCOUNT_FIELDS.PASSWORD]}
          hasFeedback
        >
          <Input type={'password'} />
        </Form.Item>
        {hasSubmitted && (
          <p>
            Password has been reset, you will be redirected to login page after{' '}
            {REDIRECT_TIME / 1000}s
          </p>
        )}
        <GradientBtnWithSpin
          btnLabel={'Confirm'}
          isLoading={isResettingPassword}
          styles={{ marginTop: '2rem' }}
          htmlType={'submit'}
        />
      </Form>
    </>
  );
};

export default ResetPasswordPage;
