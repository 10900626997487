import { useCallback, useMemo, useRef } from 'react';

const useLongPress = ({
  onClick = () => {},
  onLongPress = () => {},
  onLongPressStop = () => {},
  delay = 300,
}) => {
  const timerRef = useRef(false);
  const eventRef = useRef({});
  const callback = useCallback(() => {
    onLongPress(eventRef.current);
    eventRef.current = {};
    timerRef.current = false;
  }, [onLongPress]);
  const start = useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      timerRef.current = setTimeout(callback, delay);
    },
    [callback, delay]
  );
  const stop = useCallback(
    (ev) => {
      ev.persist();
      onLongPressStop(eventRef.current);
      eventRef.current = ev;
      if (timerRef.current) {
        //is a click not a long press
        clearTimeout(timerRef.current);
        onClick(eventRef.current);
        timerRef.current = false;
        eventRef.current = {};
      }
    },
    [onClick, onLongPressStop]
  );

  return useMemo(
    () => ({
      onMouseDown: start,
      onMouseUp: stop,
      onMouseLeave: stop,
      onTouchStart: start,
      onTouchEnd: stop,
    }),
    [start, stop]
  );
};
export default useLongPress;
