import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '@client/constants/constants';
import Landing from '@client/pages/Landing';
import Login from '@client/pages/Login';
import React from 'react';
import { BigLogoPageTemp } from '@client/templates/PageTemplate';

const BigLogoRoutesComp = () => {
  return (
    <Switch>
      <Route path={ROUTES.LANDING} exact component={Landing} />
      <Route path={ROUTES.LOGIN} exact component={Login} />
    </Switch>
  );
};

const BigLogoLayoutRoutes = () => {
  return BigLogoPageTemp(<BigLogoRoutesComp />);
};

export default BigLogoLayoutRoutes;
