import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  OutlinedBtn,
  PrimaryIconButton,
  TextBtn,
  TextBtnAsListItem,
  TextIconBtn,
} from '../components/Button.style';
import { Carousel, Col, Popover, Row, Spin, Radio } from 'antd';
import {
  PlusOutlined,
  CaretRightFilled,
  ControlOutlined,
  LeftOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Layout, { Content, Footer } from 'antd/es/layout/layout';
import { DATE_TIME_FORMAT, ROUTES, TASKS_STATUS } from '../constants/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import StyledImages from '@client/components/Image';
import GradientBtnWithSpin from '@client/components/GradientBtnWithSpin';
import { HeadingIntroTemplate } from '@client/templates/InnerTemplates';
import {
  ACCOUNT_FIELDS,
  CHUNK_FIELDS,
  TASK_FIELDS,
  USER_PREFERENCES_ENUM,
  WORKSPACE_FIELDS,
} from '@client/constants/api';
import { createWorkspace, getWorkspaces } from '@client/stores/actions/workspace';
import { updateProfile } from '@client/stores/actions/account.action';
import { PreferencesPopoverContainer } from '@client/components/Container.style';
import { getChunkById } from '@client/stores/actions/chunk';
import ACTIONS from '@client/stores/actionsType';
import ButtonWithIcon from '@client/components/ButtonWithIcon';
import { DescripText } from '@client/components/Text.style';
import moment from 'moment';

const hearingModOptions = Object.keys(USER_PREFERENCES_ENUM.HEARING_MOD.ENUM)?.map((each) => ({
  label: USER_PREFERENCES_ENUM.HEARING_MOD.ENUM[each].LABEL,
  value: USER_PREFERENCES_ENUM.HEARING_MOD.ENUM[each].VALUE,
}));

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER], null)
  );
  const workspaces = useSelector((state) =>
    _.get(state, [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.WORKSPACES], null)
  );
  const workspace = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE],
      null
    )
  );
  const isGettingWorkspace = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE],
      false
    )
  );
  const isCreatingWorkspace = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE],
      false
    )
  );
  const wasCreatingWorkspace = useRef(isCreatingWorkspace);
  const WorkspaceError = useSelector((state) =>
    _.get(state, [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.ERROR], null)
  );
  // Redux state chunk
  const chunk = useSelector((state) =>
    _.get(state, [REDUCER_STATES.CHUNK.NAME, REDUCER_STATES.CHUNK.FIELDS.CURRENT_CHUNK], null)
  );
  const isGettingChunk = useSelector((state) =>
    _.get(state, [REDUCER_STATES.CHUNK.NAME, REDUCER_STATES.CHUNK.FIELDS.IS_GETTING_CHUNK], false)
  );
  const wasGettingChunk = useRef(isGettingChunk);
  const chunkError = useSelector((state) =>
    _.get(state, [REDUCER_STATES.CHUNK.NAME, REDUCER_STATES.CHUNK.FIELDS.ERROR], null)
  );
  // Profile
  const isUpdatingProfile = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_UPDATING_PROFILE],
      false
    )
  );
  const wasUpdatingProfile = useRef(isUpdatingProfile);
  const accountError = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.ERROR], null)
  );
  /*
   * Get workspaces on mount and after user preference is updated
   */
  useEffect(() => {
    if (!workspaces) dispatch(getWorkspaces());
  }, []);
  useEffect(() => {
    if (wasUpdatingProfile.current && !isUpdatingProfile && !accountError)
      dispatch(getWorkspaces());
    wasUpdatingProfile.current = isUpdatingProfile;
  }, [wasUpdatingProfile.current, isUpdatingProfile, accountError]);

  /*
   * clear workspaces and chunk on will unmount
   */
  useEffect(() => {
    return () => {
      dispatch({ type: ACTIONS.RESET_WORKSPACE_STATE });
      dispatch({ type: ACTIONS.RESET_CHUNK_STATE });
    };
  }, []);
  /*
   * workspaces workspaceCheck
   */
  const [workspaceCheck, setWorkspaceCheck] = useState({
    workspacesCheckComplete: false,
    workspacesToCheck: [],
    emptyWorkspaces: [],
    incompleteWorkspaces: [],
  });
  /*
   * Update workspaces state
   * Check if there's incomplete workspace to resume and if there's empty workspace to use as new test
   */
  useEffect(() => {
    if (workspaces && workspaces?.length > 0) {
      const emptyWSs = [];
      const tasksCompleteWSs = [];
      const incompleteWSs = [];
      workspaces.forEach((ws) => {
        const tasks = ws[WORKSPACE_FIELDS.TASKS];
        if (!tasks || tasks?.length === 0) {
          //current ws has no task
          emptyWSs.push(ws);
        } else {
          //current ws has task, check if all tasks are complete
          let allTasksComplete = true;
          tasks.forEach((task) => {
            if (task[TASK_FIELDS.STATUS] !== TASKS_STATUS.COMPLETED) allTasksComplete = false;
          });
          if (allTasksComplete) tasksCompleteWSs.push(ws);
          //all tasks of this current ws are complete, but task number need to be checked
          else incompleteWSs.push(ws);
        }
      });
      setWorkspaceCheck((prevState) => {
        return {
          ...prevState,
          workspacesCheckComplete: tasksCompleteWSs.length === 0, // Check completes when there's no workspaceToCheck
          workspacesToCheck: tasksCompleteWSs,
          incompleteWorkspaces: incompleteWSs,
          emptyWorkspaces: emptyWSs,
        };
      });
    }
  }, [workspaces, setWorkspaceCheck]);

  /*
   * For workspaces which have all tasks complete, we need to check if the # of tasks === # of question chunks
   * We start from with the first workspace in the list, get the root chunk of this workspace from the server.
   */
  useEffect(() => {
    if (workspaceCheck.workspacesToCheck && workspaceCheck.workspacesToCheck.length > 0) {
      dispatch(getChunkById(workspaceCheck.workspacesToCheck[0][WORKSPACE_FIELDS.CHUNK_ID]));
    }
  }, [workspaceCheck.workspacesToCheck]);

  /*
   * Got the rootchunk from the server, we then compare the number of question chunks (children) with the number of tasks of the according workspace.
   * The workspace is complete if the numbers are the same. We remove the workspace from the check list.
   * We will remove the workspaces from checklist one by one until the list is empty.
   * If workspace is not complete, we move it from check list to "incompleteWorkspaces"
   */
  useEffect(() => {
    const incomplete = [...workspaceCheck.incompleteWorkspaces];
    if (wasGettingChunk.current && !isGettingChunk && !chunkError) {
      if (
        workspaceCheck.workspacesToCheck[0]?.[WORKSPACE_FIELDS.TASKS]?.length !==
        chunk[CHUNK_FIELDS.CHILDREN]?.length
      ) {
        // ws is not complete, move it to incompleteWorkspaces
        incomplete.push(workspaceCheck.workspacesToCheck[0]);
      }
      //  remove ws from workspacesToCheck, and if no more workspaces to check, then check is complete
      const newWsToCheck = [...workspaceCheck.workspacesToCheck];
      newWsToCheck.splice(0, 1);
      setWorkspaceCheck((prevState) => {
        return {
          ...prevState,
          workspacesToCheck: newWsToCheck,
          workspacesCheckComplete: newWsToCheck.length === 0,
        };
      });
    }
    wasGettingChunk.current = isGettingChunk;
  }, [wasGettingChunk.current, isGettingChunk, chunkError, setWorkspaceCheck]);

  /**
   * Handle change preferences
   * @param {Event} e
   */
  const handleChangePreferences = (e) => {
    const updatedValue = {
      [ACCOUNT_FIELDS.PREFERENCES]: {
        [USER_PREFERENCES_ENUM.HEARING_MOD.NAME]: e.target.value,
      },
    };
    dispatch(updateProfile(updatedValue));
  };

  /**
   * Render preference popover
   * @return {JSX.Element}
   * @constructor
   */
  const RenderPreferencePopover = () => {
    return (
      <PreferencesPopoverContainer>
        <h3 style={{ textTransform: 'capitalize' }}>Simulated hearing loss</h3>
        <Radio.Group
          onChange={handleChangePreferences}
          options={hearingModOptions}
          value={
            currentUser?.[ACCOUNT_FIELDS.PREFERENCES]?.[USER_PREFERENCES_ENUM.HEARING_MOD.NAME]
          }
        />
      </PreferencesPopoverContainer>
    );
  };

  /*
   *  Dashboard Content
   */
  const DashboardContent = () => (
    <React.Fragment>
      <Content>
        <Spin
          size={'large'}
          spinning={!workspaceCheck.workspacesCheckComplete || isGettingWorkspace}
        >
          <Row>
            <Col span={24}>
              {/*New Button*/}
              <ButtonWithIcon
                icon={<PlusOutlined />}
                buttonLabel='New test'
                onClick={useCallback(() => {
                  setState((prevState) => {
                    return { ...prevState, showIntro: true };
                  });
                }, [setState])}
              />
            </Col>
            <Col span={24}>
              {/*Resume button*/}
              <ButtonWithIcon
                icon={<CaretRightFilled />}
                buttonLabel='Resume test'
                onClick={useCallback(() => {
                  setState((prevState) => {
                    return { ...prevState, showResume: true };
                  });
                }, [setState])}
                disabled={workspaceCheck.incompleteWorkspaces?.length === 0}
              />
            </Col>
          </Row>
        </Spin>
      </Content>
      <Footer style={{ background: 'inherit', padding: '0px' }}>
        <Row align='middle'>
          <Col>
            <Popover placement={'top'} trigger='click' content={RenderPreferencePopover()}>
              <ButtonWithIcon
                icon={<ControlOutlined />}
                buttonLabel='Preferences'
                fontSize='1.4rem'
              />
            </Popover>
          </Col>
        </Row>
      </Footer>
    </React.Fragment>
  );

  /*
   * Intro Carousel Content
   */

  // States for showing the intro carousel
  const [slideIndex, setSlideIndex] = useState(0);

  const CarouselRef = useRef();

  // Intro Carousel go to slide
  useEffect(() => {
    if (CarouselRef.current) CarouselRef.current?.goTo(slideIndex);
  }, []);

  const INTRO_PAGES = {
    QUIET: 'QUIET',
    INTERNET: 'INTERNET',
    SPEAKER: 'SPEAKER',
    READY: 'READY',
  };
  const HeadingText = {
    QUIET: 'Quiet environment',
    INTERNET: 'Internet connection',
    SPEAKER: 'Speakers',
    READY: 'Ready to begin?',
  };
  const IntroText = {
    QUIET: (
      <React.Fragment>
        <p>We will begin the test by setting up your microphone and speaker.</p>
        <p>Please make sure that you are in a quiet environment before you begin</p>
      </React.Fragment>
    ),
    INTERNET: <p>Make sure that you will have internet access throughout the test.</p>,
    SPEAKER: (
      <p>
        Make sure that your device is not in silent mode and that you are wearing speakers or that
        the speakers in your device are set to a normal conversational listening volume.
      </p>
    ),
    READY: (
      <React.Fragment>
        <p>This test should take approximately 10 minutes.</p>
        <p>
          You can pause at any time and resume at a later date within one week of starting the test.
        </p>
      </React.Fragment>
    ),
  };

  const IntroImage = {
    QUIET: <StyledImages.QuietImage />,
    INTERNET: <StyledImages.InternetImage />,
    SPEAKER: <StyledImages.SpeakerImage />,
    READY: <StyledImages.ReadyImage />,
  };

  const CarouselArr = Object.values(INTRO_PAGES);
  const CarouselOnChange = (index) => {
    setSlideIndex(index);
  };

  /*
   * Redirect to Test page with the given workspace
   */
  const RedirectToTest = (workspace) => {
    history.push({
      pathname: ROUTES.TEST,
      search: `?workspaceId=${workspace[WORKSPACE_FIELDS.ID]}`,
    });
  };

  /*
   * If no empty workspace, "start test" button will create a new workspace
   * Then we'll use the newly created workspace to start the new test.
   */
  const [newWorkspace, setNewWorkspace] = useState(null);
  useEffect(() => {
    if (wasCreatingWorkspace.current && !isCreatingWorkspace && !WorkspaceError) {
      setNewWorkspace(...workspace);
    }
    wasCreatingWorkspace.current = isCreatingWorkspace;
  }, [wasCreatingWorkspace.current, isCreatingWorkspace, WorkspaceError]);
  useEffect(() => {
    if (newWorkspace) {
      RedirectToTest(newWorkspace);
    }
    wasCreatingWorkspace.current = isCreatingWorkspace;
  }, [wasCreatingWorkspace.current, isCreatingWorkspace, WorkspaceError]);

  const startTest = () => {
    if (state.workspaceToResume) {
      //if there's incomplete test, use it for resume test
      RedirectToTest(state.workspaceToResume);
    } else if (workspaceCheck.emptyWorkspaces?.length > 0) {
      //if there's an empty workspace, use it for new test
      RedirectToTest(workspaceCheck.emptyWorkspaces?.[0]);
    } else {
      //if not, create an empty workspace for new test
      dispatch(createWorkspace());
    }
  };
  const StartBtn = () => (
    <GradientBtnWithSpin
      btnLabel='Start test'
      onClick={startTest}
      isLoading={isCreatingWorkspace}
    />
  );
  const NextBtn = () => (
    <OutlinedBtn buttonLabel='Next' onClick={useCallback(() => CarouselRef.current?.next(), [])} />
  );
  const FooterBtn = () => {
    return slideIndex === CarouselArr.length - 1 ? <StartBtn /> : <NextBtn />;
  };
  const Intro = () => (
    <React.Fragment>
      <Content>
        <Row justify='space-between'>
          <Col span={1} justify='start'>
            <PrimaryIconButton
              icon={<CloseOutlined />}
              fontSize='15px'
              onClick={useCallback(() => {
                setState((prevState) => {
                  return { ...prevState, showIntro: false, workspaceToResume: null };
                });
              }, [setState])}
            />
          </Col>
          <Col span={2}>
            <TextBtn buttonLabel='skip' padding='0px' fontSize='13px' onClick={startTest} />
          </Col>
        </Row>
        <Carousel
          ref={CarouselRef}
          afterChange={CarouselOnChange}
          initialSlide={slideIndex}
          style={{ display: 'flex' }}
        >
          {CarouselArr.map((element, number) => (
            <div key={number}>
              <HeadingIntroTemplate
                HeadingText={HeadingText[element]}
                IntroText={IntroText[element]}
                Content={
                  <Row style={{ marginTop: 'auto' }}>
                    <Col span={24} style={{ maxHeight: '50vh', paddingBottom: '2rem' }}>
                      {IntroImage[element]}
                    </Col>
                  </Row>
                }
                minHeight={'520px'}
              />
            </div>
          ))}
        </Carousel>
      </Content>
      <Footer style={{ background: 'inherit', padding: '0px' }}>
        <FooterBtn />
      </Footer>
    </React.Fragment>
  );

  const [state, setState] = useState({
    showResume: false,
    workspaceToResume: null,
    showIntro: false,
  });
  const ResumeTest = () => (
    <React.Fragment>
      <Content>
        <h1>Resume Test</h1>
        <Spin
          size={'large'}
          spinning={isGettingWorkspace || isGettingChunk || !workspaceCheck.workspacesCheckComplete}
        >
          {workspaceCheck.incompleteWorkspaces.map((ws) => {
            return (
              <Row
                justify='space-between'
                align='middle'
                style={{ marginTop: '1.5rem' }}
                key={ws[WORKSPACE_FIELDS.ID]}
              >
                <Col>
                  <TextBtnAsListItem
                    buttonLabel={ws[WORKSPACE_FIELDS.TITLE]}
                    onClick={useCallback(() => {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          workspaceToResume: ws,
                          showIntro: true,
                        };
                      });
                    }, [setState])}
                    fontSize={'14px'}
                    disabled={false}
                  />
                  <DescripText>
                    {'Created at: ' +
                      moment(ws[WORKSPACE_FIELDS.CREATED_AT]).format(DATE_TIME_FORMAT)}
                  </DescripText>
                </Col>
              </Row>
            );
          })}
        </Spin>
      </Content>
      <Footer style={{ background: 'inherit', padding: '0px' }}>
        <Row align='middle'>
          <Col>
            <TextIconBtn
              Icon={<LeftOutlined />}
              buttonLabel='Back'
              onClick={useCallback(() => {
                setState((prevState) => {
                  return { ...prevState, showResume: false };
                });
              }, [])}
              fontSize='1.4rem'
            />
          </Col>
        </Row>
      </Footer>
    </React.Fragment>
  );

  const DynamicContent = () => {
    if (state.showIntro) return <Intro />;
    else if (state.showResume) return <ResumeTest />;
    else return <DashboardContent />;
  };

  return (
    <Layout style={{ height: '100%', background: 'inherit' }}>
      <DynamicContent />
    </Layout>
  );
};

export default Dashboard;
