import ACTIONS from '../actionsType';
import {
  handleCreateResponse,
  handleDeleteResponse,
  handleGetResponse,
  handleGetResponses,
  handleUpdateResponse,
} from '@client/apiServices';
import { updateWorkspaceTask } from '@client/stores/actions/workspace';
import { TASKS_STATUS } from '@client/constants/constants';

/**
 * Create response and Update task status to completed
 * @param {string} workspaceId
 * @param {string} taskId
 * @param {string} type
 * @param {Array} answers
 * @return {(function(*): void)|*}
 */
const createResponse =
  ({ workspaceId, taskId, type, answers }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.CREATE_RESPONSE,
    });
    handleCreateResponse({ workspaceId, taskId, type, answers })
      .then((res) => {
        dispatch({
          type: ACTIONS.CREATE_RESPONSE_FULFILLED,
          payload: res,
        });
        dispatch(
          updateWorkspaceTask({
            workspaceId: workspaceId,
            taskId: taskId,
            status: TASKS_STATUS.COMPLETED,
          })
        );
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.CREATE_RESPONSE_ERROR,
          payload: err,
        });
      });
  };

/**
 * Get responses
 * @return {(function(*): void)|*}
 */
const getResponses = () => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_RESPONSES,
  });
  handleGetResponses()
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_RESPONSES_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_RESPONSES_ERROR,
        payload: err,
      });
    });
};

/**
 * Get one response
 * @param {string} responseId
 * @return {(function(*): void)|*}
 */
const getResponse = (responseId) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_RESPONSE,
  });
  handleGetResponse(responseId)
    .then((res) => {
      dispatch({
        type: ACTIONS.GET_RESPONSE_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.GET_RESPONSE_ERROR,
        payload: err,
      });
    });
};

/**
 * Update one playback
 * @param {string} responseId
 * @param {string} answerId
 * @param {string} playbackId
 * @param {string} text
 * @return {(function(*): void)|*}
 */
const updateResponseAnswer =
  ({ responseId, answerId, playbackId, text }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.UPDATE_RESPONSE_ANSWER,
    });
    handleUpdateResponse({ responseId, answerId, playbackId, text })
      .then((res) => {
        dispatch({
          type: ACTIONS.UPDATE_RESPONSE_ANSWER_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.UPDATE_RESPONSE_ANSWER_ERROR,
          payload: err,
        });
      });
  };

/**
 * Delete one response
 * @param {string} responseId
 * @return {(function(*): void)|*}
 */
const deletePlayback = (responseId) => (dispatch) => {
  dispatch({
    type: ACTIONS.DELETE_RESPONSE,
  });
  handleDeleteResponse(responseId)
    .then(() => {
      dispatch({
        type: ACTIONS.DELETE_RESPONSE_FULFILLED,
        payload: responseId,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.DELETE_RESPONSE_ERROR,
        payload: err,
      });
    });
};

export { createResponse, getResponses, getResponse, updateResponseAnswer, deletePlayback };
