import { PRIMARY_COLOR } from '@client/assets/styles/global';
import styled from 'styled-components';

const IconButton = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  color: ${PRIMARY_COLOR};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.6rem')};
  transition: 0.5s;
  cursor: pointer;
  position: relative;
  padding: ${(props) => (props.padding ? props.padding : '1rem 0rem')};

  &:hover {
    filter: brightness(120%);
  }
  &:disabled {
    filter: opacity(30%);
    pointer-events: none;
  }
`;

export { IconButton };
