import { Switch } from 'react-router-dom';
import { SmallLogoPageTemp } from '@client/templates/PageTemplate';
import AdminRoutes from '@client/routes/adminRoutes';
import { ROUTES } from '@client/constants/constants';
import AdminPage from '@client/pages/admin';
import AdminWorkspacesPage from '@client/pages/adminWorkspacesPage';
import React from 'react';

const AdminLayoutRoutesComp = () => {
  return (
    <Switch>
      {/*Admin*/}
      <AdminRoutes path={ROUTES.ADMIN} exact component={AdminPage} />
      <AdminRoutes path={ROUTES.ADMIN_USER_WORKSPACES} exact component={AdminWorkspacesPage} />
    </Switch>
  );
};

const AdminLayoutRoutes = () => {
  return SmallLogoPageTemp({
    content: <AdminLayoutRoutesComp />,
    maxHeight: '90vh',
    maxWidth: '90vw',
  });
};

export default AdminLayoutRoutes;
