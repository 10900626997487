import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { HeadingIntroTemplate } from '../templates/InnerTemplates';
import { TextBtn, TextIconBtn } from '../components/Button.style';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { downloadTracks } from '@client/stores/actions/track';
import useAudioInSequence from '@client/custom-hooks/useAudioInSequence';
import { CHUNK_FIELDS, TRACK_FIELDS } from '@client/constants/api';
import DigitPanel from '@client/components/DigitPanel';
import { RedoOutlined } from '@ant-design/icons';
import { DIGIT_LENGTH } from '@client/constants/constants';
import ACTIONS from '@client/stores/actionsType';

const DigitTest = ({
  pageState,
  testState,
  setTestState,
  setCurrentTaskState,
  spinning,
  isGettingTrack,
  wasGettingTrack,
}) => {
  const dispatch = useDispatch();

  // Tracks
  const tracks = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.TRACKS], null)
  );
  const tracksUrl = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.TRACKS_URL], null)
  );
  const isDownloadingTrack = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK],
      false
    )
  );
  const wasDownloadingTrack = useRef(isDownloadingTrack);
  const trackError = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.ERROR], null)
  );

  /*
   * Download URL after we got tracks from server for current test (question chunk)
   */
  useEffect(() => {
    if (wasGettingTrack.current && !isGettingTrack && !trackError) {
      setTestState((prevState) => {
        return { ...prevState, currentTrack: tracks };
      });
      dispatch(downloadTracks(tracks))
        .then((tracksWithFile) => {
          dispatch({
            type: ACTIONS.DOWNLOAD_TRACK_FULFILLED,
            payload: tracksWithFile,
          });
        })
        .catch((error) => {
          dispatch({
            type: ACTIONS.DOWNLOAD_TRACK_ERROR,
            payload: error,
          });
        });
    }
  }, [tracks, wasGettingTrack, isGettingTrack, trackError]);

  const [audioPlayed, setAudioPlayed] = useState(false);
  const [audioArr, setAudioArr] = useState(null);

  useEffect(() => {
    if (wasDownloadingTrack.current && !isDownloadingTrack && !trackError) {
      setAudioArr(tracksUrl.map((track) => track?.[TRACK_FIELDS.FILE]));
    }
    wasDownloadingTrack.current = isDownloadingTrack;
  }, [wasDownloadingTrack.current, isDownloadingTrack, trackError]);

  const testAudio = useAudioInSequence(audioArr);
  const [digitAnswer, setDigitAnswer] = useState(new Array(DIGIT_LENGTH).fill(undefined));
  const previousAnswer = useRef(digitAnswer);

  useEffect(() => {
    //check answer when digitAnswer changes
    if (digitAnswer !== previousAnswer.current) {
      let complete = true;
      digitAnswer.forEach((digit) => {
        if (digit === undefined) {
          complete = false;
        }
      });
      setCurrentTaskState({ answer: digitAnswer, complete: complete });
    }
    previousAnswer.current = digitAnswer;
  }, [digitAnswer]);
  const PlayTestAudio = () => {
    testAudio.setIsPlaying(true);
    if (!audioPlayed) setAudioPlayed(true);
  };
  const StartBtn = () => (
    <Spin size={'large'} spinning={isGettingTrack || isDownloadingTrack || spinning}>
      <Row style={{ marginTop: '3rem' }}>
        <TextBtn
          buttonLabel='start'
          textTransform='uppercase'
          fontSize='3rem'
          height='3rem'
          onClick={PlayTestAudio}
        />
      </Row>
    </Spin>
  );

  return (
    <HeadingIntroTemplate
      HeadingText={pageState?.chunks[testState.testNo - 1][CHUNK_FIELDS.TITLE]}
      IntroText={'Type the 3 digits as you heard them'}
      Content={
        audioPlayed ? (
          <>
            <Row justify={'end'}>
              <Col>
                <TextIconBtn
                  buttonLabel={'Replay'}
                  Icon={<RedoOutlined />}
                  fontSize={'1rem'}
                  onClick={PlayTestAudio}
                  disabled={testAudio.isPlaying}
                />
              </Col>
            </Row>
            <DigitPanel digitAnswer={digitAnswer} setDigitAnswer={setDigitAnswer} />
          </>
        ) : (
          <StartBtn />
        )
      }
    />
  );
};

export default DigitTest;
