/**
 * Actions type
 */
export default {
  // Account
  GET_SESSION_STATUS: 'GET_SESSION_STATUS',
  GET_SESSION_STATUS_FULFILLED: 'GET_SESSION_STATUS_FULFILLED',
  GET_SESSION_STATUS_ERROR: 'GET_SESSION_STATUS_ERROR',
  REGISTER: 'REGISTER',
  REGISTER_FULFILLED: 'REGISTER_FULFILLED',
  REGISTER_ERROR: 'REGISTER_ERROR',
  LOG_IN: 'LOG_IN',
  LOG_IN_FULFILLED: 'LOG_IN_FULFILLED',
  LOG_IN_ERROR: 'LOG_IN_ERROR',
  LOG_OUT_FULFILLED: 'LOG_OUT_FULFILLED',
  REDIRECT_TO_LOGIN: 'REDIRECT_TO_LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_FULFILLED: 'RESET_PASSWORD_FULFILLED',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  RESEND_RESET_PASSWORD: 'RESEND_RESET_PASSWORD',
  RESEND_RESET_PASSWORD_FULFILLED: 'RESEND_RESET_PASSWORD_FULFILLED',
  RESEND_RESET_PASSWORD_ERROR: 'RESEND_RESET_PASSWORD_ERROR',
  START_LOADING_ACCOUNT: 'START_LOADING_ACCOUNT',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  RESEND_EMAIL_VERIFICATION: 'RESEND_EMAIL_VERIFICATION',
  RESEND_EMAIL_VERIFICATION_FULFILLED: 'RESEND_EMAIL_VERIFICATION_FULFILLED',
  RESEND_EMAIL_VERIFICATION_ERROR: 'RESEND_EMAIL_VERIFICATION_ERROR',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_FULFILLED: 'UPDATE_PROFILE_FULFILLED',
  UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',
  // Admin
  GET_USERS: 'GET_USERS',
  GET_USERS_FULFILLED: 'GET_USERS_FULFILLED',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_FULFILLED: 'CREATE_USER_FULFILLED',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  GET_RESPONSES_ADMIN: 'GET_RESPONSES_ADMIN',
  GET_RESPONSES_ADMIN_FULFILLED: 'GET_RESPONSES_ADMIN_FULFILLED',
  GET_RESPONSES_ADMIN_ERROR: 'GET_RESPONSES_ADMIN_ERROR',
  GET_WORKSPACES_ADMIN: 'GET_WORKSPACES_ADMIN',
  GET_WORKSPACES_ADMIN_FULFILLED: 'GET_WORKSPACES_ADMIN_FULFILLED',
  GET_WORKSPACES_ADMIN_ERROR: 'GET_WORKSPACES_ADMIN_ERROR',
  // Chunk
  GET_CHUNK: 'GET_CHUNK',
  GET_CHUNK_FULFILLED: 'GET_CHUNK_FULFILLED',
  GET_CHUNK_ERROR: 'GET_CHUNK_ERROR',
  RESET_CHUNK_STATE: 'RESET_CHUNK_STATE',
  // Playback
  CREATE_PLAYBACK: 'CREATE_PLAYBACK',
  CREATE_PLAYBACK_FULFILLED: 'CREATE_PLAYBACK_FULFILLED',
  CREATE_PLAYBACK_ERROR: 'CREATE_PLAYBACK_ERROR',
  GET_PLAYBACKS: 'GET_PLAYBACKS',
  GET_PLAYBACKS_FULFILLED: 'GET_PLAYBACKS_FULFILLED',
  GET_PLAYBACKS_ERROR: 'GET_PLAYBACKS_ERROR',
  GET_PLAYBACK: 'GET_PLAYBACK',
  GET_PLAYBACK_FULFILLED: 'GET_PLAYBACK_FULFILLED',
  GET_PLAYBACK_ERROR: 'GET_PLAYBACK_ERROR',
  UPDATE_PLAYBACK: 'UPDATE_PLAYBACK',
  UPDATE_PLAYBACK_FULFILLED: 'UPDATE_PLAYBACK_FULFILLED',
  UPDATE_PLAYBACK_ERROR: 'UPDATE_PLAYBACK_ERROR',
  DOWNLOAD_PLAYBACK: 'DOWNLOAD_PLAYBACK',
  DOWNLOAD_PLAYBACK_FULFILLED: 'DOWNLOAD_PLAYBACK_FULFILLED',
  DOWNLOAD_PLAYBACK_ERROR: 'DOWNLOAD_PLAYBACK_ERROR',
  DELETE_PLAYBACK: 'DELETE_PLAYBACK',
  DELETE_PLAYBACK_FULFILLED: 'DELETE_PLAYBACK_FULFILLED',
  DELETE_PLAYBACK_ERROR: 'DELETE_PLAYBACK_ERROR',
  // Response
  GET_RESPONSES: 'GET_RESPONSES',
  GET_RESPONSES_FULFILLED: 'GET_RESPONSES_FULFILLED',
  GET_RESPONSES_ERROR: 'GET_RESPONSES_ERROR',
  GET_RESPONSE: 'GET_RESPONSE',
  GET_RESPONSE_FULFILLED: 'GET_RESPONSE_FULFILLED',
  GET_RESPONSE_ERROR: 'GET_RESPONSE_ERROR',
  CREATE_RESPONSE: 'CREATE_RESPONSE',
  CREATE_RESPONSE_FULFILLED: 'CREATE_RESPONSE_FULFILLED',
  CREATE_RESPONSE_ERROR: 'CREATE_RESPONSE_ERROR',
  DELETE_RESPONSE: 'DELETE_RESPONSE',
  DELETE_RESPONSE_FULFILLED: 'DELETE_RESPONSE_FULFILLED',
  DELETE_RESPONSE_ERROR: 'DELETE_RESPONSE_ERROR',
  UPDATE_RESPONSE_ANSWER: 'UPDATE_RESPONSE_ANSWER',
  UPDATE_RESPONSE_ANSWER_FULFILLED: 'UPDATE_RESPONSE_ANSWER_FULFILLED',
  UPDATE_RESPONSE_ANSWER_ERROR: 'UPDATE_RESPONSE_ANSWER_ERROR',
  // Track
  GET_RANDOM_TRACKS: 'GET_TRACKS',
  GET_RANDOM_TRACKS_FULFILLED: 'GET_TRACKS_FULFILLED',
  GET_RANDOM_TRACKS_ERROR: 'GET_TRACKS_ERROR',
  GET_TRACKS: 'GET_TRACKS',
  GET_TRACKS_FULFILLED: 'GET_TRACKS_FULFILLED',
  GET_TRACKS_ERROR: 'GET_TRACKS_ERROR',
  GET_TRACK: 'GET_TRACK',
  GET_TRACK_FULFILLED: 'GET_TRACK_FULFILLED',
  GET_TRACK_ERROR: 'GET_TRACK_ERROR',
  CREATE_TRACK: 'CREATE_TRACK',
  CREATE_TRACK_FULFILLED: 'CREATE_TRACK_FULFILLED',
  CREATE_TRACK_ERROR: 'CREATE_TRACK_ERROR',
  UPDATE_TRACK: 'UPDATE_TRACK',
  UPDATE_TRACK_FULFILLED: 'UPDATE_TRACK_FULFILLED',
  UPDATE_TRACK_ERROR: 'UPDATE_TRACK_ERROR',
  DELETE_TRACK: 'DELETE_TRACK',
  DELETE_TRACK_FULFILLED: 'DELETE_TRACK_FULFILLED',
  DELETE_TRACK_ERROR: 'DELETE_TRACK_ERROR',
  DOWNLOAD_TRACK: 'DOWNLOAD_TRACK',
  DOWNLOAD_TRACK_FULFILLED: 'DOWNLOAD_TRACK_FULFILLED',
  DOWNLOAD_TRACK_ERROR: 'DOWNLOAD_TRACK_ERROR',
  RESET_TRACK_STATE: 'RESET_TRACK_STATE',
  // Workspace
  GET_WORKSPACE: 'GET_WORKSPACE',
  GET_WORKSPACE_FULFILLED: 'GET_WORKSPACE_FULFILLED',
  GET_WORKSPACE_ERROR: 'GET_WORKSPACE_ERROR',
  GET_WORKSPACES: 'GET_WORKSPACES',
  GET_WORKSPACES_FULFILLED: 'GET_WORKSPACES_FULFILLED',
  GET_WORKSPACES_ERROR: 'GET_WORKSPACES_ERROR',
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  CREATE_WORKSPACE_FULFILLED: 'CREATE_WORKSPACE_FULFILLED',
  CREATE_WORKSPACE_ERROR: 'CREATE_WORKSPACE_ERROR',
  CREATE_WORKSPACE_TASK: 'CREATE_WORKSPACE_TASK',
  CREATE_WORKSPACE_TASK_FULFILLED: 'CREATE_WORKSPACE_TASK_FULFILLED',
  CREATE_WORKSPACE_TASK_ERROR: 'CREATE_WORKSPACE_TASK_ERROR',
  UPDATE_WORKSPACE_TASK: 'UPDATE_WORKSPACE_TASK',
  UPDATE_WORKSPACE_TASK_FULFILLED: 'UPDATE_WORKSPACE_TASK_FULFILLED',
  UPDATE_WORKSPACE_TASK_ERROR: 'UPDATE_WORKSPACE_TASK_ERROR',
  DELETE_WORKSPACE_TASK: 'DELETE_WORKSPACE_TASK',
  DELETE_WORKSPACE_TASK_FULFILLED: 'DELETE_WORKSPACE_TASK_FULFILLED',
  DELETE_WORKSPACE_TASK_ERROR: 'DELETE_WORKSPACE_TASK_ERROR',
  RESET_WORKSPACE_STATE: 'RESET_WORKSPACE_STATE',
};
