import { REDUCER_STATES } from '@client/constants/reducerStates';
import ACTIONS from '../actionsType';
import { findIndex, get } from 'lodash';
import { PLAYBACK_FIELDS } from '@client/constants/api';

const initialState = {
  [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: false,
  [REDUCER_STATES.PLAYBACK.FIELDS.IS_CREATING_PLAYBACK]: false,
  [REDUCER_STATES.PLAYBACK.FIELDS.IS_UPDATING_PLAYBACK]: false,
  [REDUCER_STATES.PLAYBACK.FIELDS.IS_DELETING_PLAYBACK]: false,
  [REDUCER_STATES.PLAYBACK.FIELDS.IS_DOWNLOADING_PLAYBACK]: false,
  [REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS]: [],
  [REDUCER_STATES.PLAYBACK.FIELDS.CURRENT_PLAYBACK]: null,
  [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
};

export const playback = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_PLAYBACK: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_CREATING_PLAYBACK]: true,
      };
    }
    case ACTIONS.CREATE_PLAYBACK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS]: [
          ...state[REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS],
          action.payload,
        ],
        [REDUCER_STATES.PLAYBACK.FIELDS.CURRENT_PLAYBACK]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_CREATING_PLAYBACK]: false,
      };
    }
    case ACTIONS.CREATE_PLAYBACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_CREATING_PLAYBACK]: false,
      };
    }

    case ACTIONS.GET_PLAYBACKS: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: true,
      };
    }
    case ACTIONS.GET_PLAYBACKS_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: false,
      };
    }
    case ACTIONS.GET_PLAYBACKS_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: false,
      };
    }

    case ACTIONS.GET_PLAYBACK: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: true,
      };
    }
    case ACTIONS.GET_PLAYBACK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.CURRENT_PLAYBACK]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: false,
      };
    }
    case ACTIONS.GET_PLAYBACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_GETTING_PLAYBACK]: false,
      };
    }

    case ACTIONS.UPDATE_PLAYBACK: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_UPDATING_PLAYBACK]: true,
      };
    }
    case ACTIONS.UPDATE_PLAYBACK_FULFILLED: {
      const currentPlaybacks = state[REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS];
      let newPlaybacks = currentPlaybacks;
      const index = findIndex(currentPlaybacks, {
        [PLAYBACK_FIELDS.ID]: get(action.payload, PLAYBACK_FIELDS.ID, null),
      });
      if (index !== -1) {
        newPlaybacks = [
          ...currentPlaybacks.slice(0, index),
          action.payload,
          ...currentPlaybacks.slice(index + 1),
        ];
      }
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.CURRENT_PLAYBACK]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS]: newPlaybacks,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_UPDATING_PLAYBACK]: false,
      };
    }
    case ACTIONS.UPDATE_PLAYBACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_UPDATING_PLAYBACK]: false,
      };
    }

    case ACTIONS.DELETE_PLAYBACK: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_DELETING_PLAYBACK]: true,
      };
    }
    case ACTIONS.DELETE_PLAYBACK_FULFILLED: {
      const currentPlaybacks = state[REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS];
      let newPlaybacks = currentPlaybacks;
      const index = findIndex(currentPlaybacks, {
        [PLAYBACK_FIELDS.ID]: get(action.payload, PLAYBACK_FIELDS.ID, null),
      });
      if (index !== -1) {
        newPlaybacks = [...currentPlaybacks.slice(0, index), ...currentPlaybacks.slice(index + 1)];
      }
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.CURRENT_PLAYBACK]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.PLAYBACKS]: newPlaybacks,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_DELETING_PLAYBACK]: false,
      };
    }
    case ACTIONS.DELETE_PLAYBACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_DELETING_PLAYBACK]: false,
      };
    }

    case ACTIONS.DOWNLOAD_PLAYBACK: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_DOWNLOADING_PLAYBACK]: true,
      };
    }
    case ACTIONS.DOWNLOAD_PLAYBACK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_DOWNLOADING_PLAYBACK]: false,
      };
    }
    case ACTIONS.DOWNLOAD_PLAYBACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.PLAYBACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.PLAYBACK.FIELDS.IS_DOWNLOADING_PLAYBACK]: false,
      };
    }
    default: {
      return state;
    }
  }
};
