import { Col, Row } from 'antd';
import React from 'react';
import { GradientBtn } from '../components/Button.style';
import { MailTwoTone } from '@ant-design/icons';
import { PRIMARY_COLOR } from '../assets/styles/global';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@client/constants/constants';
import * as PropTypes from 'prop-types';

const EmailSentPage = ({ messagePrompt }) => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Row>
        <Col span={24} type='flex' align='middle'>
          <MailTwoTone twoToneColor={PRIMARY_COLOR} style={{ fontSize: '64px' }} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p style={{ fontSize: '1rem', marginTop: '1rem' }}>{messagePrompt}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GradientBtn
            marginTop='.5rem'
            buttonLabel='Back to Login'
            onClick={() => {
              history.push(ROUTES.LOGIN);
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

EmailSentPage.prototype = {
  messagePrompt: PropTypes.string.isRequired,
};

export default EmailSentPage;
