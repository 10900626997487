import { PRIMARY_COLOR } from '../assets/styles/global';
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { verifyEmail } from '../stores/actions/account.action';
import { useDispatch } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleFilled } from '@ant-design/icons';
import { GradientBtn } from '../components/Button.style';
import { ROUTES } from '@client/constants/constants';
import * as PropTypes from 'prop-types';

// TODO: this component need to be re-write
const VerifyEmail = ({ fetched }) => {
  // Get params from url query string
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const userId = params.get('userId');
  const token = params.get('token');
  const [state, setState] = useState({
    verifying: false,
    verified: false,
    message: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetched) {
      dispatch(verifyEmail(userId, token, setState));
    }
  }, []);

  if (state.verifying)
    return (
      <Row>
        <Col span={24} align='middle'>
          <Spin size='large' />
        </Col>
      </Row>
    );
  else if (state.verified)
    return (
      <div>
        <Row>
          <Col span={24} type='flex' align='middle'>
            <CheckCircleTwoTone twoToneColor={PRIMARY_COLOR} style={{ fontSize: '64px' }} />
          </Col>
        </Row>
        <Row>
          <Col span={24} type='flex' align='middle'>
            <p style={{ fontSize: '1rem', marginTop: '1rem' }}>
              Your email address has been verified.
            </p>
          </Col>
        </Row>
        <Row justify='center'>
          <Col span={24}>
            <GradientBtn
              marginTop='.5rem'
              buttonLabel='Continue to Login'
              onClick={() => {
                history.push(ROUTES.LOGIN);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  else
    return (
      <Row>
        <Col>
          <p style={{ fontSize: '1rem' }}>
            <CloseCircleFilled style={{ paddingRight: '.5rem' }} />
            {state.message}
          </p>
        </Col>
      </Row>
    );
};

VerifyEmail.prototype = {
  fetched: PropTypes.bool,
};

export default VerifyEmail;
