import { Col, Dropdown, Menu, Row, Space } from 'antd';
import { LogoContainer, MainContainer, MyCard } from '../components/Container.style';
import { StyledLogoName, SmallNameLink } from '../components/Logo';
import { AppContent } from '../assets/styles/global';
import React from 'react';
import { ROUTES } from '../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { JustTextButton, RoundIconButton } from '../components/Button.style';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { logout } from '../stores/actions/account.action';
import { get } from 'lodash';
import { REDUCER_STATES } from '../constants/reducerStates';
import { Link } from 'react-router-dom';
import { ACCOUNT_FIELDS, USER_ROLE_ENUM } from '@client/constants/api';

export const SmallLogoPageTemp = ({ content, maxHeight = '700px', maxWidth = '400px' }) => {
  const isLoggedIn = useSelector((state) =>
    get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN], false)
  );
  const currentUser = useSelector((state) =>
    get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER], null)
  );
  const Home = isLoggedIn
    ? currentUser?.[ACCOUNT_FIELDS.ROLE] === USER_ROLE_ENUM.ADMIN
      ? ROUTES.ADMIN
      : ROUTES.DASHBOARD
    : ROUTES.LANDING;

  const dispatch = useDispatch();

  /**
   * Handle logout
   */
  const handleLogout = () => {
    dispatch(logout());
  };

  /**
   * Render user profile button
   * @return {JSX.Element}
   * @constructor
   */
  const RenderUserProfileBtn = () => {
    if (isLoggedIn) {
      return (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='profile'>
                <Link to={ROUTES.PROFILE}>
                  <Space>
                    <UserOutlined />
                    <JustTextButton>Profile</JustTextButton>
                  </Space>
                </Link>
              </Menu.Item>
              <Menu.Item key='logout'>
                <Space onClick={handleLogout}>
                  <LogoutOutlined />
                  <JustTextButton>Log out</JustTextButton>
                </Space>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement='topRight'
          arrow
        >
          <RoundIconButton content={<UserOutlined />} />
        </Dropdown>
      );
    }
  };

  return (
    <AppContent>
      <Row justify='center'>
        <MainContainer maxWidth={maxWidth}>
          <div style={{ height: '4rem' }}>
            <Row justify='space-between' align='bottom' style={{ height: '100%' }}>
              <Col>
                <SmallNameLink destination={Home} />
              </Col>
              <Col style={{ marginLeft: 'auto' }}>{RenderUserProfileBtn()}</Col>
            </Row>
          </div>

          <MyCard maxHeight={maxHeight}>{content}</MyCard>
        </MainContainer>
      </Row>
    </AppContent>
  );
};

export const BigLogoPageTemp = (content) => {
  return (
    <AppContent>
      <LogoContainer>
        <Row justify='center'>
          <Col>
            <StyledLogoName />
          </Col>
        </Row>
      </LogoContainer>
      <Row justify='center'>
        <MainContainer>
          <MyCard maxHeight='300px'>{content}</MyCard>
        </MainContainer>
      </Row>
    </AppContent>
  );
};
