import React from 'react';
import Svg from '../Svg';

const Ready = ({ className }) => (
  <Svg width='351.146' height='316.568' viewBox='0 0 351.146 316.568' className={className}>
    <g id='undraw_completed_tasks_vs6q' transform='translate(-0.002)'>
      <path
        id='Path_81'
        data-name='Path 81'
        d='M539.524,316.458H410.9a16.47,16.47,0,0,1-16.452-16.452V148.952A16.47,16.47,0,0,1,410.9,132.5H530.564l25.412,20.255V300.007A16.47,16.47,0,0,1,539.524,316.458Z'
        transform='translate(-321.349 -132.5)'
        fill='#e6e6e6'
      />
      <path
        id='Path_82'
        data-name='Path 82'
        d='M415.667,142a11.729,11.729,0,0,0-11.716,11.715V304.771a11.729,11.729,0,0,0,11.716,11.716H544.288A11.729,11.729,0,0,0,556,304.771V159.8L533.671,142Z'
        transform='translate(-326.113 -137.264)'
        fill='#fff'
      />
      <path
        id='Path_83'
        data-name='Path 83'
        d='M573.61,224.592H515.05a2.74,2.74,0,0,1,0-5.479h58.56a2.74,2.74,0,0,1,0,5.479Z'
        transform='translate(-380.452 -175.934)'
        fill='#ccc'
      />
      <path
        id='Path_84'
        data-name='Path 84'
        d='M584.911,243.139H515.05a2.74,2.74,0,0,1,0-5.479h69.861a2.74,2.74,0,0,1,0,5.479Z'
        transform='translate(-380.452 -185.234)'
        fill='#ccc'
      />
      <path
        id='Path_85'
        data-name='Path 85'
        d='M573.61,307.71H515.05a2.74,2.74,0,0,1,0-5.479h58.56a2.74,2.74,0,0,1,0,5.479Z'
        transform='translate(-380.452 -217.615)'
        fill='#ccc'
      />
      <path
        id='Path_86'
        data-name='Path 86'
        d='M584.911,326.257H515.05a2.74,2.74,0,0,1,0-5.479h69.861a2.74,2.74,0,0,1,0,5.479Z'
        transform='translate(-380.452 -226.915)'
        fill='#ccc'
      />
      <path
        id='Path_87'
        data-name='Path 87'
        d='M573.61,390.828H515.05a2.74,2.74,0,0,1,0-5.479h58.56a2.74,2.74,0,0,1,0,5.479Z'
        transform='translate(-380.452 -259.296)'
        fill='#ccc'
      />
      <path
        id='Path_88'
        data-name='Path 88'
        d='M584.911,409.375H515.05a2.74,2.74,0,0,1,0-5.479h69.861a2.74,2.74,0,0,1,0,5.479Z'
        transform='translate(-380.452 -268.596)'
        fill='#ccc'
      />
      <circle
        id='Ellipse_44'
        data-name='Ellipse 44'
        cx='9.683'
        cy='9.683'
        r='9.683'
        transform='translate(100.53 40.859)'
        fill='#f15290'
      />
      <path
        id='Path_395'
        data-name='Path 395'
        d='M463.661,233.884a1,1,0,0,1-.6-.2l-.011-.008-2.276-1.741A1.011,1.011,0,0,1,462,230.329l1.474,1.13,3.483-4.544a1.011,1.011,0,0,1,1.417-.187h0l-.022.03.022-.03a1.012,1.012,0,0,1,.187,1.418l-4.1,5.342a1.011,1.011,0,0,1-.8.394Z'
        transform='translate(-354.408 -179.648)'
        fill='#fff'
      />
      <circle
        id='Ellipse_44-2'
        data-name='Ellipse 44'
        cx='9.683'
        cy='9.683'
        r='9.683'
        transform='translate(101.527 82.296)'
        fill='#ccc'
      />
      <circle
        id='Ellipse_44-3'
        data-name='Ellipse 44'
        cx='9.683'
        cy='9.683'
        r='9.683'
        transform='translate(100.53 123.733)'
        fill='#ccc'
      />
      <path
        id='Path_89'
        data-name='Path 89'
        d='M691.924,153.791H672.018a5.479,5.479,0,0,1-5.479-5.479v-14.7a.342.342,0,0,1,.555-.268l25.043,19.839a.342.342,0,0,1-.213.611Z'
        transform='translate(-457.793 -132.885)'
        fill='#e6e6e6'
      />
      <path
        id='Path_90'
        data-name='Path 90'
        d='M347.2,479.089l-76.1,16.04a8.485,8.485,0,0,1-10.041-6.545L248,426.632a8.485,8.485,0,0,1,6.545-10.041l76.1-16.04a8.485,8.485,0,0,1,10.041,6.545l13.058,61.952A8.485,8.485,0,0,1,347.2,479.089Z'
        transform='translate(-247.819 -266.828)'
        fill='#fff'
      />
      <path
        id='Path_91'
        data-name='Path 91'
        d='M347.2,479.089l-76.1,16.04a8.485,8.485,0,0,1-10.041-6.545L248,426.632a8.485,8.485,0,0,1,6.545-10.041l76.1-16.04a8.485,8.485,0,0,1,10.041,6.545l13.058,61.952A8.485,8.485,0,0,1,347.2,479.089Zm-92.448-61.522a7.486,7.486,0,0,0-5.775,8.859l13.059,61.952a7.486,7.486,0,0,0,8.859,5.775l76.1-16.04a7.486,7.486,0,0,0,5.775-8.859L339.712,407.3a7.487,7.487,0,0,0-8.86-5.775Z'
        transform='translate(-247.819 -266.828)'
        fill='#3f3d56'
      />
      <path
        id='Path_411'
        data-name='Path 411'
        d='M364.179,439l-38.406,8.1a1.361,1.361,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l39.1-8.241c1.526.86,1.044,2.46-.076,2.7Z'
        transform='translate(-286.08 -284.846)'
        fill='#ccc'
      />
      <path
        id='Path_412'
        data-name='Path 412'
        d='M367.027,452.51l-38.406,8.1a1.362,1.362,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l39.1-8.241c1.527.86,1.044,2.46-.076,2.7Z'
        transform='translate(-287.508 -291.622)'
        fill='#ccc'
      />
      <path
        id='Path_413'
        data-name='Path 413'
        d='M298.741,465.816l-11.721,2.471a1.477,1.477,0,0,1-1.748-1.139l-2.915-13.832a1.477,1.477,0,0,1,1.139-1.748l11.721-2.471a1.477,1.477,0,0,1,1.748,1.139l2.915,13.832a1.477,1.477,0,0,1-1.139,1.748Z'
        transform='translate(-265.122 -291.247)'
        fill='#ccc'
      />
      <path
        id='Path_414'
        data-name='Path 414'
        d='M352.308,480.225,293.694,492.58a1.361,1.361,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l59.3-12.5c1.527.86,1.044,2.46-.076,2.7Z'
        transform='translate(-269.993 -305.521)'
        fill='#ccc'
      />
      <path
        id='Path_415'
        data-name='Path 415'
        d='M355.157,493.742,296.543,506.1a1.361,1.361,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l59.3-12.5c1.527.86,1.044,2.46-.076,2.7Z'
        transform='translate(-271.422 -312.299)'
        fill='#ccc'
      />
      <path
        id='Path_416'
        data-name='Path 416'
        d='M358,507.25,299.39,519.605a1.361,1.361,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l59.3-12.5c1.527.86,1.044,2.46-.076,2.7Z'
        transform='translate(-272.849 -319.073)'
        fill='#ccc'
      />
      <path
        id='Path_417'
        data-name='Path 417'
        d='M360.854,520.766l-58.615,12.355a1.361,1.361,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l59.3-12.5c1.526.86,1.044,2.46-.076,2.7Z'
        transform='translate(-274.278 -325.851)'
        fill='#ccc'
      />
      <path
        id='Path_418'
        data-name='Path 418'
        d='M363.7,534.276,305.086,546.63a1.361,1.361,0,0,1-1.61-.929,1.311,1.311,0,0,1,1-1.622l59.3-12.5c1.526.86,1.044,2.46-.076,2.7Z'
        transform='translate(-275.706 -332.625)'
        fill='#ccc'
      />
      <path
        id='Path_92'
        data-name='Path 92'
        d='M409.017,472.428a5.251,5.251,0,0,0-.341.753l-24,5.955-4.174-4.319-7.341,5.448,6.647,8.363a3.988,3.988,0,0,0,4.631,1.21l26.038-10.64a5.233,5.233,0,1,0-1.464-6.771Z'
        transform='translate(-310.675 -301.621)'
        fill='#a0616a'
      />
      <path
        id='Path_93'
        data-name='Path 93'
        d='M273.734,525.584a5.356,5.356,0,0,0,3.18-7.572l16.513-45.9-11.468-2.032-12.626,45.756a5.385,5.385,0,0,0,4.4,9.747Z'
        transform='translate(-257.3 -301.786)'
        fill='#a0616a'
      />
      <path
        id='Path_94'
        data-name='Path 94'
        d='M303.139,467.806l-11.323-4.346a2.4,2.4,0,0,1-1.231-3.421l5.94-10.532a6.669,6.669,0,1,1,12.438,4.816l-2.62,11.764a2.4,2.4,0,0,1-3.2,1.719Z'
        transform='translate(-269.109 -289.331)'
        fill='#f15290'
      />
      <path
        id='Path_95'
        data-name='Path 95'
        d='M119.524,598.052h6.112l2.907-23.575h-9.02Z'
        transform='translate(-59.936 -288.082)'
        fill='#a0616a'
      />
      <path
        id='Path_96'
        data-name='Path 96'
        d='M364.216,750.263h12.037a7.671,7.671,0,0,1,7.671,7.671v.249H364.216Z'
        transform='translate(-306.187 -442.288)'
        fill='#2f2e41'
      />
      <path
        id='Path_97'
        data-name='Path 97'
        d='M50.524,598.052h6.112l2.907-23.575h-9.02Z'
        transform='translate(-25.335 -288.082)'
        fill='#a0616a'
      />
      <path
        id='Path_98'
        data-name='Path 98'
        d='M295.216,750.263h12.037a7.67,7.67,0,0,1,7.671,7.671v.249H295.216Z'
        transform='translate(-271.586 -442.288)'
        fill='#2f2e41'
      />
      <path
        id='Path_99'
        data-name='Path 99'
        d='M301.16,652.485h-7.734a2.244,2.244,0,0,1-2.241-2.35l3.674-78.934.231-.006,37.35-.974,7.308,78.979a2.244,2.244,0,0,1-2.054,2.448l-8.688.695a2.245,2.245,0,0,1-2.377-1.786l-11.113-56.223a.725.725,0,0,0-.732-.6h0a.725.725,0,0,0-.731.6l-10.69,56.334A2.248,2.248,0,0,1,301.16,652.485Z'
        transform='translate(-269.563 -352.003)'
        fill='#2f2e41'
      />
      <circle
        id='Ellipse_37'
        data-name='Ellipse 37'
        cx='12.244'
        cy='12.244'
        r='12.244'
        transform='translate(33.52 124.31)'
        fill='#a0616a'
      />
      <path
        id='Path_100'
        data-name='Path 100'
        d='M313.732,514.542c-5.836,0-11.83-1.534-16.8-6.069a2.269,2.269,0,0,1-.733-1.919c.532-4.779,3.112-29.454,1.659-45.937a19.951,19.951,0,0,1,6.459-16.6,19.741,19.741,0,0,1,16.931-4.9h0c.153.027.3.056.457.085a19.833,19.833,0,0,1,15.843,21.1c-1.125,14.786-2.133,34.787-.55,46.1a2.24,2.24,0,0,1-1.2,2.312C332.195,510.529,323.17,514.542,313.732,514.542Z'
        transform='translate(-272.073 -286.094)'
        fill='#f15290'
      />
      <path
        id='Path_101'
        data-name='Path 101'
        d='M360.619,470a2.4,2.4,0,0,1-1.052-1.7l-1.492-11.96a6.669,6.669,0,0,1,12.839-3.613l4.914,11.048a2.4,2.4,0,0,1-1.55,3.289l-11.684,3.252A2.4,2.4,0,0,1,360.619,470Z'
        transform='translate(-302.982 -290.644)'
        fill='#f15290'
      />
      <path
        id='Path_102'
        data-name='Path 102'
        d='M319.107,402.348a8.773,8.773,0,0,1-3.389-.613c-.474-.183-.967-.333-1.44-.516a11.681,11.681,0,0,1-7.034-10.549,15.191,15.191,0,0,1,5.668-11.7,21.933,21.933,0,0,1,12.378-4.678c4.814-.367,10.235.854,12.925,4.863a4.223,4.223,0,0,1,.793,3.65,2.336,2.336,0,0,1-.658,1.011c-1.2,1.069-2.4.265-3.624.194a4.006,4.006,0,0,0-3.739,2.861,8.622,8.622,0,0,0,.135,4.981,12.445,12.445,0,0,1,.627,3.029,3.045,3.045,0,0,1-1.262,2.681A3.476,3.476,0,0,1,327,397.2a24.205,24.205,0,0,0-3.1-1.984,2.74,2.74,0,0,0-3.326.672,3.681,3.681,0,0,0-.42,1.214c-.593,2.7-.457,2.544-1.05,5.246Z'
        transform='translate(-277.616 -253.721)'
        fill='#2f2e41'
      />
      <path
        id='Path_103'
        data-name='Path 103'
        d='M343.707,766.5H248.986a.5.5,0,0,1,0-1h94.721a.5.5,0,0,1,0,1Z'
        transform='translate(-248.153 -449.929)'
        fill='#ccc'
      />
      <path
        id='Path_104'
        data-name='Path 104'
        d='M813.752,489.626l-67.829,38.047a8.485,8.485,0,0,1-11.538-3.245l-30.974-55.22a8.485,8.485,0,0,1,3.246-11.538l67.829-38.047a8.485,8.485,0,0,1,11.538,3.245L817,478.088a8.485,8.485,0,0,1-3.245,11.538Z'
        transform='translate(-475.741 -275.941)'
        fill='#fff'
      />
      <path
        id='Path_105'
        data-name='Path 105'
        d='M813.752,489.626l-67.829,38.047a8.485,8.485,0,0,1-11.538-3.245l-30.974-55.22a8.485,8.485,0,0,1,3.246-11.538l67.829-38.047a8.485,8.485,0,0,1,11.538,3.245L817,478.088a8.485,8.485,0,0,1-3.245,11.538ZM707.145,458.539a7.487,7.487,0,0,0-2.864,10.18l30.974,55.22a7.487,7.487,0,0,0,10.18,2.864l67.829-38.047a7.486,7.486,0,0,0,2.864-10.18l-30.974-55.22a7.486,7.486,0,0,0-10.18-2.864Z'
        transform='translate(-475.741 -275.941)'
        fill='#3f3d56'
      />
      <path
        id='Path_411-2'
        data-name='Path 411'
        d='M813.8,460.037l-34.232,19.2a1.361,1.361,0,0,1-1.814-.405,1.311,1.311,0,0,1,.466-1.846l34.846-19.546c1.714.365,1.731,2.036.733,2.6Z'
        transform='translate(-513.459 -295.448)'
        fill='#ccc'
      />
      <path
        id='Path_412-2'
        data-name='Path 412'
        d='M820.553,472.082l-34.232,19.2a1.361,1.361,0,0,1-1.814-.405,1.312,1.312,0,0,1,.466-1.846l34.846-19.546c1.714.365,1.732,2.036.733,2.6Z'
        transform='translate(-516.847 -301.488)'
        fill='#ccc'
      />
      <path
        id='Path_413-2'
        data-name='Path 413'
        d='M757.678,513.214l-10.447,5.86a1.477,1.477,0,0,1-2.008-.565l-6.916-12.329a1.477,1.477,0,0,1,.565-2.008l10.447-5.86a1.477,1.477,0,0,1,2.008.565l6.916,12.329a1.477,1.477,0,0,1-.565,2.008Z'
        transform='translate(-493.687 -315.849)'
        fill='#ccc'
      />
      <path
        id='Path_414-2'
        data-name='Path 414'
        d='M816.068,496.855,763.822,526.16a1.361,1.361,0,0,1-1.814-.405,1.311,1.311,0,0,1,.466-1.846l52.859-29.65C817.048,494.624,817.066,496.295,816.068,496.855Z'
        transform='translate(-505.565 -313.91)'
        fill='#ccc'
      />
      <path
        id='Path_415-2'
        data-name='Path 415'
        d='M822.826,508.9,770.58,538.208a1.362,1.362,0,0,1-1.814-.405,1.312,1.312,0,0,1,.466-1.846l52.859-29.65c1.714.365,1.731,2.036.733,2.6Z'
        transform='translate(-508.954 -319.952)'
        fill='#ccc'
      />
      <path
        id='Path_416-2'
        data-name='Path 416'
        d='M829.579,520.943l-52.245,29.305a1.362,1.362,0,0,1-1.814-.405,1.312,1.312,0,0,1,.466-1.846l52.859-29.65C830.56,518.712,830.577,520.383,829.579,520.943Z'
        transform='translate(-512.341 -325.99)'
        fill='#ccc'
      />
      <path
        id='Path_417-2'
        data-name='Path 417'
        d='M836.337,532.99,784.092,562.3a1.361,1.361,0,0,1-1.814-.405,1.312,1.312,0,0,1,.466-1.846l52.86-29.65c1.714.365,1.732,2.036.733,2.6Z'
        transform='translate(-515.73 -332.031)'
        fill='#ccc'
      />
      <path
        id='Path_418-2'
        data-name='Path 418'
        d='M843.091,545.032l-52.245,29.305a1.362,1.362,0,0,1-1.814-.405,1.311,1.311,0,0,1,.466-1.846l52.86-29.65C844.071,542.8,844.089,544.472,843.091,545.032Z'
        transform='translate(-519.116 -338.07)'
        fill='#ccc'
      />
      <path
        id='Path_106'
        data-name='Path 106'
        d='M867.938,569.428a4.713,4.713,0,0,0-3.131-6.513l-1.421-16.688-6.833,5.391,2.4,15.021a4.739,4.739,0,0,0,8.988,2.79Z'
        transform='translate(-553.079 -339.97)'
        fill='#ffb8b8'
      />
      <path
        id='Path_107'
        data-name='Path 107'
        d='M850.2,517.133l-5.675.677a2.109,2.109,0,0,1-2.357-2l-.828-19.145-10.757-29.387a6.056,6.056,0,0,1,.32-5.767,7.9,7.9,0,0,1,.9-1.163,6.037,6.037,0,0,1,5.266-1.87,6.3,6.3,0,0,1,4.728,3.285l7.015,14.7a41.76,41.76,0,0,1,4.035,19.728l-.791,18.938a2.108,2.108,0,0,1-1.83,2Z'
        transform='translate(-539.766 -295.937)'
        fill='#f15290'
      />
      <path
        id='Path_108'
        data-name='Path 108'
        d='M646.355,589.25l-5.261,2.309-11.411-19.2,7.765-3.408Z'
        transform='translate(-315.764 -285.313)'
        fill='#ffb8b8'
      />
      <path
        id='Path_109'
        data-name='Path 109'
        d='M900.354,744.355,883.389,751.8l-.094-.215a7.211,7.211,0,0,1,3.7-9.5h0l10.362-4.548Z'
        transform='translate(-566.182 -435.907)'
        fill='#2f2e41'
      />
      <path
        id='Path_110'
        data-name='Path 110'
        d='M512.908,600.183h-5.746l-2.734-22.163h8.481Z'
        transform='translate(-252.953 -289.858)'
        fill='#ffb8b8'
      />
      <path
        id='Path_111'
        data-name='Path 111'
        d='M753.559,758.66H735.031v-.234a7.211,7.211,0,0,1,7.211-7.211h11.316Z'
        transform='translate(-492.139 -442.765)'
        fill='#2f2e41'
      />
      <path
        id='Path_112'
        data-name='Path 112'
        d='M813.441,626.9l-32.009-71.431a.7.7,0,0,0-1.324.094l-19.5,68.994a2.1,2.1,0,0,1-2.245,1.546l-10.651-1.065a2.121,2.121,0,0,1-1.894-2.269c3.388-42.138,8.729-81.907,24.728-107.942l.085-.139,24.2,4.746,32.309,102.884a2.118,2.118,0,0,1-1.372,2.624l-9.743,3.132a2.1,2.1,0,0,1-2.582-1.173Z'
        transform='translate(-497.544 -324.157)'
        fill='#2f2e41'
      />
      <circle
        id='Ellipse_38'
        data-name='Ellipse 38'
        cx='11.511'
        cy='11.511'
        r='11.511'
        transform='translate(273.27 131.06)'
        fill='#ffb8b8'
      />
      <path
        id='Path_113'
        data-name='Path 113'
        d='M791.058,483.846l-.147-.03-.035-.146a50.074,50.074,0,0,1-.11-21.24,16.685,16.685,0,0,1,16.7-13.5h0A16.712,16.712,0,0,1,823.832,465a58.339,58.339,0,0,1-4.864,24.341l-.079.165Z'
        transform='translate(-519.546 -291.179)'
        fill='#f15290'
      />
      <path
        id='Path_114'
        data-name='Path 114'
        d='M725.044,543.567a4.713,4.713,0,0,0,3.78-6.16l13.259-10.233-8.232-2.824L722.522,534.5a4.739,4.739,0,0,0,2.522,9.067Z'
        transform='translate(-484.392 -329)'
        fill='#ffb8b8'
      />
      <path
        id='Path_115'
        data-name='Path 115'
        d='M738.207,500.319a2.1,2.1,0,0,1-2.693-.475l-3.642-4.4a2.109,2.109,0,0,1,.408-3.066l15.645-11.064L766.8,456.35a6.056,6.056,0,0,1,5.021-2.854,7.9,7.9,0,0,1,1.465.128h0a6.035,6.035,0,0,1,4.424,3.414,6.294,6.294,0,0,1-.2,5.753l-13.978,22.637-.031.049Z'
        transform='translate(-490.312 -293.469)'
        fill='#f15290'
      />
      <path
        id='Path_116'
        data-name='Path 116'
        d='M807.246,397.378c-.848-1.4-2.721-1.79-4.346-1.631a46.616,46.616,0,0,1-4.839.718c-4.535-.035-8.036-5.562-6.129-9.677,1.564-3.375,5.469-4.857,9-6.02,7.407-2.438,15.916-4.781,22.637-.828a14.065,14.065,0,0,1,6.77,12.533c-.151,3.112-1.343,6.308-.335,9.256,1.163,3.4,4.849,5.225,8.333,6.108,6.107,1.547,12.547,1.3,18.692,2.686s12.443,5.073,14.1,11.151c1.088,3.993-.047,8.291-1.928,11.977a31.02,31.02,0,0,1-43.275,12.693c-7.43-4.338-12.793-11.67-15.991-19.657a65.816,65.816,0,0,1-3.8-14.457,32.159,32.159,0,0,1,1.189-13.816A1.27,1.27,0,0,0,807.246,397.378Z'
        transform='translate(-520.406 -255.52)'
        fill='#2f2e41'
      />
      <path
        id='Path_117'
        data-name='Path 117'
        d='M810.707,766.5H715.986a.5.5,0,1,1,0-1h94.721a.5.5,0,0,1,0,1Z'
        transform='translate(-482.338 -449.929)'
        fill='#ccc'
      />
    </g>
  </Svg>
);
export default Ready;
