import React, { useEffect, useRef, useState } from 'react';
import { TEST_TYPE } from '../constants/constants';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parseQueryString } from '@client/services/libs';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { getWorkspaceById } from '@client/stores/actions/workspace';
import { WORKSPACE_FIELDS } from '@client/constants/api';
import { getChunkById } from '@client/stores/actions/chunk';
import ACTIONS from '@client/stores/actionsType';
import SelectTest from '@client/components/SelectTest';
import TestContent from '@client/components/TestContent';

const Test = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { workspaceId } = parseQueryString(location?.search);

  // Redux State Workspace
  const workspace = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE],
      null
    )
  );
  const isGettingWorkspace = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE],
      false
    )
  );
  const wasGettingWorkspace = useRef(isGettingWorkspace);
  const workspaceError = useSelector((state) => {
    _.get(state, [REDUCER_STATES.WORKSPACE.NAME, REDUCER_STATES.WORKSPACE.FIELDS.ERROR], false);
  });

  // Redux State Chunk
  const rootChunk = useSelector((state) =>
    _.get(state, [REDUCER_STATES.CHUNK.NAME, REDUCER_STATES.CHUNK.FIELDS.CURRENT_CHUNK], null)
  );
  //Redux State Tracks
  const tracks = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.TRACKS], null)
  );
  const isGettingTrack = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK], false)
  );
  const wasGettingTrack = useRef(isGettingTrack);
  const trackError = useSelector((state) =>
    _.get(state, [REDUCER_STATES.TRACK.NAME, REDUCER_STATES.TRACK.FIELDS.ERROR], null)
  );
  /*
   * Get workspace on mount
   */
  useEffect(() => {
    dispatch(getWorkspaceById(workspaceId));
  }, []);
  useEffect(() => {
    return () => {
      dispatch({ type: ACTIONS.RESET_CHUNK_STATE });
      dispatch({ type: ACTIONS.RESET_WORKSPACE_STATE });
    };
  }, []);
  /*
   * If workspace is ready, get chunks
   * Check workspace tasks
   */
  useEffect(() => {
    if (wasGettingWorkspace.current && !isGettingWorkspace && !workspaceError) {
      dispatch(getChunkById(workspace[WORKSPACE_FIELDS.CHUNK_ID]));
    }
    wasGettingWorkspace.current = isGettingWorkspace;
  }, [wasGettingWorkspace.current, isGettingWorkspace, workspaceError]);
  const [state, setState] = useState({
    testType: TEST_TYPE.UNSET,
    chunks: [], //5 chunks of current test type
    tracks: [], //for phoneme/sentence test this stores all 5 tracks, for digit test this stores the current test tracks (3)
  });

  /*
   * Store the tracks we got from the server in state.tracks
   */
  useEffect(() => {
    if (wasGettingTrack.current && !isGettingTrack && !trackError)
      setState((prevState) => {
        return { ...prevState, tracks: tracks };
      });
    wasGettingTrack.current = isGettingTrack;
  }, [tracks, isGettingTrack, trackError]);

  /*
   * testPages contains all the test pages  to be created.
   * Including AdjustVolume page and all digit triplet tests from the database.
   */
  const testPages = [TEST_TYPE.ADJUST_VOLUME];
  if (state.testType !== TEST_TYPE.UNSET) {
    if (state.testType === TEST_TYPE.PHONEME) testPages.push(TEST_TYPE.ADJUST_MICROPHONE);
    for (let t = 1; t < state.chunks.length + 1; t++) {
      testPages.push(state.testType);
    }
  }
  return state.testType === TEST_TYPE.UNSET ? (
    <SelectTest
      pageState={state}
      setPageState={setState}
      workspaceId={workspaceId}
      workspace={workspace}
      rootChunk={rootChunk}
      isGettingWorkspace={isGettingWorkspace}
    />
  ) : (
    <TestContent
      testPages={testPages}
      pageState={state}
      setPageState={setState}
      workspaceId={workspaceId}
      workspace={workspace}
      workspaceError={workspaceError}
      isGettingTrack={isGettingTrack}
      wasGettingTrack={wasGettingTrack}
    />
  );
};
export default Test;
