import Svg from '../Svg';
import React from 'react';

const OneTwoThree = () => (
  <Svg width='0.8em' viewBox='0 0 20 33'>
    <g id='Group_2' data-name='Group 2' transform='translate(-72 -120)'>
      <text
        id='_1'
        data-name='1'
        transform='translate(73 134)'
        fill='currentColor'
        fontSize='18'
        fontFamily='Helvetica'
      >
        <tspan x='0' y='0'>
          1
        </tspan>
      </text>
      <text
        id='_2'
        data-name='2'
        transform='translate(82 140)'
        fill='currentColor'
        fontSize='17'
        fontFamily='Helvetica'
      >
        <tspan x='0' y='0'>
          2
        </tspan>
      </text>
      <text
        id='_3'
        data-name='3'
        transform='translate(72 149)'
        fill='#f15290'
        fontSize='17'
        fontFamily='Helvetica'
      >
        <tspan x='0' y='0'>
          3
        </tspan>
      </text>
    </g>
  </Svg>
);
export default OneTwoThree;
