import Svg from '../Svg';
import React from 'react';

const QuietSvg = ({ className }) => (
  <Svg width='354' height='327.141' viewBox='0 0 354 327.141' className={className}>
    <path
      id='Path_7'
      data-name='Path 7'
      d='M842.823,595.052l-28.736-42.684s43.519,15.193,38.58,42.684Z'
      transform='translate(-520.312 -332.96)'
      fill='#e5e5e5'
    />
    <path
      id='Path_8'
      data-name='Path 8'
      d='M319.753,559.4l-1.091-.021a253.009,253.009,0,0,1,3.873-36.157c4.717-25.571,12.561-42.365,23.314-49.915l.626.893C321.357,491.833,319.766,558.723,319.753,559.4Z'
      transform='translate(-295.119 -297.022)'
      fill='#e5e5e5'
    />
    <path
      id='Path_9'
      data-name='Path 9'
      d='M344.753,599.354l-1.091-.021c.023-1.208.705-29.68,11.914-37.55l.626.893C345.446,570.227,344.758,599.063,344.753,599.354Z'
      transform='translate(-306.483 -337.239)'
      fill='#e5e5e5'
    />
    <circle
      id='Ellipse_5'
      data-name='Ellipse 5'
      cx='5.455'
      cy='5.455'
      r='5.455'
      transform='translate(52.108 166.365)'
      fill='#f15290'
    />
    <circle
      id='Ellipse_6'
      data-name='Ellipse 6'
      cx='5.455'
      cy='5.455'
      r='5.455'
      transform='translate(49.407 213.274)'
      fill='#f15290'
    />
    <path
      id='Path_10'
      data-name='Path 10'
      d='M334.968,471.694a24.229,24.229,0,0,1-1.647,12.4,22.08,22.08,0,0,1-3.712-23.711A24.227,24.227,0,0,1,334.968,471.694Z'
      transform='translate(-299.24 -291.151)'
      fill='#e5e5e5'
    />
    <path
      id='Path_11'
      data-name='Path 11'
      d='M363.27,503.748a24.229,24.229,0,0,1-12.5.54,22.08,22.08,0,0,1,22.7-7.787A24.228,24.228,0,0,1,363.27,503.748Z'
      transform='translate(-309.713 -307.303)'
      fill='#e5e5e5'
    />
    <path
      id='Path_12'
      data-name='Path 12'
      d='M366.756,581.816a16.983,16.983,0,0,1-8.762.378,15.477,15.477,0,0,1,15.912-5.458,16.982,16.982,0,0,1-7.15,5.08Z'
      transform='translate(-312.997 -343.828)'
      fill='#e5e5e5'
    />
    <path
      id='Path_13'
      data-name='Path 13'
      d='M628.954,631.713H276.045a.545.545,0,0,1,0-1.091H628.954a.545.545,0,1,1,0,1.091Z'
      transform='translate(-275.5 -368.53)'
      fill='#cbcbcb'
    />
    <path
      id='Path_14'
      data-name='Path 14'
      d='M366.972,514.258l-4.462,11.614-46.922-11.685,6.586-17.142Z'
      transform='translate(-143.449 -225.93)'
      fill='#ffb7b7'
    />
    <path
      id='Path_15'
      data-name='Path 15'
      d='M687.284,676.4,672.9,713.852l-.474-.182a15.616,15.616,0,0,1-8.977-20.177h0l8.788-22.873Z'
      transform='translate(-451.365 -386.711)'
      fill='#2f2e41'
    />
    <path
      id='Path_16'
      data-name='Path 16'
      d='M536.69,412.112l-20.448,1.915-1.014,60.841a11.453,11.453,0,0,0,12.193,10.389l12.965-.893Z'
      transform='translate(-384.467 -269.208)'
      fill='#e5e5e5'
    />
    <path
      id='Path_17'
      data-name='Path 17'
      d='M674.387,406.472l-13.991-.18-.829,72.292h0a22.517,22.517,0,0,0,23.251-23.375Z'
      transform='translate(-450.076 -266.562)'
      fill='#e5e5e5'
    />
    <path
      id='Path_18'
      data-name='Path 18'
      d='M534.1,577.565a44.521,44.521,0,0,1-1.439,6.2c-.706,1.985-1.8,3.821-2.442,5.827-2.058,6.393.795,13.522,5.351,18.457a30.119,30.119,0,0,0,14.684,8.72,59.9,59.9,0,0,0,12.619,1.326c11.729.4,23.759.754,34.873-3.016a60.583,60.583,0,0,0,10.132-4.6,7.587,7.587,0,0,0,2.629-2.07,7.235,7.235,0,0,0,.949-4.247c-.025-4.972-1.033-9.919-.794-14.885.133-2.751.647-5.5.394-8.242a15.2,15.2,0,0,0-11.62-13.1c-3.153-.677-6.423-.308-9.632,0a245.611,245.611,0,0,1-25.069,1.121c-8.552-.055-17.051-1.36-25.56-1.5-2.516-.04-2.466.735-3.267,3.171A59.291,59.291,0,0,0,534.1,577.565Z'
      transform='translate(-390.973 -339.861)'
      fill='#2f2e41'
    />
    <path
      id='Path_19'
      data-name='Path 19'
      d='M456.894,577.867a25.3,25.3,0,0,0-7.039-.748,17.568,17.568,0,0,0-16.31,20.71,29.511,29.511,0,0,0,2.789,7.622c1.867,3.788,4.106,7.568,7.561,10a29.445,29.445,0,0,0,6.673,3.177l18.2,6.857c2.678,1.009,5.356,2.018,8.06,2.953a157.641,157.641,0,0,0,42.1,8.369,45.581,45.581,0,0,0,13.07-.662,4.255,4.255,0,0,0,2.677-1.446,4.585,4.585,0,0,0,.6-1.976l1.12-7.841a6.567,6.567,0,0,0-.1-3.305,6.331,6.331,0,0,0-3.025-2.851c-11.966-6.873-25.938-10.664-36.009-20.1-2.371-2.22-4.355-5.74-7.236-7.249-3.267-1.711-7.114-2.687-10.491-4.263C472.153,583.666,464.895,579.706,456.894,577.867Z'
      transform='translate(-347.208 -344.204)'
      fill='#2f2e41'
    />
    <path
      id='Path_20'
      data-name='Path 20'
      d='M503.114,596.38c6.433,2.016,33.595,15.982,38.25,20.858-.624.225-21.8-11.641-22.432-11.831a127.932,127.932,0,0,1-17.066-6.014c-1.1-.51-7.168-2.909-6.893-4.3C495.283,593.521,501.972,596.023,503.114,596.38Z'
      transform='translate(-375.256 -352.147)'
      opacity='0.1'
    />
    <path
      id='Path_21'
      data-name='Path 21'
      d='M551.923,373.941,534.6,387.092s-18.277.572-17.66,12.256,7.933,67,7.933,67l1.587,19.034,30.105,8.578a98.5,98.5,0,0,0,49.9,1.07h0l-3.578-19.1s14.487-86.723,5.76-90.541-14.182-2.727-14.182-2.727l-10.939-9.546Z'
      transform='translate(-385.239 -251.485)'
      fill='#e5e5e5'
    />
    <path
      id='Path_22'
      data-name='Path 22'
      d='M616.318,296.228c0,17.073-15.787,14.491-35.261,14.491S545.8,313.3,545.8,296.228s7.712-47.337,35.261-47.337C609.556,248.891,616.318,279.155,616.318,296.228Z'
      transform='translate(-398.362 -195.016)'
      fill='#2f2e41'
    />
    <circle
      id='Ellipse_7'
      data-name='Ellipse 7'
      cx='23.727'
      cy='23.727'
      r='23.727'
      transform='translate(150.693 90.22) rotate(-45)'
      fill='#ffb7b7'
    />
    <path
      id='Path_23'
      data-name='Path 23'
      d='M559.964,293.491h9.385l4.14-11.593.828,11.593H578.8l2.415-6.762.483,6.762h33.33a25.118,25.118,0,0,0-25.118-25.118h-4.83a25.118,25.118,0,0,0-25.118,25.118Z'
      transform='translate(-404.802 -203.872)'
      fill='#2f2e41'
    />
    <rect
      id='Rectangle_15'
      data-name='Rectangle 15'
      width='47.455'
      height='57.273'
      transform='translate(159.047 125.183)'
      fill='#f15290'
    />
    <path
      id='Path_24'
      data-name='Path 24'
      d='M296.381,513.258l4.463,11.614,46.922-11.685-6.586-17.142Z'
      transform='translate(-134.719 -225.475)'
      fill='#ffb7b7'
    />
    <path
      id='Path_25'
      data-name='Path 25'
      d='M564.2,669.62l8.788,22.873h0a15.616,15.616,0,0,1-8.977,20.176l-.474.182L549.154,675.4Z'
      transform='translate(-399.888 -386.257)'
      fill='#2f2e41'
    />
    <path
      id='Path_26'
      data-name='Path 26'
      d='M678.308,569.533a32.756,32.756,0,0,1,11.54-.788c3.085.34,6.264,1.2,8.516,3.334,2.742,2.6,3.622,6.619,3.7,10.4A32.478,32.478,0,0,1,695,603.216a18.82,18.82,0,0,1-4.036,3.885,26.564,26.564,0,0,1-5.251,2.531l-40.761,16.077c-10.723,4.23-21.484,8.471-32.669,11.26a2.546,2.546,0,0,1-3.4-1.186l-.026-.056a84.446,84.446,0,0,1-8.933-16.342,1.1,1.1,0,0,1,.487-1.736l28.83-16.565a29.149,29.149,0,0,0,5.868-4.043,14.571,14.571,0,0,0,3.013-4.194c.6-1.248.653-4.194,1.417-5.117.747-.9,3.3-.975,4.485-1.331a40.715,40.715,0,0,0,4.882-1.846c6.449-2.885,12.192-7.077,18.374-10.455A47.132,47.132,0,0,1,678.308,569.533Z'
      transform='translate(-422.853 -340.315)'
      fill='#2f2e41'
    />
    <path
      id='Path_27'
      data-name='Path 27'
      d='M675.307,590.4c-4.953,3.72-11.367,7.14-16.276,10.958-1.675,1.3-29.605,17.005-30,19.091,4.58.772,33.754-19.638,37.928-21.675a67.417,67.417,0,0,0,11.541-7.759c.934-.726,4.894-2.91,2.212-3.722C679.2,586.837,676.422,589.658,675.307,590.4Z'
      transform='translate(-436.196 -348.813)'
      opacity='0.1'
    />
    <circle
      id='Ellipse_8'
      data-name='Ellipse 8'
      cx='17.044'
      cy='17.044'
      r='17.044'
      transform='translate(311.094 63.938)'
      fill='#f1f1f1'
    />
    <circle
      id='Ellipse_9'
      data-name='Ellipse 9'
      cx='11.956'
      cy='11.956'
      r='11.956'
      transform='translate(284.637 77.166)'
      fill='#f1f1f1'
    />
    <circle
      id='Ellipse_10'
      data-name='Ellipse 10'
      cx='9.587'
      cy='9.587'
      r='9.587'
      transform='translate(67.984 74.106)'
      fill='#f1f1f1'
    />
    <circle
      id='Ellipse_11'
      data-name='Ellipse 11'
      cx='6.725'
      cy='6.725'
      r='6.725'
      transform='translate(86.629 86.276)'
      fill='#f1f1f1'
    />
    <circle
      id='Ellipse_12'
      data-name='Ellipse 12'
      cx='7.328'
      cy='7.328'
      r='7.328'
      transform='translate(179.028 0)'
      fill='#f1f1f1'
    />
    <path
      id='Path_28'
      data-name='Path 28'
      d='M537.087,442.408l.655-5.057s24.436,1.784,20.618,7.784-13.091,6.545-13.091,6.545Z'
      transform='translate(-394.403 -280.68)'
      fill='#2f2e41'
      opacity='0.17'
    />
    <path
      id='Path_29'
      data-name='Path 29'
      d='M548.457,406.267a9.54,9.54,0,0,1,6.941,4.262h0a9.531,9.531,0,0,1-3.1,13.4,9.184,9.184,0,0,1-1.263.621l-15.02,45.942a7.631,7.631,0,1,1-13.721-6.68l15.973-45.432a9.521,9.521,0,0,1,10.19-12.111Z'
      transform='translate(-387.328 -266.525)'
      fill='#ffb7b7'
    />
    <path
      id='Path_30'
      data-name='Path 30'
      d='M527.766,438.142s8.606,14.7,21.048,7.353l-12.058,46.145H526.6l-10.51-1.963,2.943-19.065Z'
      transform='translate(-384.858 -281.039)'
      fill='#e5e5e5'
    />
    <path
      id='Path_31'
      data-name='Path 31'
      d='M644.216,442.363l-7.13-9.788s3.427-10.153,16.713-6.44C653.8,426.135,662.254,445.06,644.216,442.363Z'
      transform='translate(-439.857 -275.211)'
      fill='#e5e5e5'
    />
    <path
      id='Path_32'
      data-name='Path 32'
      d='M644.216,442.363l-7.13-9.788s3.427-10.153,16.713-6.44C653.8,426.135,662.254,445.06,644.216,442.363Z'
      transform='translate(-439.857 -275.211)'
      fill='#2f2e41'
      opacity='0.17'
    />
    <path
      id='Path_33'
      data-name='Path 33'
      d='M642.293,393.114a9.469,9.469,0,0,1,5.091,11.262l15.973,45.432a7.631,7.631,0,1,1-13.721,6.68l-15.02-45.942a9.188,9.188,0,0,1-1.263-.621,9.532,9.532,0,0,1-3.1-13.4h0a9.54,9.54,0,0,1,6.941-4.262A9.424,9.424,0,0,1,642.293,393.114Z'
      transform='translate(-436.047 -260.159)'
      fill='#ffb7b7'
    />
    <path
      id='Path_34'
      data-name='Path 34'
      d='M637.087,433.255s11.763,7.138,16.713-6.44l16.575,43.644-4.925,8.614-.712.059a18.216,18.216,0,0,1-15.591-6.593h0a36.187,36.187,0,0,1-7.56-16.077Z'
      transform='translate(-439.858 -275.891)'
      fill='#e5e5e5'
    />
  </Svg>
);
export default QuietSvg;
