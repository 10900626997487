import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { SECOND_COLOR } from '../assets/styles/global';

const LinkComponent = ({ To, Content }) => {
  return <Link to={To}>{Content}</Link>;
};
export const UnderlinedLink = styled(LinkComponent)`
  text-decoration: underline !important;
  color: black;
`;
export const NoDeLink = styled(Link)`
  color: ${SECOND_COLOR};
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  text-decoration: none;
  display: block;
  &:hover {
    color: ${SECOND_COLOR};
  }
`;
