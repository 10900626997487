import React from 'react';
import { Form, Input, Radio } from 'antd';
import { ACCOUNT_FIELDS, USER_PREFERENCES_ENUM } from '@client/constants/api';
import { EMAIL_FORMAT_RULE, USER_NAME_FORMAT_RULE } from '@client/constants/formValidation';
import GradientBtnWithSpin from '@client/components/GradientBtnWithSpin';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '@client/stores/actions/account.action';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { ROUTES } from '@client/constants/constants';
import { TextBtn } from '@client/components/Button.style';
import { useHistory } from 'react-router-dom';

const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const hearingModOptions = Object.keys(USER_PREFERENCES_ENUM.HEARING_MOD.ENUM)?.map((each) => ({
  label: USER_PREFERENCES_ENUM.HEARING_MOD.ENUM[each],
  value: USER_PREFERENCES_ENUM.HEARING_MOD.ENUM[each],
}));

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER], null)
  );
  const isUpdatingProfile = useSelector((state) =>
    _.get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_UPDATING_PROFILE],
      false
    )
  );

  /**
   * Handle update profile
   * @param {Object} value
   */
  const onFinish = (value) => {
    const updatedValue = {
      [ACCOUNT_FIELDS.NAME]: value?.[ACCOUNT_FIELDS.NAME],
      [ACCOUNT_FIELDS.EMAIL]: value?.[ACCOUNT_FIELDS.EMAIL],
      [ACCOUNT_FIELDS.HEARING_AID]: value?.[ACCOUNT_FIELDS.HEARING_AID],
      [ACCOUNT_FIELDS.NORMAL_HEARING_MOD]: value?.[ACCOUNT_FIELDS.NORMAL_HEARING_MOD],
      [ACCOUNT_FIELDS.PREFERENCES]: {
        [USER_PREFERENCES_ENUM.HEARING_MOD.NAME]: value?.[USER_PREFERENCES_ENUM.HEARING_MOD.NAME],
      },
    };
    dispatch(updateProfile(updatedValue));
  };
  return (
    <>
      <h1>Update profile</h1>
      <Form
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          [ACCOUNT_FIELDS.NAME]: currentUser?.[ACCOUNT_FIELDS.NAME],
          [ACCOUNT_FIELDS.EMAIL]: currentUser?.[ACCOUNT_FIELDS.EMAIL],
          [ACCOUNT_FIELDS.HEARING_AID]: currentUser?.[ACCOUNT_FIELDS.HEARING_AID],
          [ACCOUNT_FIELDS.NORMAL_HEARING_MOD]: currentUser?.[ACCOUNT_FIELDS.NORMAL_HEARING_MOD],
          [USER_PREFERENCES_ENUM.HEARING_MOD.NAME]:
            currentUser?.[ACCOUNT_FIELDS.PREFERENCES]?.[USER_PREFERENCES_ENUM.HEARING_MOD.NAME],
        }}
      >
        <Form.Item label='Name' name={ACCOUNT_FIELDS.NAME} rules={USER_NAME_FORMAT_RULE}>
          <Input />
        </Form.Item>

        <Form.Item label='Email' name={ACCOUNT_FIELDS.EMAIL} rules={EMAIL_FORMAT_RULE}>
          <Input />
        </Form.Item>

        <Form.Item
          name={ACCOUNT_FIELDS.HEARING_AID}
          label='I wear a hearing aid'
          rules={[{ required: true, message: 'Please select an option!' }]}
        >
          <Radio.Group options={radioOptions} />
        </Form.Item>
        <Form.Item
          name={ACCOUNT_FIELDS.NORMAL_HEARING_MOD}
          label='I wear normal hearing modification'
          style={{ display: 'none' }}
          rules={[{ required: true, message: 'Please select an option!' }]}
        >
          <Radio.Group options={radioOptions} />
        </Form.Item>
        {/*<h3>Preferences</h3>*/}
        <Form.Item
          name={USER_PREFERENCES_ENUM.HEARING_MOD.NAME}
          label='Hearing Mod'
          rules={[{ required: true, message: 'Please select an option!' }]}
        >
          <Radio.Group options={hearingModOptions} />
        </Form.Item>

        <Form.Item>
          <GradientBtnWithSpin
            htmlType='submit'
            styles={{ marginTop: '2rem' }}
            btnLabel={'Confirm'}
            isLoading={isUpdatingProfile}
          />

          <TextBtn
            buttonLabel='Reset password'
            height='3rem'
            onClick={() => {
              history.push(ROUTES.FORGOT_PASSWORD);
            }}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default Profile;
