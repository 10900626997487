import { ttl } from '@client/services/libs';

const ENDPOINTS = {
  // Auth
  LOG_IN: 'auth/login',
  SIGN_UP: 'auth/register',
  RESEND: 'auth/resend-confirmation',
  RESET_PASSWORD: 'auth/reset-password',
  RESEND_RESET_PASSWORD: 'auth/resend-reset-password',
  VERIFY_EMAIL: 'auth/confirmation',
  CURRENT_USER: 'auth/current',
  PROFILE: 'auth/profile',
  LOG_OUT: 'auth/logout',
  // Admin
  ADMIN_USERS: 'admin/users',
  ADMIN_RESPONSES: 'admin/responses',
  ADMIN_WORKSPACES: 'admin/workspaces',
  // Playbacks
  PLAYBACKS: 'playbacks',
  PLAYBACK: ttl`playbacks/${'playbackId'}`,
  PLAYBACK_DOWNLOAD: ttl`playbacks/${'playbackId'}/download`,
  // Chunk
  CHUNKS: ttl`chunks/${'chunkId'}`,
  DEFAULT_CHUNK: 'chunks/default',
  // Response
  RESPONSES: 'responses',
  RESPONSE: ttl`responses/${'responseId'}`,
  RESPONSE_ANSWER: ttl`responses/${'responseId'}/answers/${'answerId'}`,
  // Track
  TRACKS: 'tracks',
  TRACKS_RANDOM: 'tracks/random',
  TRACK: ttl`tracks/${'trackId'}`,
  TRACK_DOWNLOAD: ttl`tracks/${'trackId'}/download`,
  // Workspace
  WORKSPACES: 'workspaces',
  WORKSPACE: ttl`workspaces/${'workspaceId'}`,
  WORKSPACE_TASKS: ttl`workspaces/${'workspaceId'}/tasks`,
  WORKSPACE_TASK: ttl`workspaces/${'workspaceId'}/tasks/${'taskId'}`,
};

export default ENDPOINTS;
