import { Checkbox, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import { JustTextButton } from '../components/Button.style';
import { useHistory } from 'react-router-dom';
import { login } from '../stores/actions/account.action';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '@root/src/client/constants/constants';
import _ from 'lodash';
import { REDUCER_STATES } from '@client/constants/reducerStates';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import GradientBtnWithSpin from '@client/components/GradientBtnWithSpin';
import { EMAIL_FORMAT_RULE, PASSWORD_FORMAT_RULE } from '@client/constants/formValidation';
import { USER_ROLE_ENUM } from '@client/constants/api/account';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.USER])
  );
  const isLoggingIn = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGING_IN])
  );
  const isLoggedIn = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN])
  );

  /**
   * Submit login detail
   * @param {Object} values - {email:xxx, password:xxx}
   */
  const handleSubmit = (values) => {
    dispatch(login({ email: values.email, password: values.password }));
  };

  useEffect(() => {
    if (isLoggedIn && user) {
      if (_.get(user, ACCOUNT_FIELDS.EMAIL_VERIFIED, false)) {
        if (_.get(user, ACCOUNT_FIELDS.ROLE, null) === USER_ROLE_ENUM.ADMIN) {
          history.push(ROUTES.ADMIN);
        } else {
          history.push(ROUTES.DASHBOARD);
        }
      } else {
        history.push({
          pathname: ROUTES.RE_VERIFY_EMAIL,
          search: `?id=${_.get(user, ACCOUNT_FIELDS.ID, null)}`,
        });
      }
    }
  }, [isLoggedIn, user]);

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <div>
        <Form
          name='login'
          layout='horizontal'
          initialValues={{
            remember: false,
          }}
          onFinish={handleSubmit}
        >
          <Form.Item name='email' rules={EMAIL_FORMAT_RULE}>
            <Input placeholder='Email' type={'email'} />
          </Form.Item>
          <Form.Item name='password' rules={PASSWORD_FORMAT_RULE}>
            <Input type='password' placeholder='Password' />
          </Form.Item>

          <Form.Item>
            <Form.Item name='remember' valuePropName='checked' noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a href={ROUTES.FORGOT_PASSWORD} style={{ float: 'right' }}>
              Forgot password
            </a>
          </Form.Item>

          <Form.Item>
            <GradientBtnWithSpin
              type='primary'
              htmlType='submit'
              btnLabel={'Sign In'}
              isLoading={isLoggingIn}
            />
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: '-15px' }}>
        <JustTextButton
          onClick={() => {
            history.push(ROUTES.SIGNUP);
          }}
        >
          Sign Up
        </JustTextButton>
      </div>
    </Space>
  );
};

export default Login;
