import { REDUCER_STATES } from '@client/constants/reducerStates';
import ACTIONS from '../actionsType';
import { findIndex, get } from 'lodash';
import { TRACK_FIELDS } from '@client/constants/api/track';

const initialState = {
  [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
  [REDUCER_STATES.TRACK.FIELDS.IS_CREATING_TRACK]: false,
  [REDUCER_STATES.TRACK.FIELDS.IS_UPDATING_TRACK]: false,
  [REDUCER_STATES.TRACK.FIELDS.IS_DELETING_TRACK]: false,
  [REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK]: false,
  [REDUCER_STATES.TRACK.FIELDS.TRACKS]: [],
  [REDUCER_STATES.TRACK.FIELDS.TRACKS_URL]: [],
  [REDUCER_STATES.TRACK.FIELDS.CURRENT_TRACK]: null,
  [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
};

export const track = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_TRACK: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_CREATING_TRACK]: true,
      };
    }
    case ACTIONS.CREATE_TRACK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.TRACKS]: [
          ...state[REDUCER_STATES.TRACK.FIELDS.TRACKS],
          action.payload,
        ],
        [REDUCER_STATES.TRACK.FIELDS.CURRENT_TRACK]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_CREATING_TRACK]: false,
      };
    }
    case ACTIONS.CREATE_TRACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_CREATING_TRACK]: false,
      };
    }
    case ACTIONS.GET_RANDOM_TRACKS: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: true,
      };
    }
    case ACTIONS.GET_RANDOM_TRACKS_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.TRACKS]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
      };
    }
    case ACTIONS.GET_RANDOM_TRACKS_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
      };
    }

    case ACTIONS.GET_TRACKS: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: true,
      };
    }
    case ACTIONS.GET_TRACKS_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.TRACKS]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
      };
    }
    case ACTIONS.GET_TRACKS_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
      };
    }

    case ACTIONS.GET_TRACK: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: true,
      };
    }
    case ACTIONS.GET_TRACK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.CURRENT_TRACK]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
      };
    }
    case ACTIONS.GET_TRACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_GETTING_TRACK]: false,
      };
    }

    case ACTIONS.UPDATE_TRACK: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_UPDATING_TRACK]: true,
      };
    }
    case ACTIONS.UPDATE_TRACK_FULFILLED: {
      const currentTracks = state[REDUCER_STATES.TRACK.FIELDS.TRACKS];
      let newTracks = currentTracks;
      const index = findIndex(currentTracks, {
        [TRACK_FIELDS.ID]: get(action.payload, TRACK_FIELDS.ID, null),
      });
      if (index !== -1) {
        newTracks = [
          ...currentTracks.slice(0, index),
          action.payload,
          ...currentTracks.slice(index + 1),
        ];
      }
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.CURRENT_TRACK]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.TRACKS]: newTracks,
        [REDUCER_STATES.TRACK.FIELDS.IS_UPDATING_TRACK]: false,
      };
    }
    case ACTIONS.UPDATE_TRACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_UPDATING_TRACK]: false,
      };
    }

    case ACTIONS.DELETE_TRACK: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_DELETING_TRACK]: true,
      };
    }
    case ACTIONS.DELETE_TRACK_FULFILLED: {
      const currentTracks = state[REDUCER_STATES.TRACK.FIELDS.TRACKS];
      let newTracks = currentTracks;
      const index = findIndex(currentTracks, {
        [TRACK_FIELDS.ID]: get(action.payload, TRACK_FIELDS.ID, null),
      });
      if (index !== -1) {
        newTracks = [...currentTracks.slice(0, index), ...currentTracks.slice(index + 1)];
      }
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.CURRENT_TRACK]: null,
        [REDUCER_STATES.TRACK.FIELDS.TRACKS]: newTracks,
        [REDUCER_STATES.TRACK.FIELDS.IS_DELETING_TRACK]: false,
      };
    }
    case ACTIONS.DELETE_TRACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_DELETING_TRACK]: false,
      };
    }

    case ACTIONS.DOWNLOAD_TRACK: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: null,
        [REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK]: true,
      };
    }
    case ACTIONS.DOWNLOAD_TRACK_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK]: false,
        [REDUCER_STATES.TRACK.FIELDS.TRACKS_URL]: action.payload,
      };
    }
    case ACTIONS.DOWNLOAD_TRACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.TRACK.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.TRACK.FIELDS.IS_DOWNLOADING_TRACK]: false,
      };
    }
    case ACTIONS.RESET_TRACK_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
