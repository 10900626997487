import React from 'react';
import Svg from '../Svg';

const StopRecord = ({ className }) => (
  <Svg
    width='0.8em'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
    className={className}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='currentColor'
      stroke='none'
    >
      <path d='M2321 5110 c-497 -48 -990 -251 -1376 -565 -114 -92 -294 -274 -384 -387 -229 -287 -417 -675 -495 -1023 -49 -218 -60 -325 -60 -575 0 -250 11 -357 60 -575 79 -355 272 -749 509 -1040 92 -114 274 -294 387 -384 287 -229 675 -417 1023 -495 218 -49 325 -60 575 -60 250 0 357 11 575 60 261 58 603 204 828 353 389 259 688 599 893 1016 125 255 196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283 480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19 -468 27 -599 15z m1074 -1411 c124 -23 223 -100 278 -217 l32 -67 0 -855 0 -855 -32 -67 c-55 -117 -154 -194 -278 -217 -81 -15 -1589 -15 -1670 0 -158 29 -275 146 -304 304 -15 81 -15 1589 0 1670 28 153 138 268 289 301 74 17 1598 19 1685 3z' />
    </g>
  </Svg>
);
export default StopRecord;
