/**
 * Extract APi response
 * @param {Object} response
 * @return {*}
 */
const extractResponse = (response) => {
  return response?.data?.data;
};

export { extractResponse };
