import API from '@client/axios';
import { errorHandler } from '@client/axios/errorHandler';
import { extractResponse } from '@client/axios/extractResponse';
import ENDPOINTS from '@client/constants/endpoints';
// import { RESPONSE_FIELDS } from '@client/constants/api';

/**
 * Get responses
 * @return {Promise<*>}
 */
const handleGetResponses = async () => {
  try {
    const res = await API.get(ENDPOINTS.RESPONSES);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get one responses
 * @param {string} responseId
 * @return {Promise<*>}
 */
const handleGetResponse = async (responseId) => {
  try {
    const res = await API.get(ENDPOINTS.RESPONSE({ responseId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Create new response
 * @param {string} workspaceId
 * @param {string} taskId
 * @param {string} type
 * @param {Array} answers
 * @return {Promise<*>}
 */
const handleCreateResponse = async ({ workspaceId, taskId, type, answers }) => {
  try {
    const res = await API.post(ENDPOINTS.RESPONSES, { workspaceId, taskId, type, answers });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Update response
 * @param {string} responseId
 * @param {string} answerId
 * @param {string} playbackId
 * @param {string} text
 * @return {Promise<*>}
 */
const handleUpdateResponse = async ({ responseId, answerId, playbackId, text }) => {
  try {
    const res = await API.post(ENDPOINTS.RESPONSE_ANSWER({ responseId, answerId }), {
      playbackId,
      text,
    });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Delete one responses
 * @param {string} responseId
 * @return {Promise<*>}
 */
const handleDeleteResponse = async (responseId) => {
  try {
    const res = await API.delete(ENDPOINTS.RESPONSE({ responseId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

export {
  handleGetResponses,
  handleGetResponse,
  handleCreateResponse,
  handleUpdateResponse,
  handleDeleteResponse,
};
