import API from '@client/axios';
import { errorHandler } from '@client/axios/errorHandler';
import { extractResponse } from '@client/axios/extractResponse';
import ENDPOINTS from '@client/constants/endpoints';
import { isEmpty } from 'lodash';
import { ACCOUNT_FIELDS } from '@client/constants/api';
import { USER_ROLE_ENUM } from '@client/constants/api/account';

/**
 * Get users
 * @return {Promise<*>}
 */
const handleGetUsers = async () => {
  try {
    const res = await API.get(ENDPOINTS.ADMIN_USERS);
    return extractResponse(res)?.filter(
      (each) => each?.[ACCOUNT_FIELDS.ROLE] !== USER_ROLE_ENUM.ADMIN
    );
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get workspaces for admin user
 * @return {Promise<*>}
 */
const handleGetAdminWorkspaces = async () => {
  try {
    const res = await API.get(ENDPOINTS.ADMIN_WORKSPACES);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get responses for admin user
 * @param {[]|Array} userId
 * @param {[]|Array} workspaceId
 * @return {Promise<*>}
 */
const handleGetAdminResponses = async ({ userId = [], workspaceId = [] }) => {
  try {
    let query = null;
    let res;
    if (!isEmpty(userId) && !isEmpty(workspaceId)) {
      query = `?userId=${userId}&workspaceId=${workspaceId}`;
    } else if (!isEmpty(userId) && isEmpty(workspaceId)) {
      query = `?userId=${userId}`;
    } else if (isEmpty(userId) && !isEmpty(workspaceId)) {
      query = `?workspaceId=${workspaceId}`;
    }
    if (query) {
      res = await API.get(ENDPOINTS.ADMIN_RESPONSES + query);
    } else {
      res = await API.get(ENDPOINTS.ADMIN_RESPONSES);
    }
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Create new user
 * @param {string} name
 * @param {string} email
 * @param {boolean} emailVerified
 * @param {string} password
 * @param {boolean} hearingAid
 * @param {boolean} normalHearingMod
 * @return {Promise<*>}
 */
const handleCreateUser = async ({
  name,
  email,
  emailVerified,
  password,
  hearingAid,
  normalHearingMod,
}) => {
  try {
    const res = await API.post(ENDPOINTS.ADMIN_USERS, {
      name,
      email,
      emailVerified,
      password,
      hearingAid,
      normalHearingMod,
    });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

export { handleGetUsers, handleCreateUser, handleGetAdminResponses, handleGetAdminWorkspaces };
