import { REDUCER_STATES } from '@client/constants/reducerStates';
import ACTIONS from '../actionsType';
import { get, findIndex } from 'lodash';
import { TASK_FIELDS, WORKSPACE_FIELDS } from '@client/constants/api';

const initialState = {
  [REDUCER_STATES.WORKSPACE.FIELDS.IS_UPDATING_WORKSPACE_TASK]: false,
  [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE_TASK]: false,
  [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: false,
  [REDUCER_STATES.WORKSPACE.FIELDS.IS_DELETING_WORKSPACE_TASK]: false,
  [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE]: null,
  [REDUCER_STATES.WORKSPACE.FIELDS.WORKSPACES]: null,
  [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_TASK]: null,
  [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
};

export const workspace = (state = initialState, action) => {
  switch (action.type) {
    /* Create workspace task */
    case ACTIONS.CREATE_WORKSPACE_TASK: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE_TASK]: true,
      };
    }
    case ACTIONS.CREATE_WORKSPACE_TASK_FULFILLED: {
      const newWorkspace = { ...state[REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE] };
      const currentTasks =
        state[REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE][WORKSPACE_FIELDS.TASKS];
      const newTasks = [...currentTasks, action.payload];
      newWorkspace[WORKSPACE_FIELDS.TASKS] = newTasks;
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE]: newWorkspace,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE_TASK]: false,
        [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_TASK]: action.payload,
      };
    }
    case ACTIONS.CREATE_WORKSPACE_TASK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE_TASK]: false,
      };
    }

    /* Get all workspaces of current user*/
    case ACTIONS.GET_WORKSPACES: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: true,
      };
    }
    case ACTIONS.GET_WORKSPACES_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.WORKSPACES]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: false,
      };
    }
    case ACTIONS.GET_WORKSPACES_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: false,
      };
    }

    /* Create one workspace */
    case ACTIONS.CREATE_WORKSPACE: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE]: true,
      };
    }
    case ACTIONS.CREATE_WORKSPACE_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE]: false,
      };
    }
    case ACTIONS.CREATE_WORKSPACE_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_CREATING_WORKSPACE]: false,
      };
    }

    /* Get one workspace */
    case ACTIONS.GET_WORKSPACE: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: true,
      };
    }
    case ACTIONS.GET_WORKSPACE_FULFILLED: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: false,
      };
    }
    case ACTIONS.GET_WORKSPACE_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_GETTING_WORKSPACE]: false,
      };
    }

    /* Update workspace task */
    case ACTIONS.UPDATE_WORKSPACE_TASK: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_UPDATING_WORKSPACE_TASK]: true,
      };
    }
    case ACTIONS.UPDATE_WORKSPACE_TASK_FULFILLED: {
      const newWorkspace = { ...state[REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE] };
      const currentWorkspaceTasks =
        state[REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE][WORKSPACE_FIELDS.TASKS];
      const index = findIndex(currentWorkspaceTasks, {
        [TASK_FIELDS.ID]: action.payload[TASK_FIELDS.ID],
      });
      let newTasks = currentWorkspaceTasks;
      if (index !== -1) {
        newTasks = [
          ...currentWorkspaceTasks.slice(0, index),
          action.payload,
          ...currentWorkspaceTasks.slice(index + 1),
        ];
      }
      newWorkspace[WORKSPACE_FIELDS.TASKS] = newTasks;
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE]: newWorkspace,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_UPDATING_WORKSPACE_TASK]: false,
      };
    }
    case ACTIONS.UPDATE_WORKSPACE_TASK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_UPDATING_WORKSPACE_TASK]: false,
      };
    }

    case ACTIONS.DELETE_WORKSPACE_TASK: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: null,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_DELETING_WORKSPACE_TASK]: true,
      };
    }
    case ACTIONS.DELETE_PLAYBACK_FULFILLED: {
      const { workspaceId, taskId } = action.payload;
      const currentWorkspace = state[REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE];
      if (get(currentWorkspace, WORKSPACE_FIELDS.ID, null) === workspaceId) {
        const currentTasks =
          state[REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE][WORKSPACE_FIELDS.TASKS];
        const newTasks = currentTasks.filter((each) => each[TASK_FIELDS.ID] !== taskId);
        return {
          ...state,
          [REDUCER_STATES.WORKSPACE.FIELDS.CURRENT_WORKSPACE]: {
            ...currentWorkspace,
            [WORKSPACE_FIELDS.TASKS]: newTasks,
          },
          [REDUCER_STATES.WORKSPACE.FIELDS.IS_DELETING_WORKSPACE_TASK]: false,
        };
      } else {
        return {
          ...state,
          [REDUCER_STATES.WORKSPACE.FIELDS.IS_DELETING_WORKSPACE_TASK]: false,
        };
      }
    }
    case ACTIONS.DELETE_PLAYBACK_ERROR: {
      return {
        ...state,
        [REDUCER_STATES.WORKSPACE.FIELDS.ERROR]: action.payload,
        [REDUCER_STATES.WORKSPACE.FIELDS.IS_DELETING_WORKSPACE_TASK]: false,
      };
    }
    case ACTIONS.RESET_WORKSPACE_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
