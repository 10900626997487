import React from 'react';
import Svg from '../Svg';
const Internet = ({ className }) => (
  <Svg width='354' height='278' viewBox='0 0 354 278' className={className}>
    <g id='undraw_speed_test_wxl0' transform='translate(0)'>
      <path
        id='Path_60'
        data-name='Path 60'
        d='M221.114,249.137l-41.951,34.791a19,19,0,1,1-29.217,24.23l-37.611,31.191,60.291,72.7L281.4,321.837Z'
        transform='translate(-105.921 -180.528)'
        fill='#f2f2f2'
      />
      <path
        id='Path_61'
        data-name='Path 61'
        d='M893.894,368.785c0,42.969-25.545,57.973-57.056,57.973s-57.056-15-57.056-57.973,57.056-97.634,57.056-97.634S893.894,325.815,893.894,368.785Z'
        transform='translate(-539.895 -194.841)'
        fill='#f2f2f2'
      />
      <path
        id='Path_62'
        data-name='Path 62'
        d='M800.326,367.184l.584-35.961,24.319-44.49L801,325.582l.263-16.17,16.76-32.188-16.691,27.909h0l.472-29.082,17.947-25.625L801.88,271.477l.3-53.326-1.855,70.595.152-2.912L782.226,257.9l17.955,33.521-1.7,32.48-.05-.862-21.036-29.392,20.972,32.438-.212,4.062-.038.061.017.333-4.313,82.4h5.763l.691-42.563,20.921-32.358Z'
        transform='translate(-505.46 -141.841)'
        fill='#3f3d56'
      />
      <ellipse
        id='Ellipse_23'
        data-name='Ellipse 23'
        cx='79.5'
        cy='6'
        rx='79.5'
        ry='6'
        transform='translate(168 266)'
        fill='#3f3d56'
      />
      <rect
        id='Rectangle_16'
        data-name='Rectangle 16'
        width='13'
        height='273'
        transform='translate(242)'
        fill='#3f3d56'
      />
      <path
        id='Path_63'
        data-name='Path 63'
        d='M969.674,768.917c0,7.86-4.672,10.6-10.436,10.6q-.2,0-.4,0c-.267-.006-.531-.018-.793-.036-5.2-.368-9.243-3.254-9.243-10.563,0-7.565,9.667-17.11,10.393-17.816h0l.042-.041S969.674,761.058,969.674,768.917Z'
        transform='translate(-649.791 -506.876)'
        fill='#f15290'
      />
      <path
        id='Path_64'
        data-name='Path 64'
        d='M969.755,778.318l3.817-5.333-3.826,5.919-.01.612c-.267-.006-.532-.019-.793-.036l.411-7.863,0-.061.007-.011.039-.743-3.836-5.934,3.848,5.376.009.158.311-5.941-3.284-6.131,3.324,5.089.324-12.317v0l-.054,9.713,3.269-3.851-3.283,4.687-.086,5.319,3.053-5.105-3.065,5.888-.048,2.957,4.432-7.105-4.448,8.137Z'
        transform='translate(-660.687 -506.876)'
        fill='#3f3d56'
      />
      <path
        id='Path_65'
        data-name='Path 65'
        d='M737.946,782.207l-2.232-1.659s-1.478,8.254.171,9.491,7.832,3.3,7.832,5.771,14.016,5.771,14.428,2.061a8.175,8.175,0,0,0-1.237-5.771s-6.6-6.6-7.42-7.832-2.886-3.71-2.886-3.71Z'
        transform='translate(-510.845 -526.05)'
        fill='#2f2e41'
      />
      <path
        id='Path_66'
        data-name='Path 66'
        d='M770.216,483.424s-.062.363-.169,1.026c-.1.589-.235,1.422-.4,2.449-1.731,10.565-6.781,41.668-6.439,42.694.412,1.237,3.3,5.771,0,7.008a2.557,2.557,0,0,0-1.026,1.117,23.951,23.951,0,0,0-1.744,4.114,180.776,180.776,0,0,0-5.285,21.16c-.532,2.778-.895,5.013-1,6.183a3.36,3.36,0,0,0-.012.816c.412,1.649-2.473,7.42-3.71,9.069s-3.3,3.3-2.886,4.947-10.306,6.183-11.542,3.3-1.237-4.535-1.649-5.359-.824-6.6,1.237-7.42.824-15.252,2.886-18.138c.688-.96,1.74-4.213,2.84-8.125.433-1.533.87-3.166,1.3-4.8,1.74-6.707,3.281-13.455,3.281-13.455l-.412-48.231,22.673-2.886.581,1.282,1.158,2.543Z'
        transform='translate(-510.137 -329.913)'
        fill='#2f2e41'
      />
      <path
        id='Path_67'
        data-name='Path 67'
        d='M832.61,715.394s3.3-3.3,4.947,0,3.3,16.077,6.183,18.963,6.6,17.314,2.473,18.138-8.657-1.237-9.069-3.71-9.069-15.253-9.069-16.489,5.771-5.771,5.771-5.771Z'
        transform='translate(-571.295 -482.734)'
        fill='#2f2e41'
      />
      <path
        id='Path_68'
        data-name='Path 68'
        d='M696.088,424.934s5.359,48.231,6.6,52.765,7.832,21.848,9.893,24.734,15.252,26.383,16.9,27.619,4.947,4.535,4.947,4.535,6.183,6.183,7.008,7.42,7.832-2.473,8.245-4.947-.412-6.6-.824-8.245.824-4.122-.412-5.359-7.42-7.42-7.42-10.306-10.718-19.787-14.84-23.5-3.3-9.893-3.3-9.893V454.614l22.673-7.008s-1.237-19.787-4.122-19.787S700.622,421.224,696.088,424.934Z'
        transform='translate(-485.476 -294.094)'
        fill='#2f2e41'
      />
      <circle
        id='Ellipse_24'
        data-name='Ellipse 24'
        cx='13.604'
        cy='13.604'
        r='13.604'
        transform='translate(230.399 34.79)'
        fill='#9f616a'
      />
      <path
        id='Path_69'
        data-name='Path 69'
        d='M771.013,208.939s-1.649,6.183-3.71,7.832,5.359,8.657,5.359,8.657l14.016.824,2.473-7.832s-4.535-9.069-3.3-14.84S771.013,208.939,771.013,208.939Z'
        transform='translate(-531.545 -149.828)'
        fill='#9f616a'
      />
      <path
        id='Path_70'
        data-name='Path 70'
        d='M721.349,242.02s3.71.412,4.535,1.649,6.6,4.947,11.13,4.122,7.42-2.061,8.245-1.649,2.473,9.069,2.473,9.069L743.2,268.4l.824,36.276,1.237,9.893s2.473-3.3-7.42-1.237-22.673-1.649-26.795-2.061-11.13-1.649-11.542-4.122,1.237-11.542,1.237-11.542l6.6-27.619,4.122-14.84Z'
        transform='translate(-487.653 -175.9)'
        fill='#3f3d56'
      />
      <path
        id='Path_71'
        data-name='Path 71'
        d='M710.072,235.056s-3.487-1.849-4.724-.2-16.9,4.122-16.9,5.359-3.3,34.627-2.473,37.513-1.649,6.183-1.649,6.183l-2.886,12.367s-7.832,23.5-4.535,24.321,6.183,0,7.42,0,.825-15.665,4.122-19.787,7.832-14.428,8.657-16.489,11.13-30.5,10.718-33.8S710.072,235.056,710.072,235.056Z'
        transform='translate(-472.476 -170.797)'
        fill='#d0cde1'
      />
      <path
        id='Path_72'
        data-name='Path 72'
        d='M805.791,242.02s3.3,0,3.71.824,4.535,6.183,6.183,7.42,7.42,6.6,7.42,7.832S811.149,268.4,811.149,268.4s-4.947,24.321-2.473,32.566,6.183,28.032,4.535,29.268-5.359,1.649-5.359,0-2.473-19.375-4.534-23.085-8.245-27.207-5.771-33.8S805.791,242.02,805.791,242.02Z'
        transform='translate(-551.07 -175.9)'
        fill='#d0cde1'
      />
      <path
        id='Path_73'
        data-name='Path 73'
        d='M666.609,358.687s-.412,12.779,4.122,19.375a78.337,78.337,0,0,1,7.42,14.016s7.42-2.061,8.657-4.535,9.069-.412,9.069-1.237-9.481-4.947-10.306-5.771-3.71-.824-5.359-2.473-2.061-5.359-2.061-5.359L676.5,361.985Z'
        transform='translate(-466.303 -251.757)'
        fill='#9f616a'
      />
      <path
        id='Path_74'
        data-name='Path 74'
        d='M755.454,143.721a7.84,7.84,0,0,1-3.051-.7,4.085,4.085,0,0,1-1.044-6.048,7.816,7.816,0,0,1,1.449-1.165l3.133-2.133a15.328,15.328,0,0,1,4.421-2.347,13.8,13.8,0,0,1,4.519-.267,30.538,30.538,0,0,1,8.657,1.574,14.488,14.488,0,0,1,7.014,5.133c2.653,3.839,2.69,9.016,1.228,13.448s-4.237,8.291-6.972,12.072l-1.419-6.924a2.556,2.556,0,0,0-.8-1.658c-1.275-.907-2.834.955-4.4.943a2.635,2.635,0,0,1-2.23-1.765,10.061,10.061,0,0,1-.494-2.962c-.187-2.121-.928-5.073-2.937-6.243C760.655,143.59,757.558,143.912,755.454,143.721Z'
        transform='translate(-520.821 -103.715)'
        fill='#2f2e41'
      />
      <path
        id='Path_75'
        data-name='Path 75'
        d='M766.94,492.569s1.392,40.272,2.217,41.509,0-41.635,0-41.635Z'
        transform='translate(-531.544 -338.724)'
        opacity='0.1'
      />
      <path
        id='Path_76'
        data-name='Path 76'
        d='M679.8,251.447h-3.3s-14.016,18.962-12.367,20.2,0,4.535,0,4.535l-3.3,6.183s-4.122,3.71-2.886,4.535,2.886,4.122,2.886,4.122,8.245-1.237,10.718,3.71l5.346-6.75Z'
        transform='translate(-460.527 -182.03)'
        fill='#d0cde1'
      />
      <path
        id='Path_77'
        data-name='Path 77'
        d='M733.078,310.874s-9.069.412-19.375-6.183L703.4,300.156s-9.069-14.016-9.893-11.542,1.237,7.42,1.237,7.42-12.437,5.756-12.76,6.517,6.989-1.158,6.989-1.158-1.237,7.832,5.771,8.245,9.069-1.237,9.069-1.237,29.68,24.734,34.627,16.9S733.078,310.874,733.078,310.874Z'
        transform='translate(-476.297 -206.005)'
        fill='#9f616a'
      />
      <path
        id='Path_78'
        data-name='Path 78'
        d='M834.864,283.266l4.534,1.649v12.367l1.649,2.473s-.412,4.947-1.649,6.183-3.71.824-2.886,3.71a15.625,15.625,0,0,1,.412,5.771s-7.42,9.481-7.832,8.245-6.183-16.489-7.008-16.489,8.245-23.909,8.245-23.909Z'
        transform='translate(-567.365 -202.718)'
        fill='#d0cde1'
      />
      <path
        id='Path_79'
        data-name='Path 79'
        d='M698.677,448.474s-4.262-4.122-2.131-4.328,9.551-4.328,10.788-2.267S698.677,448.474,698.677,448.474Z'
        transform='translate(-485.386 -305.473)'
        fill='#2f2e41'
      />
      <path
        id='Path_80'
        data-name='Path 80'
        d='M242.217,583.682a79.755,79.755,0,1,0-136.924,0Z'
        transform='translate(-94 -319.581)'
        fill='#3f3d56'
      />
      <circle
        id='Ellipse_25'
        data-name='Ellipse 25'
        cx='7.5'
        cy='7.5'
        r='7.5'
        transform='translate(72 210)'
        fill='#f15290'
      />
      <ellipse
        id='Ellipse_26'
        data-name='Ellipse 26'
        cx='3.5'
        cy='4'
        rx='3.5'
        ry='4'
        transform='translate(15 227)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_27'
        data-name='Ellipse 27'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(16.949 193.345)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_28'
        data-name='Ellipse 28'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(36.049 165.941)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_29'
        data-name='Ellipse 29'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(66.535 152.288)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_30'
        data-name='Ellipse 30'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(99.698 156.287)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_31'
        data-name='Ellipse 31'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(126.065 176.795)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_32'
        data-name='Ellipse 32'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(138.103 207.954)'
        fill='#f15290'
      />
      <circle
        id='Ellipse_33'
        data-name='Ellipse 33'
        cx='3.848'
        cy='3.848'
        r='3.848'
        transform='translate(132.374 240.862)'
        fill='#f15290'
      />
      <line
        id='Line_3'
        data-name='Line 3'
        y1='30'
        x2='40'
        transform='translate(80 188)'
        fill='none'
        stroke='#f15290'
        strokeMiterlimit='10'
        strokeWidth='4'
      />
      <circle
        id='Ellipse_34'
        data-name='Ellipse 34'
        cx='5.162'
        cy='5.162'
        r='5.162'
        transform='translate(111.162 64.222)'
        fill='none'
        stroke='#3f3d56'
        strokeMiterlimit='10'
        strokeWidth='2'
      />
      <circle
        id='Ellipse_35'
        data-name='Ellipse 35'
        cx='5.162'
        cy='5.162'
        r='5.162'
        transform='translate(140.546 124.388)'
        fill='#3f3d56'
      />
      <circle
        id='Ellipse_36'
        data-name='Ellipse 36'
        cx='14.964'
        cy='14.964'
        r='14.964'
        transform='translate(43.881 100.879)'
        fill='#f2f2f2'
      />
    </g>
  </Svg>
);
export default Internet;
