import { useState, useEffect, useMemo, useRef } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import notification from '@client/services/notification';
import MESSAGE from '@client//constants/messages';

/**
 * Custom Hook to make a mp3 recording.
 * Can also play the recorded mp3 audio.
 * @param {number} limit
 * @return {{isPlaying: boolean, setIsPlaying: void, isBlocked: boolean, recordState: {isRecording: boolean, audio: HTMLAudioElement, hasRecording: boolean}, stopRecording: StopRecording, StartRecording: StartRecording}}
 */
const useRecorderPlayer = (limit) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [recordState, setRecordState] = useState({
    isRecording: false,
    hasRecording: false,
    audio: new Audio(),
    audioBlob: null,
  });
  const resetRecordState = () => {
    setRecordState({
      isRecording: false,
      hasRecording: false,
      audio: new Audio(),
      audioBlob: null,
    });
  };
  const Mp3Recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);

  // Play or pause the recording according to state
  useEffect(() => {
    if (isPlaying) {
      recordState.audio.play();
    } else recordState.audio.pause();
  }, [isPlaying]);

  // Add end event listener
  useEffect(() => {
    const adjustVolumeListener = () => {
      setIsPlaying(false);
    };
    recordState.audio.addEventListener('ended', adjustVolumeListener);
    return () => {
      recordState.audio.removeEventListener('ended', adjustVolumeListener);
    };
  }, []);

  // Request permission for microphone
  useEffect(() => {
    let isCancelled = false;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        if (!isCancelled) {
          if (isBlocked) setIsBlocked(false);
        }
      })
      .catch((err) => {
        if (!isCancelled) {
          if (!recordState.isBlocked) {
            console.log('Permission Denied');
            setIsBlocked(true);
            console.log(err);
            notification.error({ message: MESSAGE.MICROPHONE_REQUEST_ERROR });
          }
        }
      });
    return () => (isCancelled = true);
  }, []);

  const recordingRef = useRef(recordState.isRecording);
  recordingRef.current = recordState.isRecording;
  // If a time limit is given, stop recording after time limit
  useEffect(() => {
    if (limit && recordState.isRecording) {
      setTimeout(() => {
        if (recordingRef.current) StopRecording();
      }, limit);
    }
  }, [recordState.isRecording, setTimeout]);
  const StartRecording = () => {
    if (isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          setRecordState((prevState) => {
            return { ...prevState, isRecording: true };
          });
        })
        .catch((e) => console.error(e));
    }
  };

  const StopRecording = () => {
    if (recordState.isRecording)
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setRecordState((prevState) => {
            const newAudio = new Audio(URL.createObjectURL(blob));
            newAudio.onended = () => {
              setIsPlaying(false);
            };
            return {
              ...prevState,
              hasRecording: true,
              isRecording: false,
              audio: newAudio,
              audioBlob: blob,
            };
          });
        })
        .catch((e) => console.log(e));
  };

  return {
    recordState: recordState,
    isPlaying: isPlaying,
    setIsPlaying: setIsPlaying,
    isBlocked: isBlocked,
    StopRecording: StopRecording,
    StartRecording: StartRecording,
    resetRecordState: resetRecordState,
  };
};
export default useRecorderPlayer;
