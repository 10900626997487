import styled from 'styled-components';
import { PRIMARY_COLOR } from '../assets/styles/global';
import { Card } from 'antd';

export const LogoContainer = styled.div`
  height: 55vh;
  padding-top: calc(55vh - max(45vmin, 200px) - 15px);
`;

export const MainContainer = styled.div`
  width: 100vw;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '400px')};
`;
export const MyCard = styled.div`
  background-color: white;
  display: block;
  padding: 2rem;
  border-radius: 1.2rem;
  color: ${PRIMARY_COLOR};
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight};
  min-height: calc(
    min(calc(100vh - 4rem), ${(props) => props.maxHeight})
  ); //100vh - height of smallLogoLink
  overflow-y: hidden;
`;

/**
 * Center all contents
 */
export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
`;

/**
 * Round border radius card container
 */
export const RoundRadiusCard = styled(Card)`
  margin-bottom: 10px;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px lightgrey;
  }
  .ant-card-actions {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

/**
 * Preference popover container
 */
export const PreferencesPopoverContainer = styled.div`
  width: 250px;
`;
