import React, { useCallback, useState } from 'react';
import { Col, Row } from 'antd';
import { RecordPlayButton, SquareButton } from '../components/Button.style';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import CustomIcon from '../components/CustomIcon';
import adjustVolume from '../assets/audio/adjust_volume.mp3';
import useAudio from '../custom-hooks/useAudio';
import useRecorderPlayer from '../custom-hooks/useRecorderPlayer';
import { HeadingIntroTemplate } from '../templates/InnerTemplates';
import useLongPress from '@client/custom-hooks/useLongPress';
import { RecordingText } from '@client/components/Text.style';

/**
 * Content for Adjust Volume page
 * @return {JSX.Element}
 * @constructor
 */
export const AdjustVolume = () => {
  const adjustVolumeAudio = useAudio(adjustVolume);
  return (
    <HeadingIntroTemplate
      HeadingText={'Adjust volume'}
      IntroText={
        <React.Fragment>
          <p>When you press play, you will hear a sound.</p>

          <p>Increase or decrease the volume of your device until you can hear it comfortably. </p>
          <p>Press PLAY when you are ready.</p>
        </React.Fragment>
      }
      Content={
        <div style={{ marginTop: '2rem' }}>
          <Row style={{ justifyContent: 'center' }}>
            <Col span={24}>
              <RecordPlayButton
                onClick={useCallback(
                  () => adjustVolumeAudio.setIsPlaying(!adjustVolumeAudio.isPlaying),
                  [adjustVolumeAudio.isPlaying]
                )}
                Icon={adjustVolumeAudio.isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                buttonLabel='Play'
              />
            </Col>
          </Row>
        </div>
      }
    />
  );
};
/**
 * Content for Adjust Microphone page
 * @return {JSX.Element}
 * @constructor
 */
export const AdjustMicrophone = () => {
  const recorder = useRecorderPlayer();
  const [clicked, setClicked] = useState(false);
  const longPressProps = useLongPress({
    onClick: (ev) => {
      setClicked(true);
    },
    onLongPress: (ev) => {
      setClicked(false);
      recorder.StartRecording();
    },
    onLongPressStop: (ev) => {
      recorder.StopRecording();
    },
  });

  return (
    <HeadingIntroTemplate
      HeadingText={'Adjust microphone'}
      IntroText={
        <React.Fragment>
          <p>
            We will now adjust the volume of your microphone to make sure that we can hear you
            during the test.
          </p>
          <p>
            <strong>Press and hold</strong> the record button and say the following words out loud,
            then press play button to check the recording.
          </p>
        </React.Fragment>
      }
      Content={
        <div style={{ marginTop: '2rem' }}>
          <Row style={{ textAlign: 'center', fontSize: '1.8rem' }}>
            <Col span={24} style={{ marginBottom: '1rem' }}>
              CAT
            </Col>
            <Col span={24} style={{ marginBottom: '1rem' }}>
              BROKEN
            </Col>
            <Col span={24} style={{ marginBottom: '1rem' }}>
              PATH
            </Col>
          </Row>
          <Row style={{ marginTop: '1.5rem' }}>
            <Col span={12} style={{ textAlign: 'center' }}>
              <SquareButton
                props={longPressProps}
                content={
                  recorder.recordState.isRecording ? (
                    <CustomIcon.StopIcon />
                  ) : (
                    <CustomIcon.MicrophoneIcon />
                  )
                }
                disabled={recorder.isBlocked}
              />
            </Col>

            <Col span={12} style={{ textAlign: 'center' }}>
              <SquareButton
                onClick={useCallback(() => {
                  recorder.setIsPlaying(!recorder.isPlaying);
                }, [recorder])}
                content={recorder.isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                disabled={!recorder.recordState.hasRecording}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'center' }}>
              {clicked && !recorder.recordState.isRecording && <span>hold to record</span>}
              {recorder.recordState.isRecording && <RecordingText justifycontent='center' />}
            </Col>
          </Row>
        </div>
      }
    />
  );
};
