import notification from '../services/notification';
import MSG from '../constants/messages';

/**
 * Handle and format API error
 * @param {Object} error
 * @param {Boolean} isDisplayError
 */
const errorHandler = (error, isDisplayError = true) => {
  let err;
  if (error.response) {
    const message = error.response.data.error;
    const code = error.response.status;
    err = new Error(message);
    err.status = code;
  } else {
    err = error;
  }
  if (isDisplayError) {
    displayErrorNotification(err);
  }

  throw err;
};

/**
 * Popup notification for API error
 * @param {object} error - error
 */
const displayErrorNotification = (error) => {
  if (error.status === 400) {
    notification.error({ message: error.message || MSG.INVALID_OPERATION });
  } else if (error.status === 401) {
    notification.error({ message: error.message || MSG.UNAUTHENTICATED });
  } else if (error.status === 403) {
    // Forbidden error
    notification.error({
      message: error.message || MSG.UNAUTHORIZED,
    });
  } else if (error.status === 404) {
    notification.error({ message: error.message || MSG.DATA_NOT_AVAILABLE });
  } else if (error.status === 409) {
    notification.error({ message: error.message || MSG.EXISTING_ENTITY });
  } else if (error.status === 422) {
    notification.error({ message: error.message || MSG.DATA_NOT_SUPPORTED });
  } else if (error.status === 500) {
    notification.error({ message: error.message || MSG.SERVER_INERNAL_ERROR });
  } else {
    notification.error({ message: MSG.UNKNOWN_ERROR });
  }
};

export { errorHandler };
