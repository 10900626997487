/**
 * This module defined any action function related to the user account management
 * store in the application.
 */
import ACTIONS from '../actionsType';
import { ROUTES } from '../../constants/constants';
import MESSAGE from '../../constants/messages';
import {
  getCurrentUser,
  handleLogin,
  handleLogout,
  handleResendResetPassword,
  handleResetPassword,
  handleRegister,
  reSentEmailConfirmation,
  sendEmailConfirmation,
  handleUpdateProfile,
} from '../../apiServices';

/**
 * Check whether a user has logged in or not
 * @param {Boolean} isDisplayError
 * @return {(function(*): void)|*}
 */
const getSessionStatus =
  (isDisplayError = true) =>
  (dispatch) => {
    dispatch({ type: ACTIONS.GET_SESSION_STATUS });
    getCurrentUser(isDisplayError)
      .then((res) => {
        dispatch({
          type: ACTIONS.GET_SESSION_STATUS_FULFILLED,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.GET_SESSION_STATUS_ERROR,
          payload: err,
        });
        return err;
      });
  };
/**
 * Login and update the status in the store
 * @param {object} body - {email: 'email address', password: 'string'}
 * @return {function()}
 */
const login =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.LOG_IN,
    });
    handleLogin({ email, password })
      .then((res) => {
        dispatch({
          type: ACTIONS.LOG_IN_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.LOG_IN_ERROR,
          payload: err,
        });
        return err;
      });
  };

/**
 * Register user. If success send verification email and redirect to success page.
 * @param {object} body
 * @return {function(*=): Promise<void>}
 */
const register = (body) => (dispatch) => {
  dispatch({
    type: ACTIONS.REGISTER,
  });
  handleRegister(body)
    .then((res) => {
      dispatch({
        type: ACTIONS.REGISTER_FULFILLED,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ACTIONS.REGISTER_ERROR,
        payload: err,
      });
      return err;
    });
};

/**
 * TODO: this one needs to be replaced
 * @param {string} userId
 * @param {string} token
 * @param {function(object)} setState
 * @return {function(*): Promise<void>}
 */
const verifyEmail = (userId, token, setState) => (dispatch) => {
  sendEmailConfirmation({ token, userId })
    .then(() => {
      setState({ verifying: false, verified: true });
    })
    .catch((err) => {
      setState({
        verifying: false,
        verified: false,
        message: MESSAGE.VERIFY_EMAIL_FAILURE,
      });
      return err;
    });
};

/**
 * Re send verification email
 * @param {string} userId - who will receive confirmation email
 * @param {string} destUrl - link to verify page
 * @return {function(*): Promise<void>}
 */
const reVerifyEmail =
  ({ userId, destUrl }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.RESEND_EMAIL_VERIFICATION,
    });
    reSentEmailConfirmation({ userId, destUrl })
      .then(() => {
        dispatch({
          type: ACTIONS.RESEND_EMAIL_VERIFICATION_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.RESEND_EMAIL_VERIFICATION_ERROR,
          payload: err,
        });
        return err;
      });
  };

/**
 * Reset password
 * @param {string} userId
 * @param {string} token
 * @param {string} newPassword
 * @return {(function(*): void)|*}
 */
const resetPassword =
  ({ userId, token, newPassword }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.RESET_PASSWORD,
    });
    handleResetPassword({ userId, token, newPassword })
      .then(() => {
        dispatch({
          type: ACTIONS.RESET_PASSWORD_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.RESET_PASSWORD_ERROR,
          payload: err,
        });
        return err;
      });
  };

/**
 * Resend reset password email to user
 * @param {string} email
 * @param {string} destUrl - link to reset password page
 * @return {(function(*): void)|*}
 */
const resendResetPassword =
  ({ email, destUrl }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.RESEND_RESET_PASSWORD,
    });
    handleResendResetPassword({ email, destUrl })
      .then(() => {
        dispatch({
          type: ACTIONS.RESEND_RESET_PASSWORD_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.RESEND_RESET_PASSWORD_ERROR,
          payload: err,
        });
        return err;
      });
  };

/**
 * Update profile
 * @param {string} name
 * @param {string} email
 * @param {string} password
 * @param {boolean} hearingAid
 * @param {boolean} normalHearingMod
 * @param {Object} preferences
 * @return {(function(*): void)|*}
 */
const updateProfile =
  ({ name, email, password, hearingAid, normalHearingMod, preferences }) =>
  (dispatch) => {
    dispatch({ type: ACTIONS.UPDATE_PROFILE });
    handleUpdateProfile({ name, email, password, hearingAid, normalHearingMod, preferences })
      .then((res) => {
        dispatch({
          type: ACTIONS.UPDATE_PROFILE_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACTIONS.UPDATE_PROFILE_ERROR,
          payload: err,
        });
      });
  };

/**
 * Logout terminate the session
 * @return {function()}
 */
const logout = () => (dispatch) => {
  handleLogout()
    .then(() => {
      dispatch({
        type: ACTIONS.LOG_OUT_FULFILLED,
      });
      window.location.href = ROUTES.LOGIN;
    })
    .catch((err) => {
      window.location.href = ROUTES.LOGIN;
      return err;
    });
};

export {
  getSessionStatus,
  sendEmailConfirmation,
  resetPassword,
  verifyEmail,
  reVerifyEmail,
  resendResetPassword,
  reSentEmailConfirmation,
  register,
  getCurrentUser,
  updateProfile,
  logout,
  login,
};
