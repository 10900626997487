import { Progress } from 'antd';
import styled from 'styled-components';
import { GRADIENT_COLOR_1, GRADIENT_COLOR_2 } from '../../assets/styles/global';
import React from 'react';

const GradientProgressBar = ({ className, percentage }) => {
  return (
    <Progress
      className={className}
      strokeColor={{
        '0%': GRADIENT_COLOR_1,
        '100%': GRADIENT_COLOR_2,
      }}
      percent={percentage}
    />
  );
};

export const StyledGradientProgressBar = styled(GradientProgressBar)`
  height: 2rem;
  margin-bottom: 1rem;
`;
