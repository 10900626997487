/**
 * reducers/index.js
 * Defines the root reducer (root store)
 * This module is to combine all the reducers and merge them. the root reducer will be passed to react elements that connect to the store.
 */
import { combineReducers } from 'redux';
import { account } from '@client/stores/reducers/account.reducer';
import { playback } from '@client/stores/reducers/playback';
import { response } from '@client/stores/reducers/response';
import { chunk } from '@client/stores/reducers/chunk';
import { track } from '@client/stores/reducers/track';
import { admin } from '@client/stores/reducers/admin';
import { workspace } from '@client/stores/reducers/workspace';

const rootReducer = combineReducers({
  account,
  admin,
  workspace,
  chunk,
  playback,
  track,
  response,
});

export default rootReducer;
