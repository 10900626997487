import React, { useCallback } from 'react';
import { DIGIT_LENGTH } from '@client/constants/constants';
import { Col, Row } from 'antd';
import { TripletDigitDiv } from '@client/components/Text.style';
import { GreyUnderline } from '@client/components/GreyUnderline';
import { EmptySquareButton, SquareButton } from '@client/components/Button.style';
import CustomIcon from '@client/components/CustomIcon';

const DigitPanel = ({ digitAnswer, setDigitAnswer }) => {
  const digitClicked = (digit) => {
    for (let i = 0; i < DIGIT_LENGTH; i++) {
      if (digitAnswer[i] === undefined) {
        const newAnswer = [...digitAnswer];
        newAnswer[i] = digit.toString();
        setDigitAnswer(newAnswer);
        break;
      }
    }
  };
  return (
    <React.Fragment>
      <div>
        <Row justify='center' style={{ height: '3.5rem' }}>
          {digitAnswer?.map((item, number) => (
            <Col key={number}>
              <TripletDigitDiv>{item}</TripletDigitDiv>
            </Col>
          ))}
        </Row>
        <Row justify='center'>
          <Col style={{ display: 'flex' }}>
            <GreyUnderline />
          </Col>
          <Col style={{ display: 'flex' }}>
            <GreyUnderline />
          </Col>
          <Col style={{ display: 'flex' }}>
            <GreyUnderline />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Row justify='center'>
          <Col>
            <SquareButton
              content={1}
              onClick={useCallback(() => {
                digitClicked(1);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <SquareButton
              content={2}
              onClick={useCallback(() => {
                digitClicked(2);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <SquareButton
              content={3}
              onClick={useCallback(() => {
                digitClicked(3);
              }, [digitClicked])}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Col>
            <SquareButton
              content={4}
              onClick={useCallback(() => {
                digitClicked(4);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <SquareButton
              content={5}
              onClick={useCallback(() => {
                digitClicked(5);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <SquareButton
              content={6}
              onClick={useCallback(() => {
                digitClicked(6);
              }, [digitClicked])}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Col>
            <SquareButton
              content={7}
              onClick={useCallback(() => {
                digitClicked(7);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <SquareButton
              content={8}
              onClick={useCallback(() => {
                digitClicked(8);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <SquareButton
              content={9}
              onClick={useCallback(() => {
                digitClicked(9);
              }, [digitClicked])}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Col>
            <SquareButton
              content={10}
              onClick={useCallback(() => {
                digitClicked(10);
              }, [digitClicked])}
            />
          </Col>
          <Col>
            <EmptySquareButton />
          </Col>
          <Col>
            <SquareButton
              content={<CustomIcon.BackspaceIcon />}
              onClick={useCallback(() => {
                for (let i = DIGIT_LENGTH - 1; i >= 0; i--) {
                  if (digitAnswer[i] !== undefined) {
                    const newAnswer = [...digitAnswer];
                    newAnswer[i] = undefined;
                    setDigitAnswer(newAnswer);
                    break;
                  }
                }
              }, [digitAnswer, setDigitAnswer])}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default DigitPanel;
