import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionStatus } from '../stores/actions/account.action';
import _, { get } from 'lodash';
import { REDUCER_STATES } from '../constants/reducerStates';
import { CenterContainer } from '../components/Container.style';
import { SpinWithColor } from '../components/Loading';
import { ROUTES } from '@client/constants/constants';

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const { component: Component, path } = props;

  const [canCheck, setCanCheck] = useState(false);
  const isLoggedIn = useSelector((state) =>
    _.get(state, [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_LOGGED_IN], null)
  );
  const isGettingSession = useSelector((state) =>
    get(
      state,
      [REDUCER_STATES.ACCOUNT.NAME, REDUCER_STATES.ACCOUNT.FIELDS.IS_GETTING_SESSION_STATUS],
      false
    )
  );

  const previousIsGettingSession = useRef(isGettingSession);

  useEffect(() => dispatch(getSessionStatus()), [dispatch, path]);

  useEffect(() => {
    if (previousIsGettingSession.current && !isGettingSession) {
      setCanCheck(true);
    }
    previousIsGettingSession.current = isGettingSession;
  }, [isGettingSession]);

  return (
    <Route
      render={() => {
        if (!isGettingSession && canCheck) {
          if (isLoggedIn) return <Component {...props} />;
          else return <Redirect to={ROUTES.LOGIN} />;
        } else {
          return (
            <CenterContainer>
              <SpinWithColor size={'large'} />
            </CenterContainer>
          );
        }
      }}
    />
  );
};
export default ProtectedRoute;
