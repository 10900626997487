import API from '@client/axios';
import { errorHandler } from '@client/axios/errorHandler';
import { extractResponse } from '@client/axios/extractResponse';
import ENDPOINTS from '@client/constants/endpoints';
import { TASKS_STATUS } from '@client/constants/constants';

/**
 * Get all workspaces (of current user)
 * @return {Promise<*>}
 */
const handleGetWorkspaces = async () => {
  try {
    const res = await API.get(ENDPOINTS.WORKSPACES);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Get one workspace
 * @param {string} workspaceId
 * @return {Promise<*>}
 */
const handleGetWorkspaceById = async (workspaceId) => {
  try {
    const res = await API.get(ENDPOINTS.WORKSPACE({ workspaceId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Create a workspace
 * @param {string} title
 * @return {Promise<*>}
 */
const handleCreateWorkspace = async () => {
  try {
    const res = await API.post(ENDPOINTS.WORKSPACES);
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};
/**
 * Create a workspace task
 * @param {string} workspaceId
 * @param {string} chunkId
 * @param {string} status
 * @return {Promise<*>}
 */
const handleCreateWorkspaceTask = async ({
  workspaceId,
  chunkId,
  status = TASKS_STATUS.PENDING,
}) => {
  try {
    const res = await API.post(ENDPOINTS.WORKSPACE_TASKS({ workspaceId }), {
      chunkId,
      status,
    });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Update workspace task
 * @param {string} workspaceId
 * @param {string} taskId
 * @param {string} status
 * @return {Promise<*>}
 */
const handleUpdateWorkspaceTask = async ({ workspaceId, taskId, status }) => {
  try {
    const res = await API.put(ENDPOINTS.WORKSPACE_TASK({ workspaceId, taskId }), { status });
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

/**
 * Delete workspace task
 * @param {string} workspaceId
 * @param {string} taskId
 * @return {Promise<*>}
 */
const handleDeleteWorkspaceTask = async ({ workspaceId, taskId }) => {
  try {
    const res = await API.delete(ENDPOINTS.WORKSPACE_TASKS({ workspaceId, taskId }));
    return extractResponse(res);
  } catch (err) {
    errorHandler(err);
  }
};

export {
  handleGetWorkspaces,
  handleGetWorkspaceById,
  handleCreateWorkspace,
  handleCreateWorkspaceTask,
  handleUpdateWorkspaceTask,
  handleDeleteWorkspaceTask,
};
